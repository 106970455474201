import { environment } from "src/environments/environment";

export class ApiUrl {
    static readonly USERS = environment.apiUrl + 'users';
    static readonly LOGIN = environment.apiUrl + 'login';
    static readonly PAZIENTI = environment.apiUrl + 'pazienti';
    static readonly SLOT = environment.apiUrl + 'slots';
    static readonly CONSULTI = environment.apiUrl + 'consulti';
    static readonly TIPI_CONSULTO = environment.apiUrl + 'tipi_consulti';
    static readonly PAGAMENTI = environment.apiUrl + 'pagamenti';
    //REFERTI
    static readonly REFERTI = environment.apiUrl + 'referti';
    static readonly REFERTI_ECG = environment.apiUrl + 'ecg';
    static readonly REFERTI_ECOCARDIOGRAFIA = environment.apiUrl + 'ecocardiografia';
    static readonly REFERTI_ECOGRAFIA = environment.apiUrl + 'ecografia';
    static readonly REFERTI_HOLTER = environment.apiUrl + 'holter';
    static readonly REFERTI_RADIOGRAFIA = environment.apiUrl + 'radiografia';
    static readonly REFERTI_SMALL_PART = environment.apiUrl + 'small_part';

    //ALLEGATI
    static readonly ALLEGATI = environment.apiUrl + 'allegati';
    static readonly ALLEGATI_DOWNLOAD = environment.apiUrl + 'allegati/download';
    static readonly ALLEGATI_UPLOAD = environment.apiUrl + 'allegati/upload';

    // FATTURE
    static readonly FATTURE = environment.apiUrl + 'fatture';
    static readonly FATTURE_CREA = this.FATTURE + '/crea';

    // CAPTCHA
    static readonly CAPTCHA_SITE_ID = environment.apiUrl + 'captcha/get-site-id';
    static readonly CAPTCHA_BASE_URL = environment.apiUrl + 'captcha/get-base-url'; 
    static readonly CAPTCHA_API_KEY = environment.apiUrl + 'captcha/get-api-key';
    static readonly VALUTAZIONE_CAPTCHA = environment.apiUrl + 'captcha/richiedi-valutazione';


    //PAYPAL
    static readonly PAYPAL_CLIENT_ID = environment.apiUrl + 'paypal/clientID';

    // NOTIFICHE
    static readonly NOTIFICHE = environment.apiUrl + 'lista_mail_notifiche';
}