import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ModificaPasswordData {
  OldRequested: boolean;
  OldPassword?: string;
  NewPassword: string;
}

@Component({
  selector: 'app-modifica-password',
  templateUrl: './modifica-password.component.html',
  styleUrls: ['./modifica-password.component.scss']
})
export class ModificaPasswordComponent {
  newPasswordControl?: UntypedFormControl;
  newPassword2Control?: UntypedFormControl;
  oldPasswordControl?: UntypedFormControl;
  form: UntypedFormGroup;

  passwordErrors: ValidationErrors = {
    newPassword: 'password non corrispondente',
    newPassword2: 'password non corrispondente',
  };

  constructor(
    public dialogRef: MatDialogRef<ModificaPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModificaPasswordData,
    private fb: UntypedFormBuilder
  ) {
    this.newPasswordControl = this.fb.control('', (c) => {
      return this.validateNew();
    });
    this.newPassword2Control = this.fb.control('', (c) => {
      return this.validateNew();
    });
    this.form = this.fb.group({
      newPassword2: this.newPassword2Control,
      newPassword: this.newPasswordControl,
    });

    if (this.data.OldRequested) {
      this.oldPasswordControl = this.fb.control('', Validators.required);
      this.form.addControl('oldPassword', this.oldPasswordControl);
    }
  }

  private validateNew(): ValidationErrors {
    const valid =
      (this.newPasswordControl?.value || '') ==
      (this.newPassword2Control?.value || '');
    console.log('validateNew', valid);
    return valid ? [] : this.passwordErrors;
  }

  get valid(): boolean {
    return (
      this.form.get('newPassword')?.value ==
      this.form.get('newPassword2')?.value
    );
  }

  get result(): ModificaPasswordData {
    return <ModificaPasswordData>{
      NewPassword: this.form.get('newPassword')?.value,
      OldPassword: this.form.get('oldPassword')?.value,
    };
  }

  
}