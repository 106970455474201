import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LandingComponent } from './components/landing/landing.component';
import { UserListaComponent } from './components/users/user-lista/user-lista.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { PazientiListaComponent } from './components/pazienti/pazienti-lista/pazienti-lista.component';
import { PazientiDetailComponent } from './components/pazienti/pazienti-detail/pazienti-detail.component';
import { CanDeactivateGuard } from './services/can-deactivate.service';
import { CalendarioMainComponent } from './components/calendario/calendario-main/calendario-main.component';
import { ConsultiListComponent } from './components/consulti/consulti-list/consulti-list.component';
import { ConsultoDetailComponent } from './components/consulti/consulto-detail/consulto-detail.component';
import { ListiniComponent } from './components/listini/listini.component';
import { TeleconferenzaComponent } from './components/teleconferenza/teleconferenza.component';
import { FattureListaComponent } from './components/fatture-lista/fatture-lista.component';
import { ListaMailNotificheComponent } from './components/lista-mail-notifiche/lista-mail-notifiche.component';
import { LandighomeComponent } from './components/landighome/landighome.component';

const routes: Routes = [
  {
    path:"login",
    component: LoginComponent
  },
  {
    path:"",
    component: LandingComponent,
    children: [
      {
        path: "",
        component: LandighomeComponent
      },
      {
        path: "users",
        component: UserListaComponent,
      },
      {
        path: "users/:id",
        component: UserDetailComponent,
        canDeactivate : [CanDeactivateGuard],
      },
      {
        path: "users/new",
        component: UserDetailComponent,
        canDeactivate : [CanDeactivateGuard],
      },
      {
        path:"pazienti",
        component: PazientiListaComponent,
      },
      {
        path: "pazienti/:id",
        component: PazientiDetailComponent,
        canDeactivate : [CanDeactivateGuard],
      },
      {
        path: "pazienti/new",
        component: PazientiDetailComponent,
        canDeactivate : [CanDeactivateGuard],
      },
      {
        path: "calendario",
        component: CalendarioMainComponent,
      },
      {
        path: "consulti",
        component: ConsultiListComponent,
      },
      {
        path: "consulti/:id",
        component: ConsultoDetailComponent,
        canDeactivate : [CanDeactivateGuard],
      },
      {
        path: "consulti/new",
        component: ConsultoDetailComponent,
      },
      {
        path: "listini",
        component: ListiniComponent,
      },
      {
        path: "teleconference/:id",
        component: TeleconferenzaComponent,
      },
      {
        path: "fatture",
        component: FattureListaComponent,
      },
      {
        path: "notifiche",
        component: ListaMailNotificheComponent,
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true,

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
