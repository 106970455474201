import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Consulto } from 'src/app/model/consulto.model';
import {
  pagamentoEffettuato,
  pagamentoSospeso,
} from 'src/app/model/pagamento.model';
import { Paziente } from 'src/app/model/paziente.model';
import {
  TipiConsulti,
  TipiConsultiArray,
} from 'src/app/model/tipo-consulto.model';
import { User, ruoloCliente } from 'src/app/model/user.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ApiUrl } from 'src/app/shared/api-url';
import {
  FATTURA_CON_ERRORI,
  FATTURA_INVIATA,
  FATTURA_NON_INVIATA,
  FATTURA_RICONCILIATA,
} from 'src/app/shared/icons-constant';

@Component({
  selector: 'app-consulti-list',
  templateUrl: './consulti-list.component.html',
  styleUrls: ['./consulti-list.component.scss'],
})
export class ConsultiListComponent implements OnInit {
  ICOFatturaConErrori = FATTURA_CON_ERRORI;
  ICOFatturaInviata = FATTURA_INVIATA;
  ICOFatturaRiconciliata = FATTURA_RICONCILIATA;
  ICOFatturaNonInviata = FATTURA_NON_INVIATA;

  listaConsulti: Consulto[] = [];
  displayedColumns: string[] = [
    'utente',
    'inData',
    'paziente',
    'tipoConsulto',
    'tele',
    'statoPagamento',
    'statoFattura',
    'statoReferto',
  ];
  pagamentoEffettuato = pagamentoEffettuato;
  pagamentoSospeso = pagamentoSospeso;

  /**
  filtro per teleconferenza: 
  -1 = Disattivo
  0 = solo Non teleconferenze
  1 = solo  teleconferenze
  2 = solo teleconferenze da accettare
   */
  teleconferenzaFilter: number = -1;
  teleconfFilterChanged() {
    if (this.teleconferenzaFilter == 2) {
      this.teleconferenzaFilter = -1;
    } else {
      this.teleconferenzaFilter++;
    }
    this.loadData();
  }
  getTeleconferenzaFilterTooltip() {
    switch (this.teleconferenzaFilter) {
      case -1:
        return 'Filtro disattivo';
      case 0:
        return 'Filtra non teleconferenza';
      case 1:
        return 'Filtra solo in teleconferenza';
      case 2:
        return 'Filtra Teleconferenze da accettare';
      default:
        return '';
    }
  }

  /**
   * STATO PAGAMENTO
   * -1 = nessun filtro
   * 0 = in sospeso
   * 1 = effettuati
   */
  statoPagamentoFilter: number = -1;
  statoPagamentoFilterChanged() {
    if (this.statoPagamentoFilter == 1) {
      this.statoPagamentoFilter = -1;
    } else {
      this.statoPagamentoFilter++;
    }
    this.loadData();
  }

  statoPagamentoFilterGetTooltip(): string {
    switch (this.statoPagamentoFilter) {
      case -1:
        return 'Filtro disattivo';
      case 0:
        return 'Filtra In sospeso';
      case 1:
        return 'Filtra Effettuati';
      default:
        return '';
    }
  }

  statiFatture: number[] = [-1, 0, 1, 2];
  /**
   * STATO FATTURA
   * -1 = nessun filtro
   * 0 = inviate
   * 1 = riconciliate
   * 2 = con errori
   * 3 = non inviate
   */
  statoFatturaSearch: number = -1;
  statoFatturaFilterChanged() {
    if (this.statoFatturaSearch == 3) {
      this.statoFatturaSearch = -1;
    } else {
      this.statoFatturaSearch++;
    }
    this.loadData();
  }
  statoFatturaFilterGetTooltip(): string {
    switch (this.statoFatturaSearch) {
      case -1:
        return 'Filtro disattivo';
      case 0:
        return 'Filtra Inviate';
      case 1:
        return 'Filtra Riconciliate';
      case 2:
        return 'Filtra con Errori';
      case 3:
        return 'Filtra non inviate';
      default:
        return '';
    }
  }

  statoRefertoFilter: number = -1;
  // 0- nessun filtro
  // 1- fattura inviata
  // 2- fattura riconciliata
  // 3- fattura con errori

  /**
   * STATO REFERTO 
  0 => nessun referto
  1 => referto prodotto
  2 => referto disponibile
   * @param element 
   * @returns 
   */

  statoReferto(element: Consulto): number {
    if (element.Ecg != undefined && element.Ecg.Disponibile) {
      return 2;
    }
    if (element.Ecografia != undefined && element.Ecografia.Disponibile) {
      return 2;
    }
    if (
      element.Ecocardiografia != undefined &&
      element.Ecocardiografia.Disponibile
    ) {
      return 2;
    }
    if (element.Holter != undefined && element.Holter.Disponibile) {
      return 2;
    }
    if (element.Radiografia != undefined && element.Radiografia.Disponibile) {
      return 2;
    }
    if (element.SmallParts != undefined && element.SmallParts.Disponibile) {
      return 2;
    }
    if (element.SmallParts != undefined && !element.SmallParts.Disponibile) {
      return 1;
    }
    if (element.Radiografia != undefined && !element.Radiografia.Disponibile) {
      return 1;
    }
    if (element.Holter != undefined && !element.Holter.Disponibile) {
      return 1;
    }
    if (
      element.Ecocardiografia != undefined &&
      !element.Ecocardiografia.Disponibile
    ) {
      return 1;
    }
    if (element.Ecografia != undefined && !element.Ecografia.Disponibile) {
      return 1;
    }
    if (element.Ecg != undefined && !element.Ecg.Disponibile) {
      return 1;
    }
    return 0;
  }

  constructor(
    private currentUser: CurrentUserService,
    private http: HttpClient,
    private loadingService: LoadingService,
  ) {}

  getTipoConsulto(tipoID: number) {
    let tipo = TipiConsultiArray.find((t) => t.Id == tipoID);
    if (tipo == undefined) {
      return '';
    }
    return tipo.Descrizione;
  }

  ngOnInit(): void {
    this.loadData();
    this.loadTipiConsulti();
    this.loadListaClienti();
    this.loadListaPazienti();
  }

  pazienteFilter: string[] = [];
  pazienteFilterChanged(matChange: MatSelectChange) {
    let pazientiIds = matChange.value;
    this.pazienteFilter = pazientiIds;
    this.loadData();
  }

  pazienti: Paziente[] = [];
  loadListaPazienti() {
    if (this.currentUser.currentUser == undefined) {
      return;
    }
    let params = new HttpParams();
    if (this.currentUser.currentUser.Ruolo == ruoloCliente) {
      params = params.set('cliente', this.currentUser.currentUser.Email);
    }
    const url = ApiUrl.PAZIENTI;
    const obs = this.http.get<Paziente[]>(url, { params: params });
    obs.subscribe((data) => {
      this.pazienti = data;
    });
  }

  pazientiFiltered(): Paziente[] {
    if (this.currentUser.currentUser == undefined) {
      return [];
    }
    if (this.currentUser.currentUser.Ruolo == ruoloCliente) {
      return this.pazienti.filter(
        (p) => p.UserEmail == this.currentUser!.currentUser!.Email
      );
    }
    if (this.clienteFilter.length > 0) {
      return this.pazienti.filter((p) =>
        this.clienteFilter.includes(p.UserEmail)
      );
    }
    return this.pazienti;
  }

  clienteFilter: string[] = [];
  clienteFilterChanged(matChange: MatSelectChange) {
    const listaMail = matChange.value;
    this.clienteFilter = listaMail;
    this.loadData();
  }
  clienti: User[] = [];
  loadListaClienti() {
    const url = ApiUrl.USERS;
    let param = new HttpParams().set('ruolo', ruoloCliente);
    const obs = this.http.get(url, { params: param });
    obs.subscribe((data) => {
      this.clienti = data as User[];
    });
  }

  filteredClienti(): User[] {
    if (this.currentUser.currentUser == undefined) {
      return [];
    }
    if (this.currentUser.currentUser?.Ruolo == ruoloCliente) {
      return this.clienti.filter(
        (c) => c.Email == this.currentUser!.currentUser!.Email
      );
    }
    return this.clienti;
  }

  tipiConsulto: TipiConsulti[] = [];
  loadTipiConsulti() {
    const url = ApiUrl.TIPI_CONSULTO;
    const obs = this.http.get<TipiConsulti[]>(url);
    obs.subscribe((data) => {
      this.tipiConsulto = data;
    });
  }

  /**
   * 0 = nessun referto
   * 1 = referto prodotto
   * 2 = referto disponibile
   */
  statoRefertoFilterChanged() {
    if (this.statoRefertoFilter == 2) {
      this.statoRefertoFilter = -1;
    } else {
      this.statoRefertoFilter++;
    }
    this.loadData();
  }

  getStatoRefertoFilterTooltip(): string {
    switch (this.statoRefertoFilter) {
      case -1:
        return 'Filtro disattivo';
      case 0:
        return 'Referto non prodotto';
      case 1:
        return 'Prodotto ma non disponibile';
      case 2:
        return 'Disponibile';
      default:
        return '';
    }
  }

  filtriReset() {
    this.teleconferenzaFilter = -1;
    this.statoPagamentoFilter = -1;
    this.statoFatturaSearch = -1;
    this.statoRefertoFilter = -1;
    this.clienteFilter = [];
    this.pazienteFilter = [];
    this.loadData();
  }

  loadData() {
    const url = ApiUrl.CONSULTI;
    if (
      this.currentUser == undefined ||
      this.currentUser.currentUser == undefined
    ) {
      return;
    }
    //PARAM CLIENTI
    let params = new HttpParams();
    if (this.currentUser.currentUser.Ruolo == ruoloCliente) {
      params = params.set('clienti', this.currentUser.currentUser.Email);
    } else {
      if (this.clienteFilter.length > 0) {
        params = params.set('clienti', this.clienteFilter.join(','));
      }
    }
    // TELECONFERENZA
    if (this.teleconferenzaFilter != undefined) {
      params = params.set(
        'teleconferenzaFilter',
        this.teleconferenzaFilter.toString()
      );
    }
    //PAGAMENTO
    if (this.statoPagamentoFilter != undefined) {
      params = params.set(
        'statoPagamentoFilter',
        this.statoPagamentoFilter.toString()
      );
    }
    //FATTURA
    if (this.statoFatturaSearch != undefined) {
      params = params.set('statoFattura', this.statoFatturaSearch.toString());
    }
    // PAZIENTI
    if (this.pazienteFilter.length > 0) {
      params = params.set('pazienti', this.pazienteFilter.join(','));
    }
    //REFERTO
    if (this.statoRefertoFilter != undefined) {
      params = params.set('statoReferto', this.statoRefertoFilter.toString());
    }
    const obs = this.http.get<Consulto[]>(url, { params: params });
    this.loadingService.isLoading.next(true);
    obs.subscribe((data) => {
      this.loadingService.isLoading.next(false);
      this.listaConsulti = data;
    });
  }
}
