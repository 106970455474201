import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  // Esponiamo un subject che ci permetterà di sapere se il caricamento è in corso
  public isLoading = new Subject<boolean>();

  constructor() { }

  
}
