import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

type MessageType = 'Inserimento' | 'Modifica' | 'Info' | 'Eliminazione' | 'Errore';
export type MessageData = {
  Message: string;
  Type: MessageType;
}
export class MessageConstants {
  static Inserimento: MessageData  ={Message: 'Elemento inserito con successo', Type: 'Inserimento'};
  static Modifica: MessageData  ={Message: 'Elemento modificato con successo', Type: 'Modifica'};
  static Info: MessageData  ={Message: 'Informazioni: ', Type: 'Info'};
  static Eliminazione: MessageData  ={Message: 'Elemento eliminato con successo', Type: 'Eliminazione'};
  static RichiestaAccesso: MessageData = {Message: 'Richiesta di accesso inviata con successo', Type: 'Info'};
  static Errore: MessageData  ={Message: 'Errore: ', Type: 'Errore'};
}
const snackBarBaseConfig :MatSnackBarConfig = {
  duration: 3000,
  horizontalPosition: 'left',
  verticalPosition: 'bottom',
};


@Injectable({
  providedIn: 'root'
})

/**
si occupa di gestire i messaggi che vengono mostrati all'utente tramite snack bar
 */
export class MessageService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }



  sendMessage(message: MessageData, config?: MatSnackBarConfig) {
    let conf: MatSnackBarConfig;
    if (config) {
      conf = config;
    } else {
      conf = snackBarBaseConfig;
    }
    this.snackBar.open(message.Message, message.Type, conf);
  }


}
