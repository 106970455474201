import { Component } from '@angular/core';
import { MenuConstant } from 'src/app/shared/menu-constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  menuVoices = MenuConstant;
  
  get version(): string {
    return environment.version
  }

}
