import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { finalize, Subscription } from 'rxjs';
import { ListaMailNotifica } from 'src/app/model/lista-mail-notifiche.model';
import { User } from 'src/app/model/user.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { LoadingService } from 'src/app/services/loading.service';
import {
  MessageConstants,
  MessageData,
  MessageService,
} from 'src/app/services/message.service';
import { ApiUrl } from 'src/app/shared/api-url';
import { ValidatoreEmail } from 'src/app/shared/validatore-email';

@Component({
  selector: 'app-lista-mail-notifiche',
  templateUrl: './lista-mail-notifiche.component.html',
  styleUrls: ['./lista-mail-notifiche.component.scss'],
})
export class ListaMailNotificheComponent {
  subs: Subscription = new Subscription();
  listaMailNotifiche: ListaMailNotifica[] = [];
  listaAdmin: User[] = [];
  @ViewChild('inputNewMail') inputNewMail!: ElementRef;
  @ViewChild('inputNewNome') inputNewNome!: ElementRef;
  @ViewChild('inputNewCognome') inputNewCognome!: ElementRef;

  checkedChange(contatto: User, event: MatCheckboxChange) {
    if (event.checked) {
      this.aggiungiEmail(contatto);
    } else {
      this.rimuoviEmail(contatto);
    }
  }

  validaMail(email: string): boolean {
    return ValidatoreEmail(email) && !this.isAlreadyIncluded(email);
  }

  isAlreadyIncluded(email: string): boolean {
    return this.listaMailNotifiche.findIndex((x) => x.Mail == email) != -1;
  }

  nuovoContatto: ListaMailNotifica = {} as ListaMailNotifica;
  
  aggiungiEmail(contatto?: User) {
    if (this.currentUserService == undefined) {
      return console.log('currentUserService undefined');
    }
    if (this.currentUserService.currentUser == undefined) {
      return console.log('currentUser undefined');
    }
    if (contatto) {
      this.listaMailNotifiche.push({
        Mail: contatto.Email,
        Nome: contatto.Nome,
        Cognome: contatto.Cognome,
        CreatedBy: this.currentUserService.currentUser.Email,
      } as ListaMailNotifica);
    } else if (this.nuovoContatto != undefined) {
      this.listaMailNotifiche.push({
        Mail: this.nuovoContatto.Mail,
        Nome: this.nuovoContatto.Nome,
        Cognome: this.nuovoContatto.Cognome,
        CreatedBy: this.currentUserService.currentUser.Email,
      } as ListaMailNotifica);
      this.nuovoContatto = {} as ListaMailNotifica;
      this.inputNewMail.nativeElement.value = '';
      this.inputNewNome.nativeElement.value = '';
      this.inputNewCognome.nativeElement.value = '';
    } else {
      return;
    }
  }

  rimuoviEmail(contatto?: User, email?: string) {
    if (contatto == undefined) {
      if (email == undefined) {
        return;
      }
      contatto = { Email: email } as User;
    }
    const index = this.listaMailNotifiche.findIndex(
      (x) => x.Mail == contatto!.Email
    );
    if (index != -1) {
      this.listaMailNotifiche.splice(index, 1);
    }
  }

  isSelected(contatto: User): boolean {
    return (
      this.listaMailNotifiche.findIndex((x) => x.Mail == contatto.Email) != -1
    );
  }

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    this.recuperaListaMailNotifiche();
    this.recuperaListaAdmin();
  }

  recuperaListaAdmin() {
    const url = ApiUrl.USERS;
    let params: HttpParams = new HttpParams();
    params = params.append('soloAdmin', true);
    const obs = this.http.get<User[]>(url, { params }).pipe(
      finalize(() => {
        this.loadingService.isLoading.next(false);
      })
    );
    this.loadingService.isLoading.next(true);
    this.subs.add(
      obs.subscribe({
        next: (data) => {
          this.listaAdmin = data;
        },
        error: (error) => {
          console.log(error);
        },
      })
    );
  }

  recuperaListaMailNotifiche() {
    const url = ApiUrl.NOTIFICHE;
    const obs = this.http.get<ListaMailNotifica[]>(url).pipe(
      finalize(() => {
        this.loadingService.isLoading.next(false);
      })
    );
    this.loadingService.isLoading.next(true);
    this.subs.add(
      obs.subscribe({
        next: (data) => {
          this.listaMailNotifiche = data;
        },
        error: (error) => {
          console.log(error);
        },
      })
    );
  }

  get listaMailAggiunte(): ListaMailNotifica[] {
    // I need to return as ListaMailNotifica[] the elements of listaMailNotifiche wich email are not present in element of listaAdmin
    let aggiunte: ListaMailNotifica[] = [];
    this.listaMailNotifiche.forEach((element) => {
      let index = this.listaAdmin.findIndex((x) => x.Email == element.Mail);
      if (index == -1) {
        aggiunte.push(element);
      }
    });
    return aggiunte;
  }

  salva() {
    const url = ApiUrl.NOTIFICHE;
    const obs = this.http.post(url, this.listaMailNotifiche).pipe(
      finalize(() => {
        this.loadingService.isLoading.next(false);
      })
    );
    let insertMessage: MessageData = MessageConstants.Info;
    insertMessage.Message = 'Lista mail correttamente salvata';
    this.loadingService.isLoading.next(true);
    this.subs.add(
      obs.subscribe({
        next: (data) => {
          this.messageService.sendMessage(insertMessage);
        },
        error: (error) => {
          console.log(error);
        },
      })
    );
  }
}
