export function serverDate(clientDate: Date, withHours: boolean = false): string {
  const now = new Date(clientDate);
  let d : string = '';
  if(withHours){
    d = now.toLocaleDateString('it-IT', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC', // Explicitly set the timezone to UTC
    });
    const formattedDate = d.replaceAll('/', '-').replaceAll(',', '')
    return formattedDate;
  }

  d = now.toLocaleDateString('it-IT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC', // Explicitly set the timezone to UTC
  });
  const formattedDate = d.replaceAll('/', '-').replaceAll(',', '')
  return formattedDate;
}
