import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TipiConsulti, TipoConsulto } from '../model/tipo-consulto.model';
import { ApiUrl } from '../shared/api-url';
import { HttpClient, HttpParams } from '@angular/common/http';

export type datiGenerazioneReferto = {
  tipo : TipiConsulti,
  refertoID : number
}


@Injectable({
  providedIn: 'root'
})
export class PdfRefertoService {

  public consultoRichiedeGenerazionePDF: Subject<datiGenerazioneReferto> = new Subject<datiGenerazioneReferto>();

  richiediGenerazionePDF(tipo: datiGenerazioneReferto): void {
    const url = ApiUrl.REFERTI + '/pdf';
    let params = new HttpParams();
    params = params.set('refertoID', tipo.refertoID.toString() || '');
    params = params.set('tipo', tipo.tipo.toString());
    const obs = this.http.get(url, { params: params, responseType: 'blob' });
    obs.subscribe((data) => {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = 'Referto.pdf';
      a.click();
    });
  }

  constructor(
    private http: HttpClient
  ) {
    this.consultoRichiedeGenerazionePDF.subscribe((value: datiGenerazioneReferto) => {
      this.richiediGenerazionePDF(value);
    });
   }
}
