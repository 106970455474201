import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Consulto } from 'src/app/model/consulto.model';
import { ApiUrl } from 'src/app/shared/api-url';

@Component({
  selector: 'app-fattura-detail',
  templateUrl: './fattura-detail.component.html',
  styleUrls: ['./fattura-detail.component.scss'],
})
export class FatturaDetailComponent {
  constructor(
    private dialogRef: MatDialogRef<FatturaDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Consulto,
    private http: HttpClient,
  ) {
    console.log(data);
  }

  fattura: any
  inviaConsultoPerCreazioneFattura(): void {
    let params = new HttpParams();
    if(this.isReinvio){ 
      params = params.set('reinvio', 'true');
    }
    const url = ApiUrl.FATTURE_CREA;
    this.http.post(url,  this.data, {params: params}).subscribe((res) => {
      this.dialogRef.close(res);
    });
  }

  get isReinvio (): boolean {
    if(this.data.Pagamento.Fattura == undefined || this.data.Pagamento.Fattura == null){
      return false;
    }
    return this.data.Pagamento.Fattura?.ErroriUnimatica != null;
  }

  close(): void {
    this.dialogRef.close();
  }
}
