import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paziente } from 'src/app/model/paziente.model';
import { RefreshPazientiService } from '../refresh-pazienti.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ApiUrl } from 'src/app/shared/api-url';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, lastValueFrom, of } from 'rxjs';
import {
  MessageConstants,
  MessageService,
} from 'src/app/services/message.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { CAN_DEACTIVATE_MESSAGE } from 'src/app/shared/generic-constant';

const URL_PAZIENTI = ApiUrl.PAZIENTI;

export type PazienteDialogData = {
  pazienteID : number | string;
  clienteEmail: string;
}

@Component({
  selector: 'app-pazienti-detail',
  templateUrl: './pazienti-detail.component.html',
  styleUrls: ['./pazienti-detail.component.scss'],
})
export class PazientiDetailComponent implements OnInit {
  paziente!: Paziente;
  isNew: boolean = false;
  saved: boolean = false;
  @ViewChild('form') form!: NgForm;
  @Input('pazienteID') pazienteID!: number;

  get isNomeMissing (): boolean{
    return this.paziente.Nome == undefined || this.paziente.Nome == '';
  }
  get isSpecieMissing (): boolean{
    return this.paziente.Specie == undefined || this.paziente.Specie == '';
  }
  get isSessoMissing (): boolean{ 
    return this.paziente.Sesso == undefined || this.paziente.Sesso == '';
  }
  get isNumeroChipMissing (): boolean{
    return this.paziente.NChip == undefined || this.paziente.NChip == '';
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private refreshService: RefreshPazientiService,
    private http: HttpClient,
    private currentUser: CurrentUserService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PazientiDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PazienteDialogData
  ) {}

  ngOnInit(): void {
    // TODO: Data non è mai undefined. 
    if (this.data.pazienteID == undefined) {
      this.checkMode();
    } else {
      this.checkData();
    }
  }

  private checkData() {
    this.modeInput = true;
    if (this.data.pazienteID == 'new') {
      this.newPaziente(this.data.clienteEmail);
    } else {
      this.dettaglioPaziente(this.data!.toString());
    }
  }

  modeInput: boolean = false;
  private async checkMode() {
    if (this.pazienteID == undefined) {
      this.routerObserver();
    } else {
      this.paziente = await lastValueFrom(
        this.loadPaziente(this.pazienteID.toString())
      );
      this.isNew = false;
      this.modeInput = true;
    }
  }

  newPaziente(clienteEmail?: string) {
    this.isNew = true;
    
    const user = this.currentUser.currentUser;
    if (user == undefined) {
      return console.log(
        'Utente loggato non presente. Per favore effettua il logout e riprova.'
      );
    }
    this.paziente = this.nuovoPaziente(clienteEmail);
  }
  async dettaglioPaziente(id: string) {
    this.isNew = false;
    this.paziente = await lastValueFrom(this.loadPaziente(id));
  }

  private routerObserver() {
    this.route.params.subscribe(async (params) => {
      const id = params['id'];
      if (id == 'new') {
        this.paziente = this.nuovoPaziente();
      } else {
        this.dettaglioPaziente(id);
      }
    });
  }

  nuovoPaziente(clienteEmail?:string): Paziente {
    let p: Paziente = {} as Paziente;
    if(clienteEmail){
      p.UserEmail = clienteEmail;
    }else{
      p.UserEmail = this.currentUser.currentUser!.Email;
    }
    return p;
  }

  loadPaziente(id: string): Observable<Paziente> {
    const obs = this.http.get<Paziente>(URL_PAZIENTI + '/' + id);
    return obs;
  }

  salvaPaziente() {
    if (this.isNew) {
      this.creaPaziente();
    } else {
      this.updatePaziente();
    }
  }

  private navigateBack() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private updatePaziente() {
    const obs = this.http.put<Paziente>(URL_PAZIENTI, this.paziente);
    obs.subscribe((res: Paziente) => {
      if (res) {
        this.messageService.sendMessage(MessageConstants.Modifica);
        this.refreshService.refresh();
        this.saved = true;
        this.navigateBack();
      }
    });
  }
  private creaPaziente() {
    const obs = this.http.post<Paziente>(URL_PAZIENTI, this.paziente);
    obs.subscribe((res: Paziente) => {
      if (res) {
        this.messageService.sendMessage(MessageConstants.Inserimento);
        this.refreshService.refresh();
        this.saved = true;
        if (this.data.pazienteID == undefined) {
          this.navigateBack();
        } else {
          this.dialogRef.close(res);
        }
      }
    });
  }
  protected async deletePaziente() {
    if (await lastValueFrom(this.askForDeleteConfirm())) {
      const obs = this.http.delete<Paziente>(
        URL_PAZIENTI + '/' + this.paziente.Id,
        { observe: 'response' }
      );
      obs.subscribe((res: HttpResponse<Paziente>) => {
        if (res.status == 200) {
          this.messageService.sendMessage(MessageConstants.Eliminazione);
          this.refreshService.refresh();
          this.navigateBack();
        }
      });
    }
  }

  confermaEliminaMsg = $localize`:@@confermaElimina:Sei sicuro di voler eliminare il paziente `;
  private askForDeleteConfirm(): Observable<boolean> {
    let data: ConfirmDialogData = {
      message: [this.confermaEliminaMsg + ' ' + this.paziente.Nome + '?'],
    };
    return this.dialog
      .open(ConfirmDialogComponent, { data: data })
      .afterClosed();
  }

  closeDialog(){
    this.dialogRef.close();
  }


  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.pristine && !this.saved) {
      const confirm = window.confirm(CAN_DEACTIVATE_MESSAGE);
      return of(confirm);
    }
    return true;
  }
}
