
<div>
    <mat-toolbar class="internal-toolbar " >
        <div class="flex-row space-between-center fxFlex" >
            <div class="flex-row start-center gap24">
                <span class="miniTitle">Utenti</span>
                <mat-slide-toggle color="primary" [(ngModel)]="soloDaApprovare" (change)="loadUsers()" matTooltip="Visualizza solo le richieste da approvare" i18n-matTooltip >
                    <span i18n>Da approvare</span>
                </mat-slide-toggle>
            </div>
            <button mat-raised-button color="primary" class="newBtn" [routerLink]="'new'" i18n> NUOVO</button>
        </div>
    </mat-toolbar>
    <mat-table mat-table [dataSource]="listaUtenti">
        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef i18n> Email </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.Email}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nome">
            <mat-header-cell *matHeaderCellDef i18n> Nome </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.Nome + " " + utente.Cognome}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="citta">
            <mat-header-cell *matHeaderCellDef i18n> Città </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.Citta}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nomeClinica" >
            <mat-header-cell *matHeaderCellDef i18n> Nome Clinica </mat-header-cell>
            <mat-cell *matCellDef="let utente"> {{utente.NomeClinica}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="stato">
            <mat-header-cell *matHeaderCellDef i18n> Stato </mat-header-cell>
            <mat-cell *matCellDef="let utente">
                <div>
                    <span *ngIf="utente.DataAbilitazione != undefined" class="approvato" i18n>Approvato il {{utente.DataAbilitazione | date : "shortDate"}}</span>
                    <span *ngIf="utente.DataAbilitazione == undefined" class="sospeso" i18n>Richiesto accesso il {{utente.CreatedAt | date : "shortDate"}}</span>
                </div>
            </mat-cell>
        </ng-container>



        <mat-header-row *matHeaderRowDef="displayedColumns" class="table-header" ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;" [routerLink]="row.Email" ></mat-row>

    </mat-table>
</div>