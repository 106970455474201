<mat-accordion>

    <!-- CLIENTE -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="flex-row start-center gap24">
                    <mat-icon color="primary">{{iconaCliente}}</mat-icon>
                    <span>Cliente</span>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template [ngTemplateOutlet]="User"></ng-template>
    </mat-expansion-panel>

    <!-- PAZIENTE -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="flex-row start-center gap24">
                    <mat-icon color="primary">{{iconaPaziente}}</mat-icon>
                    <span>Paziente</span>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template [ngTemplateOutlet]="Paziente"></ng-template>
    </mat-expansion-panel>

    <!-- CONSULTO -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="flex-row start-center gap24">
                    <mat-icon color="primary">{{iconaConsulto}}</mat-icon>
                    <span>Consulto</span>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template [ngTemplateOutlet]="Consulto"></ng-template>
    </mat-expansion-panel>
    <!-- PAGAMENTO -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="flex-row start-center gap24">
                    <mat-icon color="primary">{{iconaFattura}}</mat-icon>
                    <span>Pagamento</span>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template [ngTemplateOutlet]="Pagamento"></ng-template>
    </mat-expansion-panel>

    <!-- ALLEGATI -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="flex-row start-center gap24">
                    <mat-icon color="primary">{{iconaAllegati}}</mat-icon>
                    <span>Allegati</span>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-allegati [isRiepilogo]="true" [consulto]="consulto!"></app-allegati>
    </mat-expansion-panel>
</mat-accordion>


<ng-template #User>
    <div class="flex-col gap12 start-stretch">
        <span class="miniTitle" i18n>DATI CLIENTE</span>
        <mat-divider></mat-divider>
        <div class="flex-row gap24 start-center">
            <span class="labels">Email</span>
            <span class="values">{{datiUtente?.Email}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Nome e Cognome</span>
            <span class="values">{{datiUtente?.Titolo}}&nbsp;{{datiUtente?.Nome}} &nbsp; {{datiUtente?.Cognome}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Codice Fiscale</span>
            <span class="values">{{datiUtente?.CodiceFiscale}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Telefono</span>
            <span class="values">{{datiUtente?.Telefono}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Indirizzo</span>
            <span class="values">{{datiUtente?.Indirizzo}}, {{datiUtente?.NumeroCivico}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Città, Comune, Provincia</span>
            <span class="values">{{datiUtente?.Citta}},&nbsp; {{datiUtente?.Comune}} ({{datiUtente?.Provincia}})</span>
        </div>
        <mat-divider></mat-divider>
        <span class="miniTitle" i18n>DATI CLINICA</span>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Nome Clinica</span>
            <span class="values">{{datiUtente?.NomeClinica}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Indirizzo Clinica</span>
            <span class="values">{{datiUtente?.IndirizzoClinica}}, {{datiUtente?.NumeroCivicoClinica}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Città, Comune, Provincia</span>
            <span class="values">{{datiUtente?.CittaClinica}},&nbsp; {{datiUtente?.ComuneClinica}}
                ({{datiUtente?.ProvinciaClinica}})</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Telefono Clinica</span>
            <span class="values">{{datiUtente?.TelefonoClinica}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Email Clinica</span>
            <span class="values">{{datiUtente?.EmailClinica}}</span>
        </div>
    </div>
</ng-template>


<ng-template #Paziente>
    <div class="flex-col gap12 start-stretch">
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Nome</span>
            <span class="values">{{datiPaziente?.Nome}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Specie</span>
            <span class="values">{{datiPaziente?.Specie}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Razza</span>
            <span class="values">{{datiPaziente?.Razza}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Età</span>
            <span class="values">{{datiPaziente?.Eta}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Peso</span>
            <span class="values">{{datiPaziente?.Peso}}</span>
        </div>
        <span class="labels" i18n>Storia Clinica</span>
        <mat-form-field>
            <textarea matInput name="StoriaClinica" readonly rows="2"
                [ngModel]="datiPaziente?.StoriaClinica"></textarea>
        </mat-form-field>
        <span class="labels" i18n>Note</span>
        <mat-form-field>
            <textarea matInput name="Note" readonly rows="2" [ngModel]="datiPaziente?.Note"></textarea>
        </mat-form-field>
        <span></span>

    </div>


</ng-template>
<ng-template #Consulto>
    <div class="flex-col gap12 start-stretch">
        <div class="flex-row gap24 start-center highLights">
            <span class="labels" i18n>Tipo Consulto</span>
            <span class="values">{{tipoConsulto(consulto!.TipoConsultoID)}}</span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Richiesto in data </span>
            <span class="values">{{consulto?.CreatedAt | date : 'dd/MM/yyyy'}}</span>
        </div>
        <span class="labels" i18n>Note</span>
        <mat-form-field>
            <textarea matInput name="Note" readonly rows="2" [ngModel]="consulto?.Note"></textarea>
        </mat-form-field>
    </div>
</ng-template>


<ng-template #Pagamento>
    <div class="flex-col gap12 start-stretch">
        <div class="flex-row gap24 start-center ">
            <span class="labels" i18n>Tipo Pagamento</span>
            <span class="values">{{datiPagamento?.TipoPagamento}} </span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Importo</span>
            <span class="values">{{datiPagamento?.Importo}} </span>
        </div>
        <div class="flex-row gap24 start-center">
            <span class="labels" i18n>Stato Pagamento</span>
            <span class="values"
                [ngClass]="
                {'pagamentoAccettato': datiPagamento!.Stato == pagamentoEffettuato, 'pagamentoSospeso': datiPagamento!.Stato == pagamentoSospeso}">
                {{datiPagamento?.Stato | uppercase}}
            </span>
        </div>
        <div class="flex-row gap24 start-center" *ngIf="datiFattura != undefined">
            <span class="labels" i18n>Data creazione</span>
            <span class="values">{{datiFattura.CreatedAt | date: 'dd/MM/yyyy'}}</span>
        </div>
        <ng-container *ngIf="datiFattura != undefined">
            <div class="flex-row gap24 start-center" >
                <span class="labels" i18n>Numero Fattura
                </span>
                <span class="values">{{progressivoExported()}} </span>
            </div>
            <div class="flex-row gap24 start-center">
                <span class="labels" i18n>Stato</span>
                <mat-icon color="accent" *ngIf="statoFattura(consulto!) === 1" matTooltip="Fattura inviata al sistema di interscambio." i18n-matTooltip>pending</mat-icon>
                <mat-icon color="primary" *ngIf="statoFattura(consulto!) === 2" matTooltip="Ricevuta SDI Riconciliata" i18n-matTooltip>receipt</mat-icon>
                <mat-icon color="warn" *ngIf="fatturaConErrori(consulto!)" matTooltip="Errore di invio fattura al sistema di interscambio" i18n-matTooltip>error</mat-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="datiFattura == undefined">
            <div class="flex-row gap24 start-center">
                <span class="labels" i18n>Stato Fattura:</span>
                <span class="values" i18n style="color: var(--accent);font-weight: 600;">{{fatturaNonCreata | uppercase}}</span>
            </div>
        </ng-container>
        
    </div>

</ng-template>
<ng-template #Allegati></ng-template>