<div style="overflow: hidden;">
    <mat-toolbar class="internal-toolbar" [ngClass]="{'modeDToolbar': modeD}">
        <div class="flex-row space-between-center fxFlex">
            <div class="flex-row start-center gap12">
                <span class="miniTitle" i18n>Calendario</span>
                <mat-slide-toggle color="primary" *appIsAuth="true" (click)="disponibilitaMode()"
                    matTooltip="Gestisci disponibilità" i18n-matTooltip>
                    <span i18n>Modalità gestione disponibilità :</span>
                    <span class="modeDIndicator">
                        {{modeD ? 'ON' : 'OFF'}}
                    </span>
                </mat-slide-toggle>
            </div>
            <div class="flex-row space-between-center ">
                <div class="flex-row star">
                    <button mat-icon-button (click)="mesePrecedente()" matTooltip="Mese precedente" i18n-matTooltip> <mat-icon>arrow_left</mat-icon> </button>
                    <div class="flex-row start-center gap12">
                        <button mat-button [ngStyle]="{'display': viewMese(indexMese + 1) ? 'block' : 'none'}"
                            *ngFor="let mese of mesi; let indexMese = index"
                            [ngClass]="{'meseCorrente': (indexMese+1 == meseCorrente), 'meseSelezionato':(indexMese +1  == meseSelezionato) }"
                            (click)="clickMese(indexMese)">{{mese}}</button>
                    </div>
                    <button mat-icon-button (click)="meseSuccessivo()" matTooltip="Mese successivo" i18n-matTooltip> <mat-icon>arrow_right</mat-icon> </button>
                </div>
                <div class="flex-row center-center">
                    <button mat-icon-button (click)="annoPrecedente()" matTooltip="Anno precedente" i18n-matTooltip>
                        <mat-icon>arrow_left</mat-icon> </button>
                    <span [ngClass]="{'meseCorrente': annoSelezionato == annoCorrente}" color="primary"
                        style="font-weight: 700; font-size: large;">{{annoSelezionato}}</span>
                    <button mat-icon-button (click)="annoSuccessivo()">
                        <mat-icon>arrow_right</mat-icon> </button>
                </div>
            </div>

            <button mat-button class="buttonToday" matTooltip="Vai alla data odierna" i18n-matTooltip (click)="goToDataOdierna()">
                <mat-icon>today</mat-icon> {{giornoCorrente + "/" + meseCorrente + "/" + annoCorrente + " " +
                oraCorrente + ":"+ minutiCorrente}}
            </button>
        </div>
    </mat-toolbar>

    <div class="flex-col start-center gap6" style="margin-top: 12px;overflow: hidden;">
        <div class="calendarWrapper">
            <div class="flex-row space-between-start fxFlex"
                style="margin-left: 8px;margin-right: 6px;min-width: 748px;">
                <button mat-icon-button style="margin-right: -28px;"
                    (click)="settimanaPrecedente()"><mat-icon>arrow_left</mat-icon></button>
                <div *ngFor="let giornoSettimana of giorniSettimanaGetter" class="flex-col giornoSettimanaLabel"
                    [ngClass]="{'dataOdierna': isDataOdierna(giornoSettimana)}">
                    <span [ngClass]="{'festivo': isFestivo(giornoSettimana)}">{{giornoSettimana}}</span>
                    <span [ngClass]="{'festivo': isFestivo(giornoSettimana)}">{{dataGiorno(giornoSettimana) | date:
                        "shortDate"}}</span>
                </div>
                <button mat-icon-button style="margin-left: -68px;"
                    (click)="settimanaSuccessiva()"><mat-icon>arrow_right</mat-icon></button>
            </div>
            <div class="flex-col start-start " style="margin-top: 12px;overflow: auto; height: calc(80vh - 88px);"
                #scroller>
                <div *ngFor="let orario of orariGiorno; let indexOrario = index" style="width: 100%;position: relative;"
                    class="fxFlex gridDisplay">

                    <div style="min-width: 62px;min-height: 72px;" class="flex-col center-center">
                        <span>
                            {{orario.split("-")[0]}}
                        </span>
                        <span>
                            {{orario.split("-")[1]}}
                        </span>
                    </div>
                    <ng-container *ngIf="datiCaricati">

                        <div *ngFor="let cell of slotsGrid; let indexGiorno = index" class="fxFlex"
                            style="position: relative; ">
                            <ng-container *ngIf="!isCliente()">
                                <div [className]="getClassList(cell[indexOrario])"
                                    (click)="cellaClicked($event, indexOrario, indexGiorno, cell[indexOrario].orario)">
                                    <div class="flex-row gap6 center-center">
                                        <span *ngIf="cell[indexOrario] && cell[indexOrario].status != statusNascosta">{{cell[indexOrario].status || ''}}</span>
                                        <span *ngIf="cell[indexOrario] && cell[indexOrario].status != statusNascosta">{{cell[indexOrario].orario | date: "shortTime"}}</span>
                                    </div>
                                    <span *ngIf="isPresent(cell[indexOrario].prenotatoDa)">Utente: {{cell[indexOrario].prenotatoDa}}</span>
                                    <span *ngIf="isPresent(cell[indexOrario].paziente)">Paziente: {{cell[indexOrario].paziente}}</span>

                                </div>
                            </ng-container>
                            <ng-container *ngIf="isCliente()">
                                <div class="dividedDiv" [className]="getClassList(cell[indexOrario])"
                                    (click)="cellaClicked($event,indexOrario, indexGiorno, cell[indexOrario].orario)">
                                    <div class="flex-row gap6 center-center">
                                        <span
                                            *ngIf="cell[indexOrario] && cell[indexOrario].status != statusNascosta && !modeD">{{
                                            cell[indexOrario].status || ''}}</span>
                                        <span *ngIf="cell[indexOrario] && cell[indexOrario].status != statusNascosta && !modeD && cell[indexOrario].orario"> {{cell[indexOrario].orario | date: "shortTime"}}</span>
                                    </div>
                                    <span *ngIf="isPresent(cell[indexOrario].prenotatoDa)">Utente: {{cell[indexOrario].prenotatoDa}}</span>
                                    <span *ngIf="isPresent(cell[indexOrario].paziente)">Paziente: {{cell[indexOrario].paziente}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</div>