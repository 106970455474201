import { Component } from '@angular/core';

@Component({
  selector: 'app-landighome',
  templateUrl: './landighome.component.html',
  styleUrls: ['./landighome.component.scss']
})
export class LandighomeComponent {

}
