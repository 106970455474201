// TODO: Campi per registrare i dati della fattura di sdi con unimatica

import { FatturaDB } from "./fattura.model";

export type Pagamento = {
  ConsultoID: number;
  TipoPagamento: number;
  Importo: number;
  Stato: string;
  UpdatedByUser?: string;
  PaypalOrderID?: string;
  PaypalPayerID?: string;
  PaypalPaymentID?: string;
  PaypalPaymentSource?: string;
  FatturaID?: number;
  Fattura?: FatturaDB; 
};

export const pagamentoSospeso = 'sospeso';
export const pagamentoEffettuato = 'effettuato';

/**
 * hasFattura Ritorna true se il pagamento ha una fattura associata
 * @param p Pagamento
 * @returns boolean
 */
export function  hasFattura(p: Pagamento): boolean{
  return p && p.FatturaID !== undefined && p.FatturaID !== null && p.FatturaID > 0;
}