<div class="flex-col start-strech gap6">
    <mat-toolbar class="internal-toolbar">
        <span class="miniTitle" i18n> {{data.title?.toLocaleUpperCase() || "RICHIESTA CONFERMA OPERAZIONE"}}</span>
    </mat-toolbar>
    <div *ngIf="data" class="flex-col" style="padding: 24px;">
        <div class="flex-col start-strech">
            <p *ngFor="let m of data.message">{{m}}</p>
        </div>
        <div class="flex-row space-between-center" style="margin-top: 12px;">
            <button class="bottoneAnnulla" *ngIf="data.showCancel || data.showCancel == undefined" mat-raised-button
                [mat-dialog-close]="false" color="accent">{{data.cancelText || "Annulla"}}</button>
            <button class="bottoneConferma" *ngIf="data.showConfirm || data.showConfirm == undefined" mat-raised-button
                [matDialogClose]="true" color="primary">{{data.confirmText || "Conferma"}}</button>
        </div>
    </div>

</div>