<div style="background-color: var(--primaryLight); height: 99%;" class="fxFlex flex-col start-stretch">

    <video #videoElement autoplay playsInline class="player-video" 
        [srcObject]="this.utente?.MediaStream" [ngStyle]="aspectRatioOtherStream ? {'aspect-ratio': aspectRatioOtherStream} : {'display': 'none'}">
    </video>

    <video #myVideoElement autoplay playsInline class="my-video box-shadow" style="transform: scaleX(-1);"
        [srcObject]="this.myMediaStream" [ngStyle]="aspectRatioMyStream ? {'aspect-ratio': aspectRatioMyStream} : {'display': 'none'}">
    </video>

</div>