type Ruolo = 'admin' | 'cliente';

export const ruoloCliente = 'cliente';
export const ruoloAdmin = 'admin';

export interface User {
  //UTENTE
  JwtToken: string; //
  Email: string; //
  Ruolo: Ruolo; // 
  Titolo: string; //
  Nome: string; //
  Cognome: string; //
  CodiceFiscale: string; //
  Telefono: string; // 
  Indirizzo: string; // 
  NumeroCivico: string; //
  Cap: string; //
  Citta: string; // 
  Comune: string,
  Provincia: string,
  Nazione: string,
  DataAbilitazione?: Date;
  Note: string;
  // CLINICA
  NomeClinica: string; //
  PartitaIvaClinica: string;
  IndirizzoClinica: string; //
  NumeroCivicoClinica: string; 
  CapClinica: string;
  CittaClinica: string;
  ComuneClinica: string,
  ProvinciaClinica: string,
  NazioneClinica: string,
  CodiceFicaleClinica: string;
  TelefonoClinica: string;
  EmailClinica: string;
  
  
  // Conference
  MediaStream: MediaStream;

  //gorm
  CreatedAt: Date;
}

export interface StatoContent {
  Online: boolean,
  Streaming: boolean,
  Fake: boolean
}

export interface IceCandidateContent {
  IceCandidate: RTCIceCandidate
}
export interface RTCDescrInitContent {
  DescriInit: RTCSessionDescriptionInit
}