import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import { Slot } from 'src/app/model/slot.model';
import { User, ruoloAdmin } from 'src/app/model/user.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChiudiMenuService } from 'src/app/services/chiudi-menu.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ApiUrl } from 'src/app/shared/api-url';
import { ICONA_UTENTE } from 'src/app/shared/icons-constant';
import { serverDate } from 'src/locale/date';

const TELECONF_PROGRAMMATE_INTERVALLO_MINUTI = 30;
const TELECONF_PROGRAMMATE_INTERVALLO_RICHIESTA = 2;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements AfterViewInit {
  constructor(
    private authService: AuthenticationService,
    private currentUser: CurrentUserService,
    private router: Router,
    private loadingService: LoadingService,
    private chiudiMenuService: ChiudiMenuService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this.chiudiMenuService.setMenuAsOpenOnMouseEnter.subscribe(
      (setAsMouseOver: boolean) => {
        this.openMenuOnMouseOver = setAsMouseOver;
      }
    );
    this.loadingService.isLoading.subscribe((loading) => {
      this.isLoading = loading;
      this.cdr.detectChanges();
    });
  }
  iconaUtente: string = ICONA_UTENTE;
  isLoading: boolean = false;
  teleconferenzeProntePerUtente: Slot[] = [];
  ngAfterViewInit(): void {
    // creo un ticker che faccia partire checkPresenzaTeleconferenzaProssimi15Minuti ogni 10 minuti
    this.checkPresenzaTeleconferenzaProssimiMinuti();
    setInterval(() => {
      this.checkPresenzaTeleconferenzaProssimiMinuti();
    }, TELECONF_PROGRAMMATE_INTERVALLO_RICHIESTA * 60000);
    this.cdr.detectChanges();
  }

  checkPresenzaTeleconferenzaProssimiMinuti() {
    if (
      this.currentUser.currentUser == undefined ||
      this.currentUser.currentUser == null
    ) {
      return;
    }
    let userRole = this.currentUser.currentUser.Ruolo;
    // Cerco se esistono consulti in teleconference programmati per i prossimi 15 minuti
    const url = ApiUrl.SLOT;
    let params = new HttpParams();
    if (userRole != ruoloAdmin) {
      params = params.set('cliente', this.currentUser.currentUser.Email);
    }
    var dDa = new Date();
    var dA = new Date(
      dDa.getTime() + TELECONF_PROGRAMMATE_INTERVALLO_MINUTI * 60000
    );
    params = params.set('data_da', serverDate(dDa, true));
    params = params.set('data_a', serverDate(dA, true));
    params = params.set('soloAccettati', true);

    const obs = this.http.get<Slot[]>(url, { params: params });
    obs.subscribe((slots) => {
      if (slots.length > 0) {
        // Se ci sono consulti in teleconference, apro la pagina di teleconference
        this.teleconferenzeProntePerUtente = slots;
      }
    });
  }

  IT = 'it-IT';
  EN = 'en-US';

  get localizedLogo(): string {
    let href = window.location.href;
    if (href.includes(this.IT)) {
      return window.location.origin + '/' + this.IT + '/assets/loghi/logo.png';
    }
    if (href.includes(this.EN)) {
      return window.location.origin + '/' + this.EN + '/assets/loghi/logo.png';
    }
    return window.location.origin + '/assets/loghi/logo.png';
  }

  goToConference() {
    if (this.teleconferenzeProntePerUtente.length > 0) {
      this.router.navigate([
        'teleconference',
        this.teleconferenzeProntePerUtente[0].ConsultoId,
      ]);
    }
  }

  openMenuOnMouseOver: boolean = false;
  menuButtonClicked() {
    this.openMenuOnMouseOver = !this.openMenuOnMouseOver;
  }
  get logged(): boolean {
    let res =
      this.currentUser.currentUser != undefined &&
      this.currentUser.currentUser != null;
    if (!res) {
      this.router.navigate(['login']);
      return false;
    }
    return res;
  }

  notifiche() {
    this.router.navigate(['notifiche']);
  }

  get utenteAttivo(): User | undefined {
    return this.currentUser.currentUser;
  }

  inizialiUtente(): string {
    let n = this.currentUser.currentUser?.Nome || '';
    let c = this.currentUser.currentUser?.Cognome || '';
    return n.substring(0, 1) + c.substring(0, 1);
  }

  dettaglioUtente() {
    this.router.navigate([
      'users',
      'detail',
      this.currentUser.currentUser?.Email,
    ]);
  }

  cambiaPassword() {}

  logOut() {
    this.authService.logout();
  }
}
