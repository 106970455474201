import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { TipoConsulto } from 'src/app/model/tipo-consulto.model';
import { MessageConstants, MessageData, MessageService } from 'src/app/services/message.service';
import { ApiUrl } from 'src/app/shared/api-url';

@Component({
  selector: 'app-listini',
  templateUrl: './listini.component.html',
  styleUrls: ['./listini.component.scss']
})
export class ListiniComponent {


  tipiConsulto: TipoConsulto[] = [];

  constructor(
    private http: HttpClient, 
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    const url = ApiUrl.TIPI_CONSULTO;
    const obs = this.http.get<TipoConsulto[]>(url);
    obs.subscribe((res: TipoConsulto[]) => {
      this.tipiConsulto = res;
    });
  }

  salva(){
    const url = ApiUrl.TIPI_CONSULTO + '/update';
    const obs = this.http.put(url, this.tipiConsulto, {observe: 'response'});
    obs.subscribe((res: HttpResponse<any>) => {
      if(res.status === 200){
        let m : MessageData = MessageConstants.Modifica;
        m.Message = "Modifiche al listino salvate con successo";
        this.messageService.sendMessage(m);
      }else{
        let m : MessageData = MessageConstants.Errore;
        m.Message = "Errore nel salvataggio del listino";
        this.messageService.sendMessage(m);
      }
    });
  }

}
