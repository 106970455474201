export const CAN_DEACTIVATE_MESSAGE: string =
  'Ci sono modifiche non salvate. Sei sicuro di voler uscire?';

  export const listaMesi: string[] = [
    $localize`:@@gennaio:Gennaio`,
    $localize`:@@febbraio:Febbraio`,
    $localize`:@@marzo:Marzo`,
    $localize`:@@aprile:Aprile`,
    $localize`:@@maggio:Maggio`,
    $localize`:@@giugno:Giugno`,
    $localize`:@@luglio:Luglio`,
    $localize`:@@agosto:Agosto`,
    $localize`:@@settembre:Settembre`,
    $localize`:@@ottobre:Ottobre`,
    $localize`:@@novembre:Novembre`,
    $localize`:@@dicembre:Dicembre`,
  ];

  export const giorniSettimana: string[] = [
    $localize`:@@lunedi:lunedì`,
    $localize`:@@martedi:martedì`,
    $localize`:@@mercoledi:mercoledì`,
    $localize`:@@giovedi:giovedì`,
    $localize`:@@venerdi:venerdì`,
    $localize`:@@sabato:sabato`,
    $localize`:@@domenica:domenica`,
  ];

export const listaOrari: string[] = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
];

export const pagamentoPaypal = 1;
export const pagamentoBonifico = 2;
