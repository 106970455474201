import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Paziente } from 'src/app/model/paziente.model';
import { ruoloCliente } from 'src/app/model/user.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ApiUrl } from 'src/app/shared/api-url';
import { RefreshPazientiService } from '../refresh-pazienti.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-pazienti-lista',
  templateUrl: './pazienti-lista.component.html',
  styleUrls: ['./pazienti-lista.component.scss'],
})
export class PazientiListaComponent implements OnInit {
  constructor(
    private currentUser: CurrentUserService,
    private http: HttpClient,
    private refreshService: RefreshPazientiService,
    private loadingService: LoadingService,
  ) {
   
  }

  displayedColumns: string[] = [
    "utente",
    "nome",
    "propretario",
    "specie",
    "razza",
    "sesso",
    "eta",
    "peso",
    "chip",
  ]

  ngOnInit(): void {
    this.refreshService.mustRefresh.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }

  listaPazienti: Paziente[] = [];

  erroreUtenteNonLoggato = $localize`:@@erroreUtenteNonLoggato:Utente loggato non presente. Per favore effettua il logout e riprova.`;
  loadData() {
    let loggedUser = this.currentUser.currentUser;
    if (loggedUser == undefined) {
      return console.log(
       this.erroreUtenteNonLoggato
      );
    }
    const url = ApiUrl.PAZIENTI;
    let params = new HttpParams();
    if (loggedUser.Ruolo == ruoloCliente) {
      params = params.append('cliente', loggedUser.Email);
    }
    const obs = this.http.get<Paziente[]>(url, { params: params });
    this.loadingService.isLoading.next(true);
    obs.subscribe((res: Paziente[]) => {
      this.loadingService.isLoading.next(false);
      this.listaPazienti = res;
    });
  }

}
