import { Component } from '@angular/core';
import { RefreshUserService } from '../refresh-user.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from 'src/app/model/user.model';
import { ApiUrl } from 'src/app/shared/api-url';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-user-lista',
  templateUrl: './user-lista.component.html',
  styleUrls: ['./user-lista.component.scss']
})
export class UserListaComponent {

  constructor(
    private refreshUserService: RefreshUserService,
    private http: HttpClient,
    private dialog: MatDialog,
    private loadingService: LoadingService,

  ) { }

  displayedColumns: string[] = ['email', 'nome', 'citta', 'nomeClinica', 'stato'];
  listaUtenti: User[] = [];
  soloDaApprovare: boolean = false;

  ngOnInit() {
    this.refreshUserService.mustRefresh.subscribe(() => {
      this.loadUsers();
    });
    this.loadUsers();
  }

  loadUsers() {
    const url = ApiUrl.USERS;
    let params: HttpParams = new HttpParams();
    if (this.soloDaApprovare) {
      params = params.append('soloDaApprovare', 'true');
    }
    this.loadingService.isLoading.next(true);
    this.http.get<User[]>(url, {params: params}).subscribe((data) => {
      this.loadingService.isLoading.next(false);
      this.listaUtenti = data;
    });
  }
  

}
