import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  public userSubject = new BehaviorSubject<User | undefined>(undefined);
  public readonly userObservable = this.userSubject.asObservable();
  get currentUser(): User | undefined {
    return this.userSubject.value;
  }
  changeCurrentUser(user: User | undefined): void {
    this.userSubject.next(user);
    console.log('Current user changed to: ', user);
  }
}
