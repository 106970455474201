import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { Slot } from 'src/app/model/slot.model';
import { ruoloCliente } from 'src/app/model/user.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import {
  MessageConstants,
  MessageData,
  MessageService,
} from 'src/app/services/message.service';
import { ApiUrl } from 'src/app/shared/api-url';
import { serverDate } from 'src/locale/date';

export const URL: string = ApiUrl.SLOT;

const messageOptions: MatSnackBarConfig = {
  duration: 1000,
  horizontalPosition: 'left',
  verticalPosition: 'bottom',
};

@Injectable({
  providedIn: 'root',
})
export class DatasourceCalendarioService {
  constructor(
    private http: HttpClient,
    private currentUser: CurrentUserService,
    private messageService: MessageService
  ) {
    this.dataDa.subscribe((data) => {
      if (data) {
        this.loadData();
      }
    });
  }
  isFirstAnno: boolean = true;
  isFirstMese: boolean = true;

  public dataDa: BehaviorSubject<Date | undefined> = new BehaviorSubject<
    Date | undefined
  >(undefined);
  public listaSlot: BehaviorSubject<Slot[]> = new BehaviorSubject<Slot[]>([]);

  public loadData() {
    if (!this.currentUser.currentUser) {
      return;
    }
    let params = new HttpParams();
    if (this.dataDa.value == null) {
      return;
    }
    params = params.set(
      'data_da', serverDate(this.dataDa.value)
    );
    // impsto il parametro data_a a 7 giorni dopo
    let dataA = new Date(this.dataDa.value);
    dataA.setDate(dataA.getDate() + 7);
    params = params.set(
      'data_a', serverDate(dataA)
    );
    if (this.currentUser.currentUser.Ruolo == ruoloCliente) {
      params = params.set('cliente', this.currentUser.currentUser.Email);
    }
    const obs = this.http.get<Slot[]>(URL, { params: params }).pipe(
      map((data) => {
        return data.map((slot) => {
          return {
            DataInizio: new Date(slot.DataInizio),
            ConsultoId: slot.ConsultoId,
            Consulto: slot.Consulto,
          };
        });
      })
    );
    obs.subscribe((slots) => {
      this.listaSlot.next(slots);
    });
  }

  public addSlotDisponibilita(data: Slot) {
    let orarioInizio = new Date(data.DataInizio);
    const slot: Slot = { DataInizio: orarioInizio };
    if (this.checkIfSlotIsAlreadyOccupied(slot)) {
      let m: MessageData = MessageConstants.Errore;
      m.Message = 'Esiste già uno slot in quella fascia oraria';
      this.messageService.sendMessage(m, messageOptions);
      return;
    }
    // Cerco se esiste uno slot con la stessa data e orario di inizio

    this.http.post<Slot>(URL, data).subscribe(() => {
      let m: MessageData = MessageConstants.Inserimento;
      m.Message = 'Disponibilià aggiunta correttamente';
      this.messageService.sendMessage(m, messageOptions);
      this.loadData();
    });
  }

  public removeSlotDisponibilita(slot: Slot) {
    const s = this.findSlot(slot);
    if (s) {
      let stringDate = serverDate(s.DataInizio, true)
      const url = URL + '/' + stringDate;
      this.http
        .delete<Slot>(url, { observe: 'response' })
        .subscribe((res: HttpResponse<any>) => {
          if (res.status == 200) {
            let m: MessageData = MessageConstants.Eliminazione;
            m.Message = 'Disponibilità eliminata correttamente';
            this.messageService.sendMessage(m, messageOptions);
            this.loadData();
          }
        });
    }
  }

  findSlot(slotToFind: Slot): Slot | undefined {
    return this.listaSlot.value.find((slot) => {
      return this.isSameDate(slot.DataInizio, slotToFind.DataInizio);
    });
  }

  checkIfSlotIsAlreadyOccupied(data: Slot): Slot | undefined {
    return this.listaSlot.value.find((slot) => {
      let dataToSearch = new Date(data.DataInizio);
      let dataToSearchPlus = new Date(dataToSearch.getTime() + 15 * 60000);
      let dataToSearchMinus = new Date(dataToSearch.getTime() - 15 * 60000);

      return (
        this.isSameDate(slot.DataInizio, dataToSearch) ||
        this.isSameDate(slot.DataInizio, dataToSearchPlus) ||
        this.isSameDate(slot.DataInizio, dataToSearchMinus)
      );
    });
  }

  isSameDate(data1: Date, data2: Date): boolean {
    let d1 = new Date(data1);
    let d2 = new Date(data2);
    const d1y = d1.getFullYear();
    const d1m = d1.getMonth();
    const d1d = d1.getDate();
    const d1h = d1.getHours();
    const d1min = d1.getMinutes();
    const d2y = d2.getFullYear();
    const d2m = d2.getMonth();
    const d2d = d2.getDate();
    const d2h = d2.getHours();
    const d2min = d2.getMinutes();
    return (
      d1y == d2y && d1m == d2m && d1d == d2d && d1h == d2h && d1min == d2min
    );
  }
}
