import { Component, Input } from '@angular/core';
import { MessageConstants, MessageData, MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-valori-default',
  templateUrl: './valori-default.component.html',
  styleUrls: ['./valori-default.component.scss']
})
export class ValoriDefaultComponent {

  constructor(
    private messageService: MessageService,
  ) { }

  @Input('valoreDefault')
  _valoreDefault!: string; 
  get valoreDefault(): string{
    return this._valoreDefault;
  } 
  set valoreDefault(value: string){
    this._valoreDefault = value;
  }

  copyToClipboard(){
    navigator.clipboard.writeText(this.valoreDefault);
    let m: MessageData = MessageConstants.Info;
    m.Message = "Valore copiato negli appunti";
    this.messageService.sendMessage(m);
  }
}
