<div class="flex-col fxFlex start-center" style="min-height: 300px;">

    <div class="flex-row" style="margin-top: 24px;">
        <div class="fxFlex flex-row start-center">
            <mat-label class="fxFlex miniTitle" i18n>Scegli un mese per visualizzare le disponibilià di
                calendario</mat-label>
            <mat-form-field appearance="outline" class="fxFlex">
                <mat-select name="mese" [(ngModel)]="mese" placeholder="scegli il mese" i18n-placeholder
                    (ngModelChange)="meseChanged($event)">
                    <mat-optgroup>
                        <mat-option [value]="undefined" i18n>Non voglio fissare una data</mat-option>
                    </mat-optgroup>
                    <mat-optgroup>
                        <mat-option *ngFor="let mese of mesi" [value]="mese">{{mese}}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="flex-rowGap start-stretch gap12" *ngIf="dataSelezionata == undefined ">
        <ng-container *ngIf="mese != -1">
            <span class="miniTitle" i18n> Giorni disponibili nel mese di {{meseDescr(mese)}} :</span>
            <div  class="slot center-center flex-col" *ngFor="let data of listaDate()" (click)="dataScelta(data)">
                {{(data | date: 'fullDate') | titlecase}}
            </div>
        </ng-container>
        
        <span style="margin-top: 42px;" class="miniTitle" *ngIf="mese == -1" i18n>Oppure passa al prossimo passaggio se non vuoi fissare una data per una teleconferenza</span>
    </div>
    <div class="flex-col start-center gap12" *ngIf="dataSelezionata != undefined ">
        <span class="miniTitle" i18n> Slot disponibili per il giorno {{(dataSelezionata | date: 'fullDate') | titlecase}} :
        </span>
        <span i18n>(Gli slot hanno una durata di 30 minuti.)</span>
        <div class="flex-rowGap center-center">
            <div class="slot center-center flex-col" *ngFor="let slot of listaSlotFiltrata" (click)="slotScelto(slot)" [ngClass]="{'slotSelected': isSelected(slot)}">
                {{slot.DataInizio | date: 'HH:mm'}}
            </div>
        </div>
    </div>
</div>