import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { CurrentUserService } from '../current-user.service';
import { environment } from 'src/environments/environment';

export enum TipoMessaggio {
  Stato = 'STATO',
  Offer = 'OFFER',
  IceCandidate = 'ICE_CANDIDATE',
  Answer = 'ANSWER',
  Other = '',
}

export interface Messaggio {
  ID?: number;
  Content: {};
  DataOra?: Date;
  UtenteEmail?: string;
  Utente?: User;
  Dest?: string;
  Tipo?: TipoMessaggio;
}

@Injectable({
  providedIn: 'root',
})
export class SignalingService {
  private signalingSocket: WebSocket | undefined;
  error: Subject<Event> = new Subject<Event>();
  open: Subject<void> = new Subject<void>();
  close: Subject<void> = new Subject<void>();
  message: Subject<Messaggio> = new Subject<Messaggio>();
  access: Subject<Messaggio> = new Subject<Messaggio>();

  constructor(private loginService: CurrentUserService) {}

  isJWTValid = (user: User | undefined) => {
    return (
      user &&
      user.JwtToken != '' &&
      user.JwtToken != null &&
      user.JwtToken != undefined
    );
  };
  opened: boolean = false;
  initSignaling(consultoId: string): void {
    this.loginService.userObservable.subscribe((user) => {
      if (this.isJWTValid(user) && !this.opened) {
        this.opened = true;
        const params: { [key: string]: string } = {
          consultoID: consultoId,
        };
        // Creo la url con i parametri query. Non posso usare HttpParams perchè non faccio una richiesta http.
        const queryString = Object.keys(params)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          )
          .join('&');
        const url = `${environment.wsurl}/${user!.JwtToken}?${queryString}`;
        console.log('URL: ', url);
        // Apro la connessione
        this.signalingSocket = new WebSocket(url);

        this.signalingSocket.onerror = (event) => this.error.next(event);
        this.signalingSocket.onopen = () => this.open.next();
        this.signalingSocket.onclose = () => this.close.next();
        this.signalingSocket.onmessage = (message) => {
          try {
            let m = <Messaggio>JSON.parse(message.data);
            switch (m.Tipo) {
              case TipoMessaggio.Stato:
                this.access.next(m);
                break;
              case TipoMessaggio.IceCandidate:
                this.message.next(m);
                break;
              default:
                this.message.next(m);
                break;
            }
          } catch (e) {
            console.error('onmessage', message, e);
          }
        };
      }
    });
  }

  send(messaggio: Messaggio): void {
    if (this.signalingSocket == undefined) {
      return;
    }

    switch (this.signalingSocket.readyState) {
      case WebSocket.CLOSED:
        console.log('connection is closed');
        break;
      case WebSocket.OPEN:
        this.signalingSocket.send(JSON.stringify(messaggio));
        console.log("connection is open");
        break;
      default:
        console.error('send', messaggio);
        break;
    }
  }

  stopSignaling() {
    this.signalingSocket?.close();
  }
}
