import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | boolean | UrlTree | Promise<boolean> | Promise<UrlTree>;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard {

  canDeactivate(component: CanComponentDeactivate, router : ActivatedRouteSnapshot, state: RouterStateSnapshot ){
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
