export const ICONA_UTENTE = "people";
export const ICONA_PAZIENTE = "pets";
export const ICONA_CONSULTO = "event";
export const ICONA_CALENDARIO = "calendar_month";
export const ICONA_LISTINO = "payments";
export const ICONA_FATTURA = "receipt";
export const ICONA_ALLEGATI = "attach_file";
export const FATTURA_INVIATA = "pending";
export const FATTURA_NON_INVIATA = "cancel_schedule_send";
export const FATTURA_RICONCILIATA = "receipt";
export const FATTURA_CON_ERRORI = "error";
