<div matDialogTitle color="primary">Password Recovery</div>
<div matDialogContent fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
  <div fxFlex>Insert your email.</div>
  <div fxFlex>
    <mat-form-field>
      <mat-label>Email address</mat-label>
      <input #emailbox type="email" matInput />
    </mat-form-field>
  </div>
</div>
<div matDialogActions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="gappx">
  <button mat-raised-button color="primary" [mat-dialog-close]="emailbox.value">
    Ok
  </button>
  <button mat-button color="accent" mat-dialog-close>Close</button>
</div>