import { AuthenticationService } from './../services/authentication.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtAuthInterceptor implements HttpInterceptor {


  constructor(private auth: AuthenticationService) {}


  intercept(req: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    let loginInfo = this.auth.currentLoginInfo;
    if (loginInfo && loginInfo.Jwttoken && req.url.indexOf(environment.apiUrl) == 0) {
      if (req.headers.get('Content-Type')) {
        req = req.clone({
          setHeaders: {Authorization: `Bearer ${loginInfo.Jwttoken}`,'access-control-allow-origin': '*', },
          params: (req.params ? req.params : new HttpParams()),
        });
      } else {
        req = req.clone({
          setHeaders: {Authorization: `Bearer ${loginInfo.Jwttoken}`,
          'Content-Type': 'application/json; charset=utf-8;','access-control-allow-origin': '*',},
          params: (req.params ? req.params : new HttpParams()),
        });
      }
    }
    return next.handle(req);
  }

  localDate(): string {
    const d = new Date();
    const res =
      d.getDate() +
      '/' +
      (d.getMonth() + 1) +
      '/' +
      d.getFullYear() +
      '-' +
      d.getHours() +
      ':' +
      d.getMinutes() +
      ':' +
      d.getSeconds();
    return res;
  }
}
