import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, lastValueFrom, of } from 'rxjs';
import { Allegato } from 'src/app/model/allegato.model';
import { Consulto } from 'src/app/model/consulto.model';
import { ConsultoUnsavedDataService } from 'src/app/services/consulto-unsaved-data.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { MessageConstants, MessageData, MessageService } from 'src/app/services/message.service';
import { ApiUrl } from 'src/app/shared/api-url';

export const dimensioneMassimaFile = 10;

@Component({
  selector: 'app-allegati',
  templateUrl: './allegati.component.html',
  styleUrls: ['./allegati.component.scss'],
})
export class AllegatiComponent  {
  constructor(
    private http: HttpClient,
    private currentUser: CurrentUserService,
    private messageService: MessageService,
    private modifiche: ConsultoUnsavedDataService,
  ) {}

  @Input('isRiepilogo') isRiepilogo: boolean = false;

  dimensioneMassimaFile = dimensioneMassimaFile;

  _consulto!: Consulto;
  @Input() set consulto(val: Consulto) {
    this._consulto = val;
  }
  get consulto(): Consulto {
    return this._consulto;
  }
  @Output() allegati: EventEmitter<Allegato[]> = new EventEmitter<Allegato[]>();
  @Output() eliminaAllegato: EventEmitter<Allegato> =
    new EventEmitter<Allegato>();

  private emitAllegati(newAtt: Allegato[]) {
    this.allegati.next(newAtt);
  }

  disabilitaInputDescrizione(allegato: Allegato): boolean {
    return allegato.ConsultoID != 0 && allegato.ConsultoID != undefined;
  }

  fileName: string = '';
  /**
   * OnFileSelected si occupa di gestire l'evento di selezione di uno o più file dall'input file
  Calcola le dimensioni dell'immagine se il file è un'immagine, o del pdf se il file è un pdf
  Ci servono per stabilire l'aspect ratio dell'immagine o del pdf nel component che ne visualizza l'anteprima
  
   * @param event 
   * @returns 
   */
  async onFileSelected(event: any) {
    if (this.currentUser.currentUser == undefined) {
      return;
    }
    let files: File[] = [];
    if (event == undefined) {
      return;
    }
    let listaAllegati: Allegato[] = [];
    files = event.target.files;
    for (let file of files) {
      if (!file) {
        return;
      }
      let allegato: Allegato = await lastValueFrom(this.recuperaUrlUpload());
      this.fileName = file.name;
      const fileSizeMB = file.size / 1024 / 1024;
      if (fileSizeMB > dimensioneMassimaFile) {
        this.avvisaDimensioneMassima();
        return;
      }
      const fileType = file.type;
      let dimensions = { width: 0, height: 0 };
      if (fileType == 'image/jpeg' || fileType == 'image/png') {
        dimensions = await this.calcolaDimensioni(file);
      }
      if (fileType == 'application/pdf') {
        let w = 100;
        let h = 100 * Math.sqrt(2);
        dimensions = { width: w, height: h };
      }
      console.log('dimensions', dimensions);
      allegato.ID = -1;
      allegato.Nome = file.name;
      allegato.File = file;
      allegato.CreatedBy = this.currentUser.currentUser.Email;
      allegato.Dimensione = file.size;
      allegato.Width = dimensions.width;
      allegato.Height = dimensions.height;
      listaAllegati.push(allegato);
    }
    this.modifiche.modificheNonSalvate.next(true);
    this.emitAllegati(listaAllegati);
  }

  private avvisaDimensioneMassima(){
    let m: MessageData = MessageConstants.Errore
    m.Message = 'Dimensione massima consentita 10MB. Impossibile caricare il file.'
    this.messageService.sendMessage(m);
  }

  async calcolaDimensioni(
    file: File
  ): Promise<{ width: number; height: number }> {
    let img = new Image();
    let imgLoad = new Promise<{ width: number; height: number }>(
      (resolve, reject) => {
        img.onload = () =>
          resolve({ width: img.naturalWidth, height: img.naturalHeight });
        img.onerror = reject;
      }
    );
    img.src = URL.createObjectURL(file);
    let dimensions = await imgLoad;
    return dimensions;
  }

  private recuperaUrlUpload(): Observable<Allegato> {
    let url: string = ApiUrl.ALLEGATI_UPLOAD;
    return this.http.get<Allegato>(url);
  }

  async deleteAllegato(allegato: Allegato) {
    const confirm = await lastValueFrom(this.chiediConfermaEliminaAllegato());
    if (!confirm) {
      return;
    }
    this.eliminaAllegato.next(allegato);
    this.modifiche.modificheNonSalvate.next(true);
    return;
  }
  private chiediConfermaEliminaAllegato(): Observable<boolean> {
    return of(window.confirm("Sei sicuro di voler eliminare l'allegato?"));
  }

  private recuperaUrlDownload(id: string): Observable<Allegato> {
    let url: string = ApiUrl.ALLEGATI_DOWNLOAD + '/' + id;
    return this.http.get<Allegato>(url);
  }

  downloadAllegato(allegato: Allegato) {
    if (allegato == undefined || allegato.AwsID == '') {
      return;
    }
    const obs = this.recuperaUrlDownload(allegato.AwsID!);
    obs.subscribe((data) => {
      window.open(data.AwsUrl, '_blank');
    });
  }

  allegatiFiltered() {
    if (this.consulto == undefined || this.consulto.Allegati == undefined) {
      return [];
    }
    return this.consulto.Allegati.filter((a) => !a.ToDelete);
  }
}
