import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CurrentUserService } from '../services/current-user.service';
import { ruoloCliente } from '../model/user.model';

@Directive({
  selector: '[appIsAuth]'
})
export class IsAuthDirective {

  /**
   * appIsAuth si occupa di mostrare o nascondere un componente in base al ruolo dell'utente loggato.
    * si dichiara come *appIsAuth="true" per mostrare il componente solo se l'utente loggato è admin.
    * se è impostato a false, il componente verrà mostrato comunque.
   * @param templateRef 
   * @param currentUser 
   * @param vcr 
   */
  constructor(
    private templateRef: TemplateRef<any>,
    private currentUser: CurrentUserService,
    private vcr: ViewContainerRef
  ) { }

  @Input() set appIsAuth(check: boolean) {
    if(check){
      this.checkAuth();
    }else{
      this.vcr.createEmbeddedView(this.templateRef);
    }
  }

  checkAuth() {
    let ruoloUtenteLoggato = this.currentUser.currentUser?.Ruolo;
    if(ruoloUtenteLoggato == undefined){
      this.vcr.clear();
      return;
    }
    if(ruoloUtenteLoggato == ruoloCliente){
      this.vcr.clear();
    }else{
      this.vcr.createEmbeddedView(this.templateRef);
    }
  }

}
