import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, lastValueFrom, of } from 'rxjs';
import { User, ruoloCliente } from 'src/app/model/user.model';
import { ApiUrl } from 'src/app/shared/api-url';
import { RefreshUserService } from '../refresh-user.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  MessageConstants,
  MessageData,
  MessageService,
} from 'src/app/services/message.service';
import { LoginRichiestaAccessoCoordinatorService } from 'src/app/services/login-richiesta-accesso-coordinator.service';
import { ValidatoreEmail } from 'src/app/shared/validatore-email';
import { NgForm } from '@angular/forms';
import { CAN_DEACTIVATE_MESSAGE } from 'src/app/shared/generic-constant';
import { province } from 'src/app/shared/province';
import { nazioni } from 'src/app/shared/nazioni';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  richiediAccessoLabel = $localize`Richiedi accesso`;
  dettaglioUtenteLabel = $localize`Dettaglio utente`;

  richiediLabel = $localize`Richiedi`;
  salvaLabel = $localize`SALVA`;

  utente!: User;
  isNew: boolean = false;
  @ViewChild('form') form!: NgForm;
  saved: boolean = false;

  listaProvince = province;
  listaNazioni = nazioni;

  @Input('userEmail') userEmail!: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private refreshUserService: RefreshUserService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private loginCoordinator: LoginRichiestaAccessoCoordinatorService
  ) {}

  get isRichiestaAccesso(): boolean {
    return this.loginCoordinator.isRichiestaAccesso.value;
  }

  ngOnInit(): void {
    this.checkMode();
  }

  backToLogin() {
    this.loginCoordinator.setRichiestaAccesso(false);
  }

  modeInput: boolean = false;
  async checkMode() {
    // Controllo se ho l'ID dell'utente in input
    if (this.userEmail != undefined && this.userEmail != '') {
      this.modeInput = true;
      this.utente = await lastValueFrom(this.recuperaUtente(this.userEmail));
    } else {
      this.routeSub();
    }
  }

  routeSub() {
    this.route.params.subscribe(async (params) => {
      const p = params['id'];
      if (p == undefined) {
        this.loginCoordinator.setRichiestaAccesso(true);
        this.isNew = true;
        this.utente = this.nuovoUtente();
      }
      if (p == 'new') {
        this.isNew = true;
        this.utente = this.nuovoUtente();
      } else if (p != undefined) {
        this.utente = await lastValueFrom(this.recuperaUtente(p));
      }
    });
  }

  async disabilitaUtente() {
    const res = await lastValueFrom(
      this.askForModificaStatoApprovazione(false)
    );
    if (!res) {
      return;
    }
    this.utente.DataAbilitazione = undefined;
    this.updateUtente();
  }

  async approvaUtente() {
    const res = await lastValueFrom(this.askForModificaStatoApprovazione(true));
    if (!res) {
      return;
    }
    this.utente.DataAbilitazione = new Date();
    this.updateUtente();
  }

  private askForModificaStatoApprovazione(
    stoAbilitando: boolean
  ): Observable<boolean> {
    let data: ConfirmDialogData = {} as ConfirmDialogData;
    if (stoAbilitando) {
      data = {
        message: [
          "Sei sicuro di voler approvare l'accesso all'utente " +
            this.utente.Email +
            '?',
          "L'utente riceverà una mail di conferma con le credenziali di accesso.",
        ],
      };
    } else {
      data = {
        message: [
          "Sei sicuro di voler disabilitare l'accesso all'utente " +
            this.utente.Email +
            '?',
          "L'utente non potrà più accedere all'applicazione.",
        ],
      };
    }
    return this.dialog
      .open(ConfirmDialogComponent, { data: data })
      .afterClosed();
  }

  private nuovoUtente(): User {
    let u: User = {} as User;
    return u;
  }
  private recuperaUtente(email: string): Observable<User> {
    const url = ApiUrl.USERS + '/' + email;
    const obs = this.http.get<User>(url);
    return obs;
  }

  eseguiRichiesta() {
    if (this.utente.CodiceFiscale != undefined) {
      this.utente.CodiceFiscale = this.utente.CodiceFiscale.toUpperCase();
    }
    if (this.utente.CodiceFicaleClinica != undefined) {
      this.utente.CodiceFicaleClinica =
        this.utente.CodiceFicaleClinica.toUpperCase();
    }
    if (this.isNew) {
      if (this.isRichiestaAccesso) {
        this.richiediAccesso();
      } else {
        this.creaUtente();
      }
    } else {
      this.updateUtente();
    }
  }

  goBack() {
    if (this.isRichiestaAccesso) {
      this.router.navigate(['../../login'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  private creaUtente() {
    // Se sto inserendo un nuovo utente da questa pagina
    // Significa che lo sta inserendo un admin ed è già abilitato
    this.utente.DataAbilitazione = new Date();
    const url = ApiUrl.USERS;
    this.http.post(url, this.utente).subscribe((res) => {
      if (res) {
        let m: MessageData = MessageConstants.Inserimento;
        this.messageService.sendMessage(m);
        this.refreshUserService.refresh();
        this.saved = true;
        this.goBack();
      }
    });
  }
  private updateUtente() {
    const url = ApiUrl.USERS;
    this.http.put(url, this.utente).subscribe((res) => {
      let m: MessageData = MessageConstants.Modifica;
      this.messageService.sendMessage(m);
      if (res) {
        this.refreshUserService.refresh();
        this.saved = true;
        this.goBack();
      }
    });
  }

  async deleteUtente() {
    const res = await lastValueFrom(this.askConfirmDelete());
    const url: string = ApiUrl.USERS + '/' + this.utente.Email;
    this.http.delete(url, { observe: 'response' }).subscribe((res) => {
      if (res.status == 200) {
        let m: MessageData = MessageConstants.Eliminazione;
        this.messageService.sendMessage(m);
        this.refreshUserService.refresh();
        this.backToList();
        this.saved = true;
      }
    });
  }

  backToList() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  askConfirmDelete() {
    let data: ConfirmDialogData = {
      message: [
        "Sei sicuro di voler eliminare l'utente " + this.utente.Email + '?',
        "L'utente non potrà più accedere all'applicazione.",
      ],
    };
    return this.dialog
      .open(ConfirmDialogComponent, { data: data })
      .afterClosed();
  }

  richiediAccesso() {
    const url = ApiUrl.USERS + '/richiedi-accesso';
    this.utente.Ruolo = ruoloCliente;
    this.http.post<any>(url, this.utente, { observe: 'response' }).subscribe({
      next: (res: HttpResponse<any>) => {
        if (res.status == 200) {
          let m: MessageData = MessageConstants.RichiestaAccesso;
          this.messageService.sendMessage(m);
          this.loginCoordinator.setRichiestaAccesso(false);
          this.saved = true;
        }
      },
      error: (err) => {
        let m: MessageData = MessageConstants.Errore;
        m.Message = err;
        this.messageService.sendMessage(m);
      },
    });
  }

  isMailValid(): boolean {
    return ValidatoreEmail(this.utente.Email);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.pristine && !this.saved) {
      const c = window.confirm(CAN_DEACTIVATE_MESSAGE);
      return of(c);
    }
    return true;
  }
}
