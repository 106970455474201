import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DefaultApparatoGastrointericoEPancreas, DefaultApparatoGenitale, DefaultFegato, DefaultLinfonodi, DefaultMilza, DefaultReferto, DefaultReni, DefaultSurreni, DefaultVescica } from 'src/app/model/referti/default-value.model';
import { Ecografia } from 'src/app/model/referti/ecografia.model';
import { ruoloCliente } from 'src/app/model/user.model';
import { ConsultoUnsavedDataService } from 'src/app/services/consulto-unsaved-data.service';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
  selector: 'app-ecografia',
  templateUrl: './ecografia.component.html',
  styleUrls: ['./ecografia.component.scss'],
})
export class EcografiaComponent implements OnDestroy {
  constructor(
    private currentUser: CurrentUserService,
    private modifiche: ConsultoUnsavedDataService,
  ) {}


  subs: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  defFegato = DefaultFegato;
  defMilza = DefaultMilza;
  defApparatoGastrointericoEPancreas = DefaultApparatoGastrointericoEPancreas;
  defLinfnodi = DefaultLinfonodi;
  defReni = DefaultReni;
  defSurreni = DefaultSurreni;
  defVescica = DefaultVescica;
  defApparatoGenitale = DefaultApparatoGenitale;
  defReferto = DefaultReferto;

  @ViewChild('f') form: NgForm | undefined;

  // Ci serve per valutare se il form è stato inizializzato
  formInit: boolean = false;
  get hasForm(): void {
    if(this.form == undefined || this.formInit == true) {
      return ;
    }else{
      this.formInit = true;
      console.log("FORM INIT");
      /**
      Si esegue la subscription in un timeout per evitare che la prima assegnazione del form,
      dopo il recupero dei dati dal server, venga considerata come una modifica
       */
      setTimeout(() => {
        this.formSub();
      }, 500);
    }
  }

  formSub(){
    this.subs.add(
    this.form?.valueChanges?.subscribe((_) => {
      this.modifiche.modificheNonSalvate.next(true);
    }));
  }



  _referto: Ecografia | undefined;
  @Input() set referto(value: Ecografia | undefined) {
    this._referto = value;
  }
  get referto(): Ecografia | undefined {
    return this._referto;
  }

  get isCliente(): boolean {
    if (this.currentUser.currentUser == undefined) {
      return false;
    }
    return this.currentUser.currentUser?.Ruolo == ruoloCliente;
  }

}
