import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

@Injectable()
export class AauDateAdapter extends NativeDateAdapter {

    override getFirstDayOfWeek(): number {
      return 1;
    }

    override parse(value: any): Date | null {
      if (!value) { return null; }
      return this.toDate(value);
    }

    private toDate(dateStr: string) {
      const [day, month, year] = dateStr.split(/[-\/.]/);
      return new Date(+year, +month - 1, +day);
    }

  }
