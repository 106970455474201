<div class="flex-col start-strech gap12">
    <mat-toolbar class="internal-toolbar">
        <div class="flex-row space-between-center fxFlex">
            <span class="miniTitle" i18n>Lista mail per notifiche</span>
            <button mat-raised-button color="accent" class="saveBtn" [tabIndex]="8" (click)="salva()">SAVE</button>
        </div>
    </mat-toolbar>
    <div class="flex-col start-strech gap12" style="padding: 12px;">
        <span class="miniTitle"> Seleziona dalla lista, gli indirizzi mail ai
            quali inviare una notifica all'inserimento di una nuova richiesta di consulto </span>

        <div class="flex-col start-strech" *ngFor="let contatto of listaAdmin">
            <div style="padding-left: 12px;">
                <div class="flex-row start-center ">
                    <div class="flex70">
                        <div class="flex-col">
                            <span style="font-weight: 800;">{{contatto.Cognome}}&nbsp;{{contatto.Nome}}</span>
                            <span>{{contatto.Email}}</span>
                        </div>
                    </div>
                    <div class="flex30">
                        <mat-checkbox [checked]="isSelected(contatto)" (change)="checkedChange(contatto, $event)"></mat-checkbox>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        
        </div>
        <div style="padding-left: 12px;" class="flex-col gap12">
            <div *ngFor="let aggiunto of listaMailAggiunte" class="flex-col">
                <div class="flex-row start-center">
                    <div class="flex-col flex70">
                        <span style="font-weight: 800;">{{aggiunto.Nome}}&nbsp;{{aggiunto.Cognome}}</span>
                        <span>{{aggiunto.Mail}}</span>
                    </div>
                    <div class="flex30">
                        <button  mat-icon-button (click)="rimuoviEmail(undefined , aggiunto.Mail)">
                            <mat-icon color="warn">delete</mat-icon>
                        </button>

                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
        <span style="margin-top: 24px;" class="miniTitle">Oppure aggiungi un nuovo indirizzo</span>
        <div class="flex-row start-start gap24" style="padding: 24px;">
            <div class="flex-col start-strech fxFlex gap12" >
                <div class="flex-row start-center gap12">
                    <mat-form-field class="flex50" appearance="outline" subscriptSizing="dynamic" #inputNewMail >
                        <input matInput placeholder="Email" [(ngModel)]="nuovoContatto.Mail">
                    </mat-form-field>
                    <mat-form-field class="flex50" appearance="outline" subscriptSizing="dynamic" #inputNewNome >
                        <input matInput placeholder="Nome" [(ngModel)]="nuovoContatto.Nome">
                    </mat-form-field>
                    <mat-form-field class="flex50" appearance="outline" subscriptSizing="dynamic" #inputNewCognome >
                        <input matInput placeholder="Cognome" [(ngModel)]="nuovoContatto.Cognome">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="isAlreadyIncluded(nuovoContatto.Mail)">Email già indicata</mat-error>
            </div>
            <button mat-raised-button color="accent" [disabled]="!validaMail(nuovoContatto.Mail)"
            class="saveBtn" (click)="aggiungiEmail()" style="margin-top: 8px;">Aggiungi</button>
        </div>
    </div>  

</div>