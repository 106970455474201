import { Component, Inject, OnInit } from '@angular/core';
import { LoginCandidate } from '../../model/login-candidate.model';
import { AuthenticationService } from '../../services/authentication.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { RecuperaPasswordComponent } from '../recupera-password/recupera-password.component';

import { LoginRichiestaAccessoCoordinatorService } from 'src/app/services/login-richiesta-accesso-coordinator.service';
import { load } from 'recaptcha-v3';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ApiUrl } from 'src/app/shared/api-url';
import { lastValueFrom, Observable } from 'rxjs';
import { LoadingDialogComponent } from 'src/app/shared/components/loading-dialog/loading-dialog.component';
import { ValutazioneCaptcha } from 'src/app/model/captcha-valutazione.model';
import { CaptchaRequest } from 'src/app/model/captcha_request.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginError?: any;

  constructor(
    private auth: AuthenticationService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RecuperaPasswordComponent>,
    private loginCoordinator: LoginRichiestaAccessoCoordinatorService,
    @Inject(MAT_DIALOG_DATA) private data: boolean,
    private http: HttpClient
  ) {}

  IT = 'it-IT';
  EN = 'en-US';

  get localizedAssets(): string {
    const href = window.location.href;
    if (href.includes(this.IT)) {
      return window.location.origin + '/' + this.IT + '/assets/loghi/logo.png';
    }
    if (href.includes(this.EN)) {
      return window.location.origin + '/' + this.EN + '/assets/loghi/logo.png';
    }
    return window.location.origin + '/assets/loghi/logo.png';
  }

  candidate: LoginCandidate = { RememberMe: false } as LoginCandidate;

  get isRichiestaAccesso(): boolean {
    return this.loginCoordinator.isRichiestaAccesso.value;
  }

  async richiediAccesso() {
    const dialogRef = this.dialog.open(LoadingDialogComponent, {});
    const obsSiteID = this.http.get<string>(ApiUrl.CAPTCHA_SITE_ID);
    const obsBaseUrl = this.http.get<string>(ApiUrl.CAPTCHA_BASE_URL);
    const obsApiKey = this.http.get<string>(ApiUrl.CAPTCHA_API_KEY);
    const siteID = await lastValueFrom(obsSiteID);
    const baseUrl = await lastValueFrom(obsBaseUrl);
    const apiKey = await lastValueFrom(obsApiKey);
    if (siteID == null || siteID == undefined || siteID == '') {
      return console.log('Errore nel caricamento del siteID');
    }
    if (baseUrl == null || baseUrl == undefined || baseUrl == '') {
      return console.log('Errore nel caricamento del baseUrl');
    }
    if (apiKey == null || apiKey == undefined || apiKey == '') {
      return console.log('Errore nel caricamento del apiKey');
    }
    const recaptcha = await load(siteID, {
      useEnterprise: true,
    });
    const token = await recaptcha.execute('signup');
    let punteggio: ValutazioneCaptcha;
    try {
      punteggio = await lastValueFrom(
        this.richiediPunteggioFromClient(token, baseUrl, siteID, apiKey)
      );
      dialogRef.close();
      if (punteggio.riskAnalysis.score < 0.3) {
        alert('Sembrerebbe che tu sia un robot, riprova');
      } else {
        this.loginCoordinator.setRichiestaAccesso(true);
      }
    } catch (err) {
      alert('Errore nella valutazione del captcha. Ricarica la pagina e riprova');
      console.log(err);
    }
  }

  richiediPunteggioFromClient(
    token: string,
    baseUrl: string,
    siteID: string,
    apiKey: string
  ): Observable<ValutazioneCaptcha> {
    let r: CaptchaRequest = {
      event: {
        token: token,
        expectedAction: 'signup',
        siteKey: siteID,
      },
    };
    const body = JSON.stringify(r);
    const url = baseUrl + apiKey;
    const obs = this.http.post<ValutazioneCaptcha>(url, body, {
      headers: { 'Content-Type': 'application/json' },
    });
    return obs;
  }

  rememberMeChanged(ev: boolean) {
    console.log(ev);
    let t = ev;
    this.candidate.RememberMe = t;
    console.log('rm' + this.candidate.RememberMe);
  }

  isDialog(): boolean {
    if (this.data == true) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    this.auth.errorInfo.subscribe((error) => {
      console.log('loginError', error);
      this.loginError = error;
    });
    this.auth.tryToAutoLogin().subscribe((res) => {
      console.log(res);
    });
  }

  authenticate(): void {
    this.auth.login(this.candidate).subscribe((res) => {
      if (this.isDialog()) {
        this.dialogRef.close(true);
      }
    });
  }

  recoverPassword(): void {
    const dialogRef = this.dialog.open(RecuperaPasswordComponent, {
      id: 'recoverpwd',
    });
    dialogRef.afterClosed().subscribe((email) => {
      if (email) {
        this.auth.recoverPassword(email).subscribe();
      }
    });
  }
}
