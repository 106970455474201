import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ControlliTeleconferenzaCoordinatorService } from 'src/app/services/ws/controlli-teleconferenza-coordinator.service';
import { RtcService } from 'src/app/services/ws/rtc.service';
import {
  Messaggio,
  SignalingService,
  TipoMessaggio,
} from 'src/app/services/ws/signaling.service';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss'],
})
export class VideoCallComponent implements OnInit {
  //@ViewChild('videoElement', { static: true }) videoElement: any;
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('myVideoElement') myVideoElement!: ElementRef<HTMLVideoElement>;

  utente: User | undefined;
  subs: Subscription = new Subscription();

  get video(): HTMLVideoElement {
    return this.videoElement.nativeElement;
  }

  myMediaStream: MediaStream | undefined;
  aspectRatioMyStream: number | undefined = undefined;
  aspectRatioOtherStream: number | undefined = undefined;

  constructor(
    private rtcService: RtcService,
    private currentUserService: CurrentUserService,
    private ctcs: ControlliTeleconferenzaCoordinatorService,
    private signalingService: SignalingService
  ) {
    this.utente = this.currentUserService.currentUser;

    this.subs.add(
      //Start Streaming
      this.ctcs.toggleStream.subscribe((stop) => {
        if (stop == undefined) {
          return;
        }
        if (stop) {
          this.rtcService.stopStreaming();
          this.aspectRatioMyStream = undefined;
        } else {
          this.rtcService.startStreaming();
          this.rtcService.streamAvailable.subscribe(
            (utente: User | undefined) => {
              if (utente == undefined) {
                return;
              }
              if (utente?.Email == this.utente?.Email) {
                if (this.myVideoElement.nativeElement) {
                  this.myVideoElement.nativeElement.srcObject =
                    utente!.MediaStream;
                  this.myVideoElement.nativeElement.muted = true;
                  const VT = utente?.MediaStream.getVideoTracks()[0];
                  const Settings = VT?.getSettings();
                  const width = Settings?.width;
                  const height = Settings?.height;
                  this.aspectRatioMyStream = width! / height!;
                }
              } else {
                if (this.videoElement.nativeElement) {
                  this.videoElement.nativeElement.srcObject =
                    utente!.MediaStream;
                  // const VT = utente?.MediaStream.getVideoTracks()[0];
                  // const Settings = VT?.getSettings();
                  // const width = Settings?.width;
                  // const height = Settings?.height;
                  this.aspectRatioOtherStream = 640 / 480;
                }
              }
            }
          );
          this.rtcService.streamUnavailable.subscribe(
            (utente: User | undefined) => {
              if (utente == undefined) {
                return;
              }
              if (utente?.Email == this.utente?.Email) {
                this.myVideoElement.nativeElement.srcObject = null;
                this.aspectRatioMyStream = undefined;
              } else {
                this.videoElement.nativeElement.srcObject = null;
                this.aspectRatioOtherStream = undefined;
              }
            }
          );
          setTimeout(() => {
            const m: Messaggio = {
              Utente: this.utente!,
              UtenteEmail: this.utente!.Email,
              Tipo: TipoMessaggio.Stato,
              Content: { Online: true, Streaming: true },
            };
            this.signalingService.access.next(m);
          }, 1000);
        }
      })
    );
    // Audio Toggle
    this.subs.add(
      this.subs.add(
        this.ctcs.toggleAudio.subscribe((off) => {
          if (off != undefined) {
            this.rtcService.mute = off;
          }
        })
      )
    );
    // video Toggle
    this.subs.add(
      this.ctcs.toggleCamera.subscribe((off) => {
        if (off != undefined) {
          this.rtcService.hidden = off;
        }
      })
    );
  }

  ngOnInit(): void {
    this.subs.add(
      this.rtcService.audioOutput.subscribe((device) => {
        if (
          device &&
          typeof (this.videoElement as any).sinkID !== 'undefined'
        ) {
          (this.video as any).setSinkId(device.deviceId);
        }
      })
    );
  }
}
