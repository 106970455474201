<div class="fxFlex flex-col">
    <mat-toolbar class="internal-toolbar">
        <div class="flex-row fxFlex space-between-center">
            <div>
                <span class="miniTitle"> {{titolo}}</span>
            </div>
            <div class="flex-row space-between-center gap12">
                <!-- // Controlli -->
                <!-- Attiva/ disattiva video -->
                <button mat-icon-button (click)="toggleCamera()" color="primary" [matTooltip]="'Disable/Enable Video Input'">
                    <mat-icon>{{cameraOn? 'videocam_off':'videocam'}}</mat-icon>
                </button>
                <!-- Attiva/Disattiva audio -->
                 <div class="audioToggle flex-row start-center">
                     <button  (click)="toggleAudio()" color="primary" class="toggle" [matTooltip]="'Disable/Enable Audio Input'">
                         <mat-icon>{{audioOn? 'mic_off':'mic'}}</mat-icon>
                     </button>
                     <div style="color: white;width: 1px;height: 100%;"></div>
                     <button [mat-menu-trigger-for]="devices" (click)="loadDevices()" class="moreAudio" [matTooltip]="'Change Device'">
                        <mat-icon>more_vert</mat-icon>
                     </button>

                 </div>
                <!-- <button mat-mini-fab (click)="toggleStream()" class="box-shadow" 
                    style="margin-left: 48px; color: white;" [ngStyle]="{'background-color': !this.ctcs.toggleStream.value ? 'var(--error)' : 'var(--primary)'}">
                    <mat-icon>{{!this.ctcs.toggleStream.value? 'phone_disabled':'call_start'}}</mat-icon>
                </button> -->
            </div>
        </div>
    </mat-toolbar>
    <div class="flex-row start-stretch" style="height: calc(100vh - 120px);" #parent [ngClass]="{'no-select': isDragging}">
        <div style="height: 100%; "[ngStyle]="{'width': largVideo + '%'}">
            <app-video-call></app-video-call>
        </div>
        <div style="height: 100%;background-color: var(--primary);width: 0px; margin-left: 6px;" *ngIf="visualizzaDettaglio">
            <div class="dragHandle box-shadow flex-col center-center gap12" (mousedown)="dragStart()" 
                 matTooltip="Clicca e trascina per ridimensionare la tabella e il dettaglio">
                <div style="height: 1px ;border-top: 1px solid white;width: 8px;" class="box-shadow"></div>
                <div style="height: 1px ;border-top: 1px solid white;width: 8px;"></div>
                <div style="height: 1px ;border-top: 1px solid white;width: 8px;"></div>
            </div>
        </div>
        <div style="height: calc(100vh - 124px); overflow: auto;" [ngStyle]="{'width': (100-largVideo) + '%'}">
            <app-info-teleconferenza [consulto]="consulto" *ngIf="consulto"></app-info-teleconferenza>
        </div>
    </div>
</div>

<mat-menu #devices>
    <div *ngFor="let device of listaDevices">
        <button mat-menu-item >{{device.label}}</button>
    </div>
</mat-menu>
