<div>
    <mat-toolbar class="internal-toolbar">
        <div class="flex-row space-between-center fxFlex">
            <span class="miniTitle" i18n>Pazienti</span>
            <button mat-raised-button color="primary" class="newBtn" [routerLink]="'new'" i18n>NUOVO</button>
        </div>
    </mat-toolbar>
    <mat-table mat-table [dataSource]="listaPazienti">
        <ng-container matColumnDef="utente">
            <mat-header-cell *matHeaderCellDef i18n> Aggiunto da:  </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.User.Nome + " " + paziente.User.Cognome}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nome">
            <mat-header-cell *matHeaderCellDef i18n> Nome </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.Nome}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="eta">
            <mat-header-cell *matHeaderCellDef i18n> Eta </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.Eta}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="specie">
            <mat-header-cell *matHeaderCellDef i18n> Specie </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.Specie}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="razza">
            <mat-header-cell *matHeaderCellDef i18n> Razza </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.Razza}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sesso">
            <mat-header-cell *matHeaderCellDef i18n> Sesso </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.Sesso | uppercase}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="peso">
            <mat-header-cell *matHeaderCellDef i18n> Peso </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.Peso}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="chip">
            <mat-header-cell *matHeaderCellDef i18n> Chip </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.NChip}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="propretario">
            <mat-header-cell *matHeaderCellDef i18n> Proprietario </mat-header-cell>
            <mat-cell *matCellDef="let paziente"> {{paziente.CognomeProprietario}} </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns" class="table-header" ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;" [routerLink]="row.Id" ></mat-row>

    </mat-table>
</div>



