import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { Slot } from 'src/app/model/slot.model';
import { ApiUrl } from 'src/app/shared/api-url';
import { map } from 'rxjs';
import { listaMesi } from 'src/app/shared/generic-constant';
import { serverDate } from 'src/locale/date';

const listaMesiPerIndex = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

@Component({
  selector: 'app-lista-slot',
  templateUrl: './lista-slot.component.html',
  styleUrls: ['./lista-slot.component.scss'],
})
export class ListaSlotComponent {
  constructor(private http: HttpClient) {}

  dataSelezionata: Date | undefined;
  mese: number = -1;
  listaSlot: Slot[] = [];
  @Output() slotTeleconferenza: EventEmitter<Slot> = new EventEmitter<Slot>();

  get mesi() {
    let currentMonth = new Date().getMonth();
    let currentYear = new Date().getFullYear();
    let months = [];

    for (let i = 0; i < 12; i++) {
      let monthIndex = (currentMonth + i) % 12;
      let year = currentYear + Math.floor((currentMonth + i) / 12);
      months.push(listaMesi[monthIndex] + ' ' + year);
    }

    return months;
  }
  get listaSlotFiltrata(): Slot[] {
    let today = new Date();
    if (!this.dataSelezionata) {
      return this.listaSlot.filter(
        (slot) => new Date(slot.DataInizio) >= today
      );
    }
    return this.listaSlot.filter((slot) => {
      return (
        new Date(slot.DataInizio) > today &&
        slot.DataInizio.getDate() === this.dataSelezionata?.getDate() &&
        slot.DataInizio.getMonth() === this.dataSelezionata?.getMonth() &&
        slot.DataInizio.getFullYear() === this.dataSelezionata?.getFullYear()
      );
    });
  }

  listaDate(): Date[] {
    // Map the listaSlot array to an array of dates
    let dates = this.listaSlot.map((slot) => slot.DataInizio);

    // Filter the dates array to only include distinct dates
    let distinctDates = dates.filter(
      (date, index, self) =>
        index ===
        self.findIndex(
          (d) =>
            d.getDate() === date.getDate() &&
            d.getMonth() === date.getMonth() &&
            d.getFullYear() === date.getFullYear()
        )
    );
    return distinctDates;
  }
  meseDescr(index: number) {
    return this.mesi[index];
  }
  dataScelta(data: Date) {
    this.dataSelezionata = data;
  }

  slotSceltoPerConferenza: Slot | undefined;
  slotScelto(slot: Slot) {
    this.slotSceltoPerConferenza = slot;
    this.slotTeleconferenza.emit(slot);
  }

  isSelected(slot: Slot): boolean {
    if (this.slotSceltoPerConferenza == undefined) {
      return false;
    }
    return this.isSameHour(slot, this.slotSceltoPerConferenza);
  }

  isSameHour(slot: Slot, slot2: Slot): boolean {
    return (
      slot.DataInizio.getHours() == slot2.DataInizio.getHours() &&
      slot.DataInizio.getMinutes() == slot2.DataInizio.getMinutes()
    );
  }

  meseChanged(indexMese: string) {
    let meseStr = indexMese.split(' ')[0];
    this.mese = listaMesiPerIndex.findIndex((mese) => mese == meseStr);
    setTimeout(() => {
      this.slotSub();
    }, 200);
  }

  private slotSub() {
    const url = ApiUrl.SLOT;
    let param = new HttpParams();
    let data = new Date();
    if (this.mese != -1) {
      data = this.controllaData(data);
    }
    param = param.set('data_da', serverDate(data));
    const obs = this.http.get<Slot[]>(url, { params: param }).pipe(
      map((data) => {
        return data.map((s) => {
          return {
            DataInizio: new Date(s.DataInizio),
          };
        });
      })
    );
    obs.subscribe((data) => {
      this.listaSlot = data;
    });
  }

  controllaData(dataOdierna: Date): Date {
    let dataRitorno = new Date(dataOdierna);
    let meseO = dataOdierna.getMonth();
    // Se ho scelto un mese precedente a quello attuale, devo considerare l'anno successivo
    if (this.mese < meseO) {
      dataOdierna.setMonth(this.mese);
      dataOdierna.setFullYear(dataOdierna.getFullYear() + 1);
      dataOdierna.setDate(1);
      dataRitorno = dataOdierna;
      return dataRitorno;
    }
    // Se ho scelto lo stesso mese della data attuale, ritorno la data odierna.
    if (this.mese == meseO) {
      return new Date(dataRitorno);
    }
    // Se il mese è successivo, setto la data al primo giorno del mese scelto.
    dataRitorno.setMonth(this.mese);
    dataRitorno.setDate(1);
    return dataRitorno;
  }
}
