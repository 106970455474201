import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DatasourceCalendarioService } from '../datasource-calendario.service';
import { Slot } from 'src/app/model/slot.model';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ruoloCliente } from 'src/app/model/user.model';
import {
  giorniSettimana,
  listaMesi,
  listaOrari,
} from 'src/app/shared/generic-constant';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

export const STATUS_DISPONIBILE = $localize`:@@statusDisponibile:DISPONIBILE`;
export const STATUS_PRENOTATO = $localize`:@@statusPrenotato:PRENOTATO`;
export const STATUS_CONFERMATO = $localize`:@@statusConfermato:CONFERMATO`;
export const STATUS_NASCOSTA = $localize`:@@statusNascosta:NASCOSTA`;

export const LOCALE = $localize`:@@locale:it-IT`;

export type indexesData = {
  indexGiorno: number;
  indexOra: number;
  isTranslated: number;
};

@Component({
  selector: 'app-calendario-main',
  templateUrl: './calendario-main.component.html',
  styleUrls: ['./calendario-main.component.scss'],
})
export class CalendarioMainComponent implements OnInit, AfterViewInit {
  cells: any[][] = [];
  modeD: boolean = false;
  datiCaricati: boolean = true;
  slotsGrid!: {
    status: string;
    orario: string;
    isTranslated?: number;
    prenotatoDa?: string;
    paziente?: string;
  }[][];

  @ViewChild('scroller') scroller: ElementRef<HTMLDivElement> | undefined;

  constructor(
    private dataSourceService: DatasourceCalendarioService,
    private currentUser: CurrentUserService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    if (history.state.dataInizioSettimana) {
      this.calcolaDataInizioSettimana(history.state.dataInizioSettimana);
    } else {
      this.calcolaDataInizioSettimana();
    }
    this.dataSourceService.listaSlot.subscribe((data) => {
      this.populateCells(data);
    });
  }

  isPresent(val: string | undefined){
    return val != undefined  && val != null && val.trim() != '';
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.goToDataOdierna();
  }

  orarioFine = (orario: string) => {
    if (orario == undefined) {
      return '';
    }
    let o = new Date(orario);
    o.setMinutes(o.getMinutes() + 30);
    return o;
  };

  goToDataOdierna() {
    this.calcolaDataInizioSettimana(new Date());
    //Scrolla all'ora corrente
    let minuti: string = '';
    let minutiInt = parseInt(this.minutiCorrente);
    if (minutiInt >= 0 && minutiInt <= 30) {
      minuti = '00';
    }
    if (minutiInt > 30 && minutiInt <= 60) {
      minuti = '30';
    }

    let index = this.orariGiorno.indexOf(this.oraCorrente + ':' + minuti);
    let scroll = index * 55;
    let elToScroll = this.scroller?.nativeElement;
    if (elToScroll == undefined) {
      return;
    }
    elToScroll.scrollTo({ top: scroll });
  }
  disponibilitaMode() {
    this.modeD = !this.modeD;
  }
  settimanaPrecedente() {
    const data = this.dataInizioSettimana.setDate(
      this.dataInizioSettimana.getDate() - 7
    );
    this.calcolaDataInizioSettimana(new Date(data));
  }
  settimanaSuccessiva() {
    const data = this.dataInizioSettimana.setDate(
      this.dataInizioSettimana.getDate() + 7
    );
    this.calcolaDataInizioSettimana(new Date(data));
  }
  mesePrecedente() {
    const data = new Date(this.dataInizioSettimana).setMonth(
      this.dataInizioSettimana.getMonth() - 1
    );
    this.calcolaDataInizioSettimana(new Date(data));
  }
  meseSuccessivo() {
    const data = new Date(
      this.dataInizioSettimana.setMonth(this.dataInizioSettimana.getMonth() + 1)
    );
    this.calcolaDataInizioSettimana(new Date(data));
  }
  annoPrecedente() {
    const data = new Date(this.dataInizioSettimana).setFullYear(
      this.dataInizioSettimana.getFullYear() - 1
    );
    this.calcolaDataInizioSettimana(new Date(data));
  }
  annoSuccessivo() {
    const data = new Date(
      this.dataInizioSettimana.setFullYear(
        this.dataInizioSettimana.getFullYear() + 1
      )
    );
    this.calcolaDataInizioSettimana(new Date(data));
  }
  isFestivo(giornoSettimana: string): boolean {
    return giornoSettimana == 'Domenica' || giornoSettimana == 'Sabato';
  }
  isDataOdierna(giornoSettimana: string): boolean {
    return (
      this.dataGiorno(giornoSettimana).getDate() == this.giornoCorrente &&
      this.dataGiorno(giornoSettimana).getMonth() == this.meseCorrente - 1 &&
      this.dataGiorno(giornoSettimana).getFullYear() == this.annoCorrente
    );
  }
  viewMese(i: number): boolean {
    let isMesePrecedente = i == this.meseSelezionato - 1;
    let isMeseSuccessivo = i == this.meseSelezionato + 1;
    let isMeseCorrente = i == this.meseSelezionato;
    return isMesePrecedente || isMeseSuccessivo || isMeseCorrente;
  }
  dataGiorno(giornoSettimana: string): Date {
    let i = giorniSettimana.indexOf(giornoSettimana);
    let data = new Date(
      this.dataInizioSettimana.getTime() + i * 24 * 60 * 60 * 1000
    );
    return data;
  }

  _dataInizioSettimana!: Date;
  get dataInizioSettimana(): Date {
    return this._dataInizioSettimana;
  }
  set dataInizioSettimana(value: Date) {
    this._dataInizioSettimana = value;
  }
  get dataFineSettimana(): Date {
    return new Date(
      this.dataInizioSettimana.getTime() + 6 * 24 * 60 * 60 * 1000
    );
  }

  get orariGiorno() {
    return listaOrari;
  }
  get meseCorrente(): number {
    return new Date().getMonth() + 1;
  }
  get annoCorrente(): number {
    return new Date().getFullYear();
  }
  get giornoCorrente(): number {
    return new Date().getDate();
  }
  get oraCorrente(): string {
    let ora = new Date().getHours();
    let oraStr = ora.toString();
    if (oraStr.length == 1) {
      oraStr = '0' + oraStr;
    }
    return oraStr;
  }
  get minutiCorrente(): string {
    let minuti = new Date().getMinutes();
    let minutiStr = minuti.toString();
    if (minutiStr.length == 1) {
      minutiStr = '0' + minutiStr;
    }
    return minutiStr;
  }

  get meseSelezionato(): number {
    return this.dataInizioSettimana.getMonth() + 1;
  }
  get annoSelezionato(): number {
    return this.dataInizioSettimana.getFullYear();
  }
  get giorniSettimanaGetter() {
    return giorniSettimana;
  }
  clickMese(i: number) {
    let d = new Date(this.dataInizioSettimana);
    d.setMonth(i);
    this.calcolaDataInizioSettimana(d);
  }
  get mesi() {
    return listaMesi;
  }

  calcolaDataInizioSettimana(data?: Date) {
    let dataAttuale!: Date;
    if (data) {
      dataAttuale = data;
    } else {
      dataAttuale = new Date();
    }
    const giornoSettimana: number = dataAttuale.getDay() - 1;
    // Dato il giorno della settimana, calcolo la data di inizio settimana (lunedì)
    // Sottraggo al giorno attuale il numero di giorni che mancano a lunedì
    this.dataInizioSettimana = new Date(
      dataAttuale.getTime() - giornoSettimana * 24 * 60 * 60 * 1000
    );
    this.dataSourceService.dataDa.next(this.dataInizioSettimana);
  }

  protected statusDisponibile = STATUS_DISPONIBILE;
  protected statusPrenotato = STATUS_PRENOTATO;
  protected statusNascosta = STATUS_NASCOSTA;

  isCliente(): boolean {
    if (this.currentUser.currentUser == null) {
      return true;
    }
    return this.currentUser.currentUser?.Ruolo == ruoloCliente;
  }

  getClassList(cell: any) {
    let baseClasses = ' flex-col center-center fxFlex';
    if (cell == undefined || cell.status == '' || cell.status == undefined) {
      return [baseClasses + ' slot '];
    }
    if (cell.isTranslated != 0) {
      baseClasses += ' halfSlot ';
    } else {
      baseClasses += ' slot ';
    }
    switch (cell.status) {
      case STATUS_DISPONIBILE:
        return [baseClasses + ' disponibile '];
      case STATUS_PRENOTATO:
        return [baseClasses + ' prenotato '];
      case STATUS_NASCOSTA:
        return [baseClasses + ' nascosta '];
      case STATUS_CONFERMATO:
        return [baseClasses + ' confermato '];
      default:
        return [];
    }
  }
  cellaClicked(event: MouseEvent, iO: number, iG: number, orario: string) {
    console.log(orario);
    let cl  = this.getClassList(this.slotsGrid[iG][iO]);
    let isTranslated = cl[0].includes('halfSlot');
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const isTopHalf = event.clientY - rect.top < rect.height / 2;
    if (this.isCliente()) {
      //CLIENTE
      if (this.slotsGrid[iG][iO].status == STATUS_PRENOTATO) {
        this.modificaRichiestaConsulto(iG, iO);
      } else {
        this.aggiungiRichiestaConsulto(iG, iO, orario);
      }
    } else {
      //ADMIN
      if (this.modeD) {
        if (this.slotsGrid[iG][iO].status == STATUS_DISPONIBILE) {
          this.rimuoviDisponibilita(iG, iO, orario);
        } else {
          this.aggiungiDisponibilita(isTopHalf, iG, iO);
        }
      } else {
        if (
          this.slotsGrid[iG][iO].status == STATUS_PRENOTATO ||
          this.slotsGrid[iG][iO].status == STATUS_CONFERMATO
        ) {
          this.modificaRichiestaConsulto(iG, iO, isTranslated);
        }
      }
    }
  }

  private modificaRichiestaConsulto(ig: number, io: number, isTranslated?: boolean) {
    let dataSlot: Date = this.dataSlotFromIndexes(ig, io);
    if(isTranslated){
      dataSlot.setMinutes(dataSlot.getMinutes() + 15);
    }
    dataSlot.setSeconds(0);
    dataSlot.setMilliseconds(0);
    let slot: Slot | undefined =
      this.dataSourceService.checkIfSlotIsAlreadyOccupied({
        DataInizio: dataSlot,
      });
    if (slot == undefined) {
      return;
    }
    const state = { navigateToTab: 3,  dataInizioSettimana: this.dataInizioSettimana};
    this.router.navigate(['../consulti', slot.ConsultoId], { state: state });
  }
  private aggiungiRichiestaConsulto(ig: number, io: number, orario: string) {
    let dataSlot = new Date(orario);
    let slot = this.dataSourceService.findSlot({ DataInizio: dataSlot });
    if (slot == undefined) {
      return;
    }
    // l'extras dataInizioSettimana serve per passare la data di inizio settinana al component del consulto.
    // ci serve per ritornare alla stessa pagina del calendario quando si clicca indietro
    this.router.navigate(['../consulti', 'new'], {
      state: { slot: slot, dataInizioSettimana: this.dataInizioSettimana },
    });
  }
  private rimuoviDisponibilita(ig: number, io: number, orario: string) {
    let dataSlot = new Date(orario);
    let slot: Slot = { DataInizio: dataSlot };
    this.dataSourceService.removeSlotDisponibilita(slot);
    this.dataSourceService.dataDa.next(this.dataInizioSettimana);
  }
  private aggiungiDisponibilita(isTopHalf: boolean, ig: number, io: number) {
    let dataSlot: Date = this.dataSlotFromIndexes(ig, io);
    if (!isTopHalf) {
      dataSlot.setMinutes(dataSlot.getMinutes() + 15);
    }
    let slot: Slot = { DataInizio: dataSlot };
    this.dataSourceService.addSlotDisponibilita(slot);
    this.dataSourceService.dataDa.next(this.dataInizioSettimana);
  }

  dataSlotFromIndexes(iG: number, iO: number) {
    let data = new Date(this.dataInizioSettimana);
    data.setDate(data.getDate() + iG);
    let ora = this.orariGiorno[iO];
    let oraSplit = ora.split(':');
    data.setHours(parseInt(oraSplit[0]));
    data.setMinutes(parseInt(oraSplit[1]));
    data.setSeconds(0);
    return data;
  }

  private populateCells(data: Slot[]) {
    this.loadingService.isLoading.next(true);
    this.slotsGrid = new Array(7)
      .fill(null)
      .map(() => new Array(this.orariGiorno.length).fill(0));
    if (!data || data.length == 0) {
      this.loadingService.isLoading.next(false);
      return;
    }
    // Per ogni slot, popolo la cella corrispondente
    data.forEach((slot, index) => {
      let dataInizio = new Date(slot.DataInizio);
      let { indexGiorno, indexOra, isTranslated } = this.indexesFromSlot(slot);
      this.slotsGrid[indexGiorno][indexOra] = {
        status: slot.ConsultoId != undefined && slot.ConsultoId != 0 ? slot.Consulto?.Slot?.Accettato ? STATUS_CONFERMATO : STATUS_PRENOTATO : STATUS_DISPONIBILE,
        orario: dataInizio.toString(),
        isTranslated: isTranslated,
        prenotatoDa: (slot.Consulto?.User?.Nome || '') +' ' +(slot.Consulto?.User?.Cognome || '') || '',
        paziente: slot.Consulto?.Paziente?.Nome || '',
      }


      if (index == data.length - 1) {
        this.loadingService.isLoading.next(false);
      }
    });
  }


  indexGiorno(data: Date) {
    let giorno = data.toLocaleDateString(LOCALE, { weekday: 'long' });
    return giorniSettimana.indexOf(giorno);
  }

  indexesFromSlot(slot: Slot): indexesData {
    let dataInizio = new Date(slot.DataInizio);
    let giorno = this.indexGiorno(dataInizio);
    let ora = dataInizio.getHours().toString();
    let minute: number = dataInizio.getMinutes();
    let isTranslated = 0;
    let minuteStr = '';

    if (minute > 0 && minute < 30) {
      isTranslated = minute;
      minuteStr = '00';
    } else if (minute > 30) {
      isTranslated = minute - 30;
      minuteStr = '30';
    } else {
      minuteStr = minute.toString();
    }
    if (ora.length == 1) {
      ora = '0' + ora;
    }
    if (minuteStr.length == 1) {
      minuteStr = '0' + minuteStr;
    }
    let orario = ora + ':' + minuteStr;
    let indexOra = this.orariGiorno.indexOf(orario);
    return {
      indexGiorno: giorno,
      indexOra: indexOra,
      isTranslated: isTranslated,
    };
  }
}
