export interface Provincia {
    name: string;
    code: string;
}

export const province: Provincia[] = [
    {name: "TORINO", code: "TO"},
    {name: "VERCELLI", code: "VC"},
    {name: "NOVARA", code: "NO"},
    {name: "CUNEO", code: "CN"},
    {name: "ASTI", code: "AT"},
    {name: "ALESSANDRIA", code: "AL"},
    {name: "AOSTA", code: "AO"},
    {name: "IMPERIA", code: "IM"},
    {name: "SAVONA", code: "SV"},
    {name: "GENOVA", code: "GE"},
    {name: "LA SPEZIA", code: "SP"},
    {name: "VARESE", code: "VA"},
    {name: "COMO", code: "CO"},
    {name: "SONDRIO", code: "SO"},
    {name: "MILANO", code: "MI"},
    {name: "BERGAMO", code: "BG"},
    {name: "BRESCIA", code: "BS"},
    {name: "PAVIA", code: "PV"},
    {name: "CREMONA", code: "CR"},
    {name: "MANTOVA", code: "MN"},
    {name: "BOLZANO", code: "BZ"},
    {name: "TRENTO", code: "TN"},
    {name: "VERONA", code: "VR"},
    {name: "VICENZA", code: "VI"},
    {name: "BELLUNO", code: "BL"},
    {name: "TREVISO", code: "TV"},
    {name: "VENEZIA", code: "VE"},
    {name: "PADOVA", code: "PD"},
    {name: "ROVIGO", code: "RO"},
    {name: "UDINE", code: "UD"},
    {name: "GORIZIA", code: "GO"},
    {name: "TRIESTE", code: "TS"},
    {name: "PIACENZA", code: "PC"},
    {name: "PARMA", code: "PR"},
    {name: "REGGIO NELL'EMILIA", code: "RE"},
    {name: "MODENA", code: "MO"},
    {name: "BOLOGNA", code: "BO"},
    {name: "FERRARA", code: "FE"},
    {name: "RAVENNA", code: "RA"},
    {name: "FORLI - CESENA", code: "FC"},
    {name: "PESARO E URBINO", code: "PU"},
    {name: "ANCONA", code: "AN"},
    {name: "MACERATA", code: "MC"},
    {name: "ASCOLI PICENO", code: "AP"},
    {name: "MASSA - CARRARA", code: "MS"},
    {name: "LUCCA", code: "LU"},
    {name: "PISTOIA", code: "PT"},
    {name: "FIRENZE", code: "FI"},
    {name: "LIVORNO", code: "LI"},
    {name: "PISA", code: "PI"},
    {name: "AREZZO", code: "AR"},
    {name: "SIENA", code: "SI"},
    {name: "GROSSETO", code: "GR"},
    {name: "PERUGIA", code: "PG"},
    {name: "TERNI", code: "TR"},
    {name: "VITERBO", code: "VT"},
    {name: "RIETI", code: "RI"},
    {name: "ROMA", code: "RM"},
    {name: "LATINA", code: "LT"},
    {name: "FROSINONE", code: "FR"},
    {name: "CASERTA", code: "CE"},
    {name: "BENEVENTO", code: "BN"},
    {name: "NAPOLI", code: "NA"},
    {name: "AVELLINO", code: "AV"},
    {name: "SALERNO", code: "SA"},
    {name: "L'AQUILA", code: "AQ"},
    {name: "TERAMO", code: "TE"},
    {name: "PESCARA", code: "PE"},
    {name: "CHIETI", code: "CH"},
    {name: "CAMPOBASSO", code: "CB"},
    {name: "FOGGIA", code: "FG"},
    {name: "BARI", code: "BA"},
    {name: "TARANTO", code: "TA"},
    {name: "BRINDISI", code: "BR"},
    {name: "LECCE", code: "LE"},
    {name: "POTENZA", code: "PZ"},
    {name: "MATERA", code: "MT"},
    {name: "COSENZA", code: "CS"},
    {name: "CATANZARO", code: "CZ"},
    {name: "REGGIO DI CALABRIA", code: "RC"},
    {name: "TRAPANI", code: "TP"},
    {name: "PALERMO", code: "PA"},
    {name: "MESSINA", code: "ME"},
    {name: "AGRIGENTO", code: "AG"},
    {name: "CALTANISSETTA", code: "CL"},
    {name: "ENNA", code: "EN"},
    {name: "CATANIA", code: "CT"},
    {name: "RAGUSA", code: "RG"},
    {name: "SIRACUSA", code: "SR"},
    {name: "SASSARI", code: "SS"},
    {name: "NUORO", code: "NU"},
    {name: "CAGLIARI", code: "CA"},
    {name: "PORDENONE", code: "PN"},
    {name: "ISERNIA", code: "IS"},
    {name: "ORISTANO", code: "OR"},
    {name: "BIELLA", code: "BI"},
    {name: "LECCO", code: "LC"},
    {name: "LODI", code: "LO"},
    {name: "RIMINI", code: "RN"},
    {name: "PRATO", code: "PO"},
    {name: "CROTONE", code: "KR"},
    {name: "VIBO VALENTIA", code: "VV"},
    {name: "VERBANO-CUSIO-OSSOLA", code: "VB"},
    {name: "OLBIA-TEMPIO", code: "OT"},
    {name: "OGLIASTRA", code: "OG"},
    {name: "MEDIO CAMPIDANO", code: "VS"},
    {name: "CARBONIA-IGLESIAS", code: "CI"},
    {name: "MONZA", code: "MB"},
    {name: "FERMO", code: "FM"},
    {name: "BARLETTA-ANDRIA-TRANI", code: "BT"},
]

