export type TipoConsulto = {
    ID : number;
    Descrizione : string;
    CostoTc: number;
    Costo: number;
}

export class TipiConsulti{
    static readonly Ecografia: number = 1;
    static readonly Radiografia: number = 2;
    static readonly Ecg: number = 3;
    static readonly Holter: number = 4;
    static readonly Ecocardiografia: number = 5;
    static readonly SmallPart: number = 6;
}

export const TipiConsultiArray: any[] = [
    {
        Id : 1,
        Descrizione : 'Ecografia',
    },
    {
        Id : 2,
        Descrizione : 'Radiografia',
    },
    {
        Id : 3,
        Descrizione : 'Ecg',
    },
    {
        Id : 4,
        Descrizione : 'Holter',
    },
    {
        Id : 5,
        Descrizione : 'Ecocardiografia',
    },
    {
        Id : 6,
        Descrizione : 'SmallPart',
    }
]