import { Component, Input } from '@angular/core';
import { Consulto } from 'src/app/model/consulto.model';
import { FATTURA_CON_ERRORI, FATTURA_INVIATA, FATTURA_RICONCILIATA } from 'src/app/shared/icons-constant';

@Component({
  selector: 'app-stato-fattura',
  templateUrl: './stato-fattura.component.html',
  styleUrls: ['./stato-fattura.component.scss']
})
export class StatoFatturaComponent {

  fatturaInviataICO = FATTURA_INVIATA;
  fatturaRiconciliataICO = FATTURA_RICONCILIATA;
  fatturaConErroriICO = FATTURA_CON_ERRORI  ;

  @Input('consulto') consulto!: Consulto;

    /**
   * 0 - Nessuna Fattura
   * 1 - Fattura inviata a unimatica
   * 2 - Fattura con ricevuta SDI
   * @param element
   * @returns
   */
  statoFattura(element: Consulto) {
    if (element.Pagamento == undefined || element.Pagamento.FatturaID == 0 || element.Pagamento.FatturaID == undefined) {
      return 0;
    }
    if (element.Pagamento.FatturaID != undefined && element.Pagamento.Fattura?.RicevutaSDI == undefined) {
      return 1;
    }
    return 2;
  }



  fatturaConErrori(element: Consulto) {
    if(element.Pagamento == undefined || element.Pagamento.FatturaID == 0 || element.Pagamento.FatturaID == undefined) {
      return false;
    }
    return element.Pagamento.Fattura?.ErroriUnimatica != undefined && element.Pagamento.Fattura?.ErroriUnimatica != '';
  }

}
