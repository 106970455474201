<ng-container *ngIf="fileType">

  <ng-container *ngIf="fileType.startsWith('image/') && imgURL!= ''">
    <img style="height: 10vw !important;" [src]="imgURL" alt="preview"
      [ngStyle]="{'aspect-ratio': allegato.Width + ' / ' + allegato.Height}">
  </ng-container>


  <ng-container *ngIf="fileType.startsWith('application/') && imgURL!= ''">
    <object style="height: 10vw !important;border: none;overflow: hidden;"
      [ngStyle]="{'aspect-ratio': allegato.Width + ' / ' + allegato.Height}" *ngIf="imgURL" [data]="imgURL"
      type="application/pdf">
      <p>Your browser does not support PDFs.</p>
    </object>

  </ng-container>
</ng-container>