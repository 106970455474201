<div class="flex-col start-stretch gap12" >
    <div class="flex-row space-between-center fakeToolbar" style="padding-left: 24px;padding-right: 24px;" *ngIf="!isRiepilogo">
        <div class="flex-col">
            <span class="miniTitle" i18n>ALLEGATI AL CONSULTO</span>
            <span style="font-size: small;" i18n>La dimensione massima consentita è di {{dimensioneMassimaFile}}MB per file.</span>
        </div>
        <div>
            <input type="file" (change)="onFileSelected($event)" #fileUpload style="display: none;">
            <div class="file-upload">
                <button class="caricaFileBtn box-shadow"
                    (click)="fileUpload.click();$event.stopPropagation();$event.preventDefault();">
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="flex-col" style="overflow: auto; max-height: calc(100vh - 420px);">

        <div class="flex-col gap12" *ngFor="let allegato of allegatiFiltered()">
            <div class="flex-col">

                <div class="flex-row space-between-center gap24">
                    <div class="flex-col gap12 fxFlex start-stretch" style="max-width: 50%!important;">
                        <div class="flex-row gap12 space-between-center">
                            <div class="flex-row gap24">
                                <span class="miniTitle"  i18n>Nome originale: </span>
                                <span >{{allegato.Nome}}</span>
                            </div>
                            <div class="flex-row gap12 start-center">
                                <button mat-icon-button color="warn" (click)="deleteAllegato(allegato)" matTooltip="Elimina allegato" i18n-matTooltip>
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button color="primary" matTooltip="Scarica allegato" *appIsAuth="true" (click)="downloadAllegato(allegato)" i18n-matTooltip>
                                    <mat-icon>download</mat-icon>    
                                </button>
                            </div>
                        </div>
                        <div class="fxFlex" *ngIf="!isRiepilogo">
                            <mat-form-field appearance="outline" class="fxFlex">
                                <mat-label i18n>Descrizione</mat-label>
                                <input matInput placeholder="Descrizione" name="descrizione" [(ngModel)]="allegato.Descrizione" [readonly]="allegato.ConsultoID != 0" 
                                [disabled]="disabilitaInputDescrizione(allegato)" i18n-matTooltip>
                            </mat-form-field>
                        </div>
                    </div>
                    <app-file-preview [file]="allegato"></app-file-preview>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
</div>