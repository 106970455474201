import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FatturaDB } from 'src/app/model/fattura.model';
import { ApiUrl } from 'src/app/shared/api-url';

@Component({
  selector: 'app-fatture-lista',
  templateUrl: './fatture-lista.component.html',
  styleUrls: ['./fatture-lista.component.scss'],
})
export class FattureListaComponent implements OnInit {
  listaFatture: FatturaDB[] = [];

  constructor(private http: HttpClient) {}

  displayedColumns: string[] = ['numero','anno', 'creata','cessionario','paziente', 'stato','download'];

  ngOnInit(): void {
    this.recuperaFatture();
  }


  recuperaFatture() {
    const url = ApiUrl.FATTURE;
    const obs = this.http.get<FatturaDB[]>(url);
    obs.subscribe((data) => {
      this.listaFatture = data;
    });
  }

  statoFattura(element: FatturaDB) {
    if (
      (element.ErroriUnimatica != undefined && element.ErroriUnimatica != '') ||
      (element.ErroriSDI != undefined && element.ErroriSDI != '')
    ) {
      return 1;
    }
    if(element.Riconciliata){
      return 2;
    }
    return 0;

  }


  scaricaFattura(fattura: FatturaDB){
    if(fattura == undefined){
      return console.log("Errore fattura non presente");
    }
    const url = ApiUrl.FATTURE + '/scarica';
    let params = new HttpParams().set('fattura_id', fattura.ID.toString());
    const obs = this.http.get(url, {params: params, responseType: 'blob'});
    obs.subscribe((response) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
