<fieldset 
    [disabled]="isCliente" 
    *ngIf="referto != undefined && (!isCliente || isCliente && referto.Disponibile)" 
    style="padding:0px!important; border: none!important;" #ecgWrapper>
    <div style="visibility: hidden;">{{hasForm}}</div>
    <form #f="ngForm" class="flex-col gap12 start-stretch" style="height: calc(100vh - 386px);overflow: auto;">
        <div class="flex-col gap12">
            <span class="miniTitle">Elettrocardiogramma</span>
            <div class="flex-col start-stretch">
                <mat-label i18n>Frequenza cardiaca media</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="FrequenzaCardiacaMedia" placeholder="Frequenza cardiaca media" i18n-placeholder
                        [(ngModel)]="referto.FrequenzaCardiacaMedia">
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Ritmo</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="Ritmo" placeholder="Ritmo" [(ngModel)]="referto.Ritmo" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="flex-col">
            <span class="miniTitle" i18n>ONDA P</span>
            <div class="flex-row space-between-center gap12">
                <div class="flex-col fxFlex">
                    <mat-label>Ms</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="OndaPMs" placeholder="Ms" [(ngModel)]="referto.OndaPMs">
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label>mV</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="OndaPMv" placeholder="mV" [(ngModel)]="referto.OndaPMv">
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label i18n>Tratto PQ</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="OndaPTrattoPq" placeholder="Tratto PQ" i18n-placeholder
                            [(ngModel)]="referto.OndaPTrattoPq">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <span class="miniTitle">QRS IN Dii</span>
            <div class="flex-row space-between-center gap12">
                <div class="flex-col fxFlex">
                    <mat-label i18n>Onda Q</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="QrsInDiiOndaQ" placeholder="Onda Q" [(ngModel)]="referto.QrsInDiiOndaQ" i18n-placeholder>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label i18n>Onda R</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="QrsInDiiOndaR" placeholder="Onda R" [(ngModel)]="referto.QrsInDiiOndaR" i18n-placeholder>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label i18n>Onda S</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="QrsInDiiOndaS" placeholder="Onda S" [(ngModel)]="referto.QrsInDiiOndaS" i18n-placeholder >
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label i18n>Ampiezza QRS in DII</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="QRSInDiiAmpiezzaQrsInDii" placeholder="Ampiezza QRS in DII"
                            [(ngModel)]="referto.QRSInDiiAmpiezzaQrsInDii" i18n-placeholder>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label i18n>Durata Qrs</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="QrsInDiiDurataQrs" placeholder="Durata Qrs"
                            [(ngModel)]="referto.QrsInDiiDurataQrs" i18n-placeholder>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <span class="miniTitle" i18n>Asse Medio QRS</span>
            <div class="flex-row space-between-center gap12">
                <div class="flex-col fxFlex">
                    <mat-label>Amp DII</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="AsseMedioQrsAmpDii" placeholder="Amp DII"
                            [(ngModel)]="referto.AsseMedioQrsAmpDii" i18n-placeholder>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label i18n>Amp aVF</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="AsseMedioQrsAmpAVf" placeholder="Amp aVF"
                            [(ngModel)]="referto.AsseMedioQrsAmpAVf" i18n-placeholder>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label i18n>Asse QRS</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="AsseMedioQrsAsseQrs" placeholder="Asse QRS"
                            [(ngModel)]="referto.AsseMedioQrsAsseQrs" i18n-placeholder>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex-col fxFlex">
            <mat-label i18n>Segmento ST</mat-label>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <input matInput name="SegmentoST" placeholder="Segmento ST" [(ngModel)]="referto.SegmentoST" i18n-placeholder>
            </mat-form-field>
        </div>
        <div class="flex-col">
            <span class="miniTitle" i18n>ONDA T</span>
            <div class="flex-row space-between-center gap12">
                <div class="flex-col fxFlex">
                    <mat-label>Onda T</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="OndaTOndaT" placeholder="Onda T" [(ngModel)]="referto.OndaTOndaT" i18n-placeholder>
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label>Ms</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="OndaTMs" placeholder="Ms" [(ngModel)]="referto.OndaTMs">
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label>mV</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="OndaTMv" placeholder="mV" [(ngModel)]="referto.OndaTMv">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <span class="miniTitle" i18n>Intervallo QT</span>
            <div class="flex-row space-between-center gap12">
                <div class="flex-col fxFlex">
                    <mat-label>Ms</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="IntervalloQtMs" placeholder="Ms" [(ngModel)]="referto.IntervalloQtMs">
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label>RR</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="IntervalloQtRr" placeholder="RR" [(ngModel)]="referto.IntervalloQtRr">
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label>QTc</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="IntervalloQtQTc" placeholder="QTc" [(ngModel)]="referto.IntervalloQtQTc">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex-col">
            <span class="miniTitle" i18n>Asse Medio Onda P</span>
            <div class="flex-row space-between-center gap12">
                <div class="flex-col fxFlex">
                    <mat-label>Amp DII</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="AsseMedioOndaPAmpDii" placeholder="Amp DII"
                            [(ngModel)]="referto.AsseMedioOndaPAmpDii">
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label>Amp aVF</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="AsseMedioOndaPAmpAVF" placeholder="Amp aVF"
                            [(ngModel)]="referto.AsseMedioOndaPAmpAVF">
                    </mat-form-field>
                </div>
                <div class="flex-col fxFlex">
                    <mat-label i18n>Asse onda P</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                        <input matInput name="AsseMedioOndaPAsseOndaP" placeholder="Asse onda P" i18n-placeholder
                            [(ngModel)]="referto.AsseMedioOndaPAsseOndaP">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex-col gap12">
            <span class="miniTitle" i18n>Interpretazione</span>
            <div class="flex-col start-stretch">
                <mat-label i18n>Frequenza cardiaca</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="InterpretazioneFrequenzaCardiaca" placeholder="Frequenza cardiaca" i18n-placeholder
                        [(ngModel)]="referto.InterpretazioneFrequenzaCardiaca">
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Intervalli R-R</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="InterpretazioneIntervalliRR" placeholder="Intervalli R-R"
                        [(ngModel)]="referto.InterpretazioneIntervalliRR" i18n-placeholder >
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Onda P</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="InterpretazioneOndaP" placeholder="Onda P"
                        [(ngModel)]="referto.InterpretazioneOndaP" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Conduzione AV</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="InterpretazioneConduzioneAV" placeholder="Conduzione AV"
                        [(ngModel)]="referto.InterpretazioneConduzioneAV" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Complesso QRS</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="InterpretazioneComplessoQrs" placeholder="Complesso QRS"
                        [(ngModel)]="referto.InterpretazioneComplessoQrs" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Conduzione VA</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="InterpretazioneConduzioneVA" placeholder="Conduzione VA"
                        [(ngModel)]="referto.InterpretazioneConduzioneVA" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Battiti bloccati</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="InterpretazioneBattitiBloccati" placeholder="Battiti bloccati"
                        [(ngModel)]="referto.InterpretazioneBattitiBloccati" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col start-stretch">
                <mat-label i18n>Modalità d'inizio e cessazione</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                    <input matInput name="InterpretazioneModalitaInizioCessazione"
                        placeholder="Modalità d'inizio e cessazione"
                        [(ngModel)]="referto.InterpretazioneModalitaInizioCessazione" i18n-placeholder>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="flex-col start-stretch">
            <mat-label i18n>Conclusioni</mat-label>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                <textarea matInput name="Conclusioni" placeholder="Conclusioni"
                    [(ngModel)]="referto.Conclusioni" i18n-placeholder></textarea>
            </mat-form-field>
        </div>
        <div class="flex-col start-stretch">
            <mat-label i18n>Referto</mat-label>
            <app-valori-default  [valoreDefault]="defReferto" class="fxFlex"></app-valori-default>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                <textarea matInput name="Referto" placeholder="Referto" rows="10"
                    [(ngModel)]="referto.Referto"></textarea>
            </mat-form-field>
        </div>
    </form>

</fieldset>