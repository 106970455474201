import { Component, Input, OnInit } from '@angular/core';
import { Consulto } from 'src/app/model/consulto.model';
import { FatturaDB, progressivo } from 'src/app/model/fattura.model';
import { Pagamento, pagamentoEffettuato, pagamentoSospeso } from 'src/app/model/pagamento.model';
import { Paziente } from 'src/app/model/paziente.model';
import { TipiConsulti, TipiConsultiArray } from 'src/app/model/tipo-consulto.model';
import { User } from 'src/app/model/user.model';
import { ICONA_ALLEGATI, ICONA_CONSULTO, ICONA_FATTURA, ICONA_PAZIENTE, ICONA_UTENTE } from 'src/app/shared/icons-constant';

@Component({
  selector: 'app-info-teleconferenza',
  templateUrl: './info-teleconferenza.component.html',
  styleUrls: ['./info-teleconferenza.component.scss']
})
export class InfoTeleconferenzaComponent implements OnInit  {

  iconaPaziente = ICONA_PAZIENTE;
  iconaCliente = ICONA_UTENTE;
  iconaConsulto = ICONA_CONSULTO;
  iconaFattura = ICONA_FATTURA;
  iconaAllegati = ICONA_ALLEGATI;

  pagamentoEffettuato = pagamentoEffettuato;
  pagamentoSospeso = pagamentoSospeso;

  fatturaNonCreata = $localize`:@@fatturaNonCreata:Fattura non creata`;

  statoFattura(element: Consulto) {
    if (element.Pagamento == undefined || element.Pagamento.FatturaID == 0 || element.Pagamento.FatturaID == undefined) {
      return 0;
    }
    if (element.Pagamento.FatturaID != undefined && element.Pagamento.Fattura?.RicevutaSDI == undefined) {
      return 1;
    }
    return 2;
  }
  fatturaConErrori(element: Consulto) {
    if(element.Pagamento == undefined || element.Pagamento.FatturaID == 0 || element.Pagamento.FatturaID == undefined) {
      return false;
    }
    return element.Pagamento.Fattura?.ErroriUnimatica != undefined && element.Pagamento.Fattura?.ErroriUnimatica != '';
  }

  tipoConsulto(id: number): string {
    let a =  TipiConsultiArray.find((el) => el.Id== id);
    return a.Descrizione;
  }


  @Input() consulto: Consulto | undefined;

  datiUtente: User | undefined;
  datiPagamento: Pagamento | undefined;
  datiPaziente: Paziente | undefined;
  datiFattura: FatturaDB | undefined;

  progressivoExported(){
    return progressivo(this.datiFattura!);
  }

  ngOnInit(): void {
    this.datiUtente = this.consulto!.User;
    this.datiPagamento = this.consulto!.Pagamento;
    this.datiPaziente = this.consulto!.Paziente;
    this.datiFattura = this.consulto!.Pagamento.Fattura;
    if(this.consulto == undefined){
      return
    }

  }

}
