<div style="height: 100vh;" class="flex-col start-stretch">

  <mat-toolbar class="logo-container">
    <img [src]="localizedAssets" alt="logo" height="60px" i18n-alt/>
  </mat-toolbar>

  <ng-container [ngTemplateOutlet]="login" *ngIf="!isRichiestaAccesso"></ng-container>
  <ng-container [ngTemplateOutlet]="richiestaAccesso" *ngIf="isRichiestaAccesso"></ng-container>
  
</div>


<ng-template #login>
  <div class="flex-col center-center gap24">
    <div class="flex-row center-center gap24" style="margin-top: 80px;">
      <span class="miniTitle" i18n>Effettua il login oppure</span>
      <button mat-raised-button 
      style="background-color: var(--accent);color:white" 
      matTooltip="Richiedi Accesso" i18n-matTooltip
      (click)="richiediAccesso()">
        <span i18n>
          Richiedi accesso
        </span>
      </button>
    </div>
    <form #form="ngForm" class="loginForm gap12">
      <div class="mat-label-autofill-container">
        <mat-label class="mat-label-autofill">Email*</mat-label>
        <mat-form-field class="flexFormField" appearance="outline">
          <input [(ngModel)]="candidate.Email" name="email" matInput placeholder="Email" />
        </mat-form-field>
      </div>
      <div class="mat-label-autofill-container">
        <mat-label class="mat-label-autofill">Password</mat-label>
        <mat-form-field appearance="outline" class="flexFormField">
          <input [(ngModel)]="candidate.Password" name="password" matInput type="password" placeholder="Password" />
        </mat-form-field>
      </div>
      <mat-checkbox [ngModel]="candidate.RememberMe" (ngModelChange)="rememberMeChanged($event)" name="rememberMe">
        <span i18n>
          Ricordami
        </span>
      </mat-checkbox>
      <div>{{ loginError }}</div>
      <button mat-raised-button style="background-color: var(--primary);color: white;" (click)="authenticate()">
        Login
      </button>
      <button mat-button (click)="recoverPassword()" style="margin-top: 24px;color: var(--accent);">
        <span i18n>
          Password dimenticata?
        </span>
      </button>
    </form>
  </div>
</ng-template>


<ng-template #richiestaAccesso>
  <app-user-detail></app-user-detail>
</ng-template>