import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';
import { Consulto } from 'src/app/model/consulto.model';
import { ChiudiMenuService } from 'src/app/services/chiudi-menu.service';
import { ControlliTeleconferenzaCoordinatorService } from 'src/app/services/ws/controlli-teleconferenza-coordinator.service';
import { SignalingService } from 'src/app/services/ws/signaling.service';
import { ApiUrl } from 'src/app/shared/api-url';

@Component({
  selector: 'app-teleconferenza',
  templateUrl: './teleconferenza.component.html',
  styleUrls: ['./teleconferenza.component.scss'],
})
export class TeleconferenzaComponent implements OnInit, OnDestroy {
  titolo = 'Teleconferenza';

  cameraOn: boolean = false;
  audioOn: boolean = false;

  subs: Subscription = new Subscription();

  

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private chiudiMenuService: ChiudiMenuService,
    protected ctcs: ControlliTeleconferenzaCoordinatorService,
    private signalingService: SignalingService,
  ) {
    this.checkRoute();
  }

  listaDevices: MediaDeviceInfo[] = [];
  loadDevices() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.listaDevices = devices.filter((dev) => dev.kind == 'audioinput' && !dev.label.includes('Virtual'));
    });
  }

  

  ngOnInit(): void {
    // Imposto il menu come aperto al passaggio del mouse
    this.chiudiMenuService.setMenuAsOpenOnMouseEnter.next(true);
  }

  consultoID: number | undefined;
  consulto: Consulto | undefined;

  private checkRoute() {
    this.subs.add(
      this.route.params.subscribe(async (params) => {
        if (params['id']) {
          // Recupero l'ID del consulto dalla route
          const id = params['id'];
          // recupero il consulto
          this.consulto = await lastValueFrom(this.loadConsulto(id));
          // Se il consulto esiste, inizializzo il signaling
          if(this.consulto != undefined){
            this.signalingService.initSignaling(this.consulto.ID.toString());
          }
        } else {
          console.log('Error: No conference id provided.');
        }
      })
    );
  }



  protected toggleStream() {
    this.ctcs.toggleStreamAction();
  }
  protected toggleCamera() {
    this.cameraOn = !this.cameraOn;
    this.ctcs.toggleCameraAction();
  }

  protected toggleAudio() {
    this.audioOn = !this.audioOn;
    this.ctcs.toggleAudioAction();
  }

  largVideo: number = 70;
  visualizzaDettaglio = true;
  @ViewChild('parent') parent!: ElementRef;
  isDragging = false;
  protected dragStart() {
    this.isDragging = true;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(ev: MouseEvent) {
    if (!this.isDragging) {
      return;
    }
    const parentWidth = this.parent.nativeElement.clientWidth;
    let x = Number(ev.clientX.toFixed(0));
    let perc = (x * 100) / parentWidth;
    this.largVideo = perc;
  }
  @HostListener('document:mouseup', ['$event'])
  onMouseUp() {
    if (this.isDragging) {
      this.isDragging = false;
    }
  }

  private loadConsulto(id: number) {
    const url = ApiUrl.CONSULTI + '/' + id;
    const obs = this.http.get<Consulto>(url);
    return obs;
  }

  ngOnDestroy(): void {
    this.chiudiMenuService.setMenuAsOpenOnMouseEnter.next(false);
    this.subs.unsubscribe();
  }
}
