<div class="fxFlex flex-col">
    <mat-toolbar class="internal-toolbar">
        <div class="flex-row space-between-center fxFlex">
            <div class="flex-row start-center">
                <button mat-icon-button [routerLink]="'../'" color="accent" *ngIf="!isRichiestaAccesso && !modeInput">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <button mat-icon-button *ngIf="isRichiestaAccesso" matTooltip="Torna al login" color="primary"
                    (click)="backToLogin()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <span class="miniTitle">{{isRichiestaAccesso? richiediAccessoLabel: dettaglioUtenteLabel}}</span>
                <button mat-icon-button (click)="deleteUtente()" color="warn" matTooltip="EliminaUtente" i18n-matTooltip
                    *ngIf="!isRichiestaAccesso && !modeInput">
                    <mat-icon>delete</mat-icon>
                </button>

            </div>
            <div>
                <ng-container *appIsAuth="true">
                    <div class="flex-row end-end" style="margin-top: 48px;"
                        *ngIf="utente.DataAbilitazione == undefined && !isNew">
                        <button mat-raised-button color="primary" (click)="approvaUtente()" i18n>Abilita Utente
                        </button>
                    </div>
                    <div class="flex-row start-center gap12" *ngIf="utente.DataAbilitazione != undefined">
                        <span style="font-size: small;" i18n>Approvato in data: </span>
                        <span class="miniTitle">{{utente.DataAbilitazione | date: "shortDate"}}</span>
                        <button mat-raised-button color="primary" (click)="disabilitaUtente()" i18n>Disabilita
                            Utente</button>
                    </div>
                </ng-container>
            </div>
            <button mat-raised-button color="accent" class="saveBtn" [disabled]="!form.valid"
                (click)="eseguiRichiesta()">{{isRichiestaAccesso? richiediLabel : salvaLabel}}</button>
        </div>
    </mat-toolbar>

    <form #form="ngForm" class="fxFlex flex-col" >
        <ng-container *ngIf="utente">

            <div class="flex-row center-center" style="margin-top: 12px;margin-bottom: 12px;">
                <span *ngIf="isRichiestaAccesso" i18n>La richiesta verrà processata dall'ammistratore di sistema. Se verrà
                    accettata riceverai una mail di
                    conferma con le credenziali di accesso temporanee</span>
            </div>
            <div class="flex-row start-center fxFlex gap24" style="padding-left: 24px; padding-right: 24px;">
                <mat-label>Email</mat-label>
                <div class="flex-col fxFlex">
                    <mat-form-field appearance="outline" class="fxFlex">
                        <input matInput name="email" [(ngModel)]="utente.Email" required placeholder="Email">
                    </mat-form-field>
                    <mat-error *ngIf="!isMailValid() && !(utente.Email == '' || utente.Email == undefined)" i18n>Deve
                        essere una mail valida</mat-error>
                </div>
                <ng-container *appIsAuth="true">
                    <mat-label i18n>Ruolo</mat-label>
                    <mat-form-field appearance="outline" class="fxFlex">
                        <mat-select [(ngModel)]="utente.Ruolo" placeholder="Ruolo" name="ruolo" required>
                            <mat-option value="admin">Admin</mat-option>
                            <mat-option value="cliente" i18n>Cliente</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>

            <mat-tab-group color="primary" mat-stretch-tabs="false" >
                <mat-tab > 
                    <ng-template mat-tab-label>
                        <div class="flex-row gap24 start-center">
                            <mat-icon color="primary">person</mat-icon>
                            <span i18n>Dati Utente</span>
                        </div>
                    </ng-template>
                    <ng-container [ngTemplateOutlet]="datiUtente"></ng-container>
                </mat-tab>
                <mat-tab >
                    <ng-template mat-tab-label>
                        <div class="flex-row gap24 start-center">
                            <mat-icon color="primary">local_hospital</mat-icon>
                            <span i18n>Dati Clinica</span>
                        </div>
                    </ng-template>
                    <ng-container [ngTemplateOutlet]="datiClinica"></ng-container>
                </mat-tab>
            </mat-tab-group>



            <ng-template #datiUtente>
                <div class="flex-col start-stretch gap12 " style="padding: 24px;">
                    <div class="flex-row start-center fxFlex gap24">
                        <mat-label i18n>Titolo</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput name="titolo" [(ngModel)]="utente.Titolo" placeholder="Titolo" i18n-placeholder>
                        </mat-form-field>
                        <mat-label i18n>Cognome</mat-label>
                        <mat-form-field appearance="outline" class="fxFlex">
                            <input matInput name="cognome" [(ngModel)]="utente.Cognome" [required]="isRichiestaAccesso"
                                placeholder="Cognome" i18n-placeholder>
                        </mat-form-field>
                        <mat-label i18n>Nome</mat-label>
                        <mat-form-field appearance="outline" class="fxFlex">
                            <input matInput name="nome" [(ngModel)]="utente.Nome" placeholder="Nome" i18n-placeholder>
                        </mat-form-field>
                    </div>
                    <div class="flex-row start-start fxFlex gap24" style="margin-top: 24px;">
                        <div class="flex-col start-stretch fxFlex" style="width: 45%;">
                            <div class="flex-row start-center fxFlex gap24">
                                <mat-label style="width: 80px;" i18n>Codice Fiscale</mat-label>
                                <mat-form-field appearance="outline" class="fxFlex">
                                    <input matInput name="cf" [(ngModel)]="utente.CodiceFiscale" required
                                        placeholder="Codice Fiscale" i18n-placeholder>
                                </mat-form-field>
                            </div>
                            <div class="flex-row start-center fxFlex gap24">
                                <mat-label style="width: 80px;" i18n>Telefono</mat-label>
                                <mat-form-field appearance="outline" class="fxFlex">
                                    <input matInput name="Telefono" [(ngModel)]="utente.Telefono" placeholder="Telefono" i18n-placeholder
                                        [required]="isRichiestaAccesso">
                                </mat-form-field>
                            </div>
                            <div class="flex-row start-center fxFlex gap24">
                                <mat-label style="width: 80px;" i18n>Indirizzo</mat-label>
                                <mat-form-field appearance="outline" class="fxFlex">
                                    <input matInput name="indirizzo" 
                                    [(ngModel)]="utente.Indirizzo" 
                                    placeholder="Indirizzo"
                                    i18n-placeholder>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex-col start-stretch fxFlex " style="width: 45%;">

                            <div class="flex-row start-center fxFlex gap12">
                                <div class="fxFlex fx-row start-center">
                                    <mat-label style="width: 80px;" i18n>Numero Civico</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput name="ncivico" [(ngModel)]="utente.NumeroCivico"
                                            placeholder="Numero Civico" i18n-placeholder  >
                                    </mat-form-field>

                                </div>
                                <div class="fxFlex fx-row start-center">
                                    <mat-label style="width: 60px;" i18n>Cap</mat-label>
                                    <mat-form-field appearance="outline" class="fxFlex">
                                        <input matInput name="cap" [(ngModel)]="utente.Cap" placeholder="Cap" i18n-placeholder>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row start-center fxFlex gap12">
                                <div class="fxFlex fx-row start-center">
                                    <mat-label style="width: 80px;" i18n>Comune</mat-label>
                                    <mat-form-field appearance="outline">
                                        <input matInput name="com" [(ngModel)]="utente.Comune" placeholder="Comune" i18n-placeholder>
                                    </mat-form-field>
                                </div>
                                <div class="fxFlex fx-row start-center">

                                    <mat-label style="width: 80px;" i18n>Provincia</mat-label>
                                    <mat-form-field appearance="outline">
                                        <mat-select [(ngModel)]="utente.Provincia" name="provinciaUtente">
                                            <mat-option *ngFor="let provincia of listaProvince" [value]="provincia.code"
                                            >{{provincia.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                            </div>
                            <div class="flex-row start-center fxFlex gap12">
                                <mat-label style="width: 80px;" i18n>Citta</mat-label>
                                <mat-form-field appearance="outline" class="fxFlex">
                                    <input matInput name="citta" [(ngModel)]="utente.Citta" placeholder="Citta" i18n-placeholder>
                                </mat-form-field>
                            </div>
                            <div class="flex-row start-center fxFlex gap12">
                                <mat-label style="width: 80px;" i18n>Nazione</mat-label>
                                <mat-form-field appearance="outline" class="fxFlex">
                                    <mat-select [(ngModel)]="utente.Nazione" name="nazioneUtente">
                                        <mat-option [value]="'IT'">{{'ITALIA'}}</mat-option>
                                        <mat-option *ngFor="let nazione of listaNazioni"  [value]="nazione.code">{{nazione.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>

                </div>
            </ng-template>

            <ng-template #datiClinica>
                <div class="flex-row start-start gap24 fxFlex" style="margin-top: 24px; padding: 24px;">
                    <div class="flex-col" style="width: 50%;">
                        <!-- <mat-checkbox> Usa i dati della clinica per la fatturazione</mat-checkbox> -->
                        <div class="flex-row start-center fxFlex ">
                            <mat-label style="width: 80px" i18n>Nome </mat-label>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <input matInput name="nomeC" [(ngModel)]="utente.NomeClinica" placeholder="Nome" i18n-placeholder>
                            </mat-form-field>
                        </div>
                        <div class="flex-row start-center fxFlex ">
                            <mat-label style="width: 80px" i18n>Indirizzo</mat-label>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <input matInput name="indC" [(ngModel)]="utente.IndirizzoClinica" i18n-placeholder 
                                    placeholder="Indirizzo">
                            </mat-form-field>
                        </div>

                        <div class="flex-row start-center fxFlex gap12">
                            <div class="flex-row start-center fxFlex ">
                                <mat-label style="width: 90px" i18n>Cap</mat-label>
                                <mat-form-field appearance="outline" class="fxFlex">
                                    <input matInput name="capClinica" [(ngModel)]="utente.CapClinica" placeholder="Cap" i18n-placeholder>
                                </mat-form-field>
                            </div>
                            <div class="flex-row start-center fxFlex ">
                                <mat-label style="width: 80px" i18n>Numero Civico</mat-label>
                                <mat-form-field appearance="outline" class="fxFlex">
                                    <input matInput name="ncivicoclinica" [(ngModel)]="utente.NumeroCivicoClinica"
                                        placeholder="Numero Civico" i18n-placeholder>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex-row start-center fxFlex ">
                            <mat-label style="width: 80px" i18n>Città</mat-label>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <input matInput name="cittaClinica" [(ngModel)]="utente.CittaClinica"
                                    placeholder="Città" i18n-placeholder>
                            </mat-form-field>
                        </div>
                        <div class="flex-row start-center fxFlex ">
                            <mat-label style="width: 80px" i18n>Telefono</mat-label>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <input matInput name="TelefonoClinica" [(ngModel)]="utente.TelefonoClinica"
                                    placeholder="Telefono" i18n-placeholder>
                            </mat-form-field>
                        </div>
                

                    </div>
                    <div class="flex-col" style="width: 50%;">
                        <div class="flex-row start-center fxFlex gap12">
                            <div class="flex-row start-center fxFlex ">
                                <mat-label style="width: 70px" i18n>Comune</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput name="comClinica" [(ngModel)]="utente.ComuneClinica"
                                        placeholder="Comune" i18n-placeholder>
                                </mat-form-field>
                            </div>
                            <div class="flex-row start-center fxFlex ">
                                <mat-label style="width: 80px" i18n>Provincia</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="provClinica" [(ngModel)]="utente.ProvinciaClinica" placeholder="Provincia" i18n-placeholder>
                                        <mat-option *ngFor="let provincia of listaProvince" [value]="provincia.code">{{provincia.name}}</mat-option>
                                    </mat-select> 
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="flex-row start-center fxFlex ">
                            <mat-label style="width: 80px" i18n>Nazione</mat-label>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <mat-select matInput name="nazioneClinica" [(ngModel)]="utente.NazioneClinica" placeholder="Nazione" i18n-placeholder>
                                    <mat-option [value]="'IT'">{{'ITALIA'}}</mat-option>
                                    <mat-option *ngFor="let nazione of listaNazioni" [value]="nazione.code">{{nazione.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="flex-row start-center fxFlex ">
                            <mat-label style="width: 80px" i18n>Partita Iva Clinica</mat-label>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <input matInput name="pivaC" [(ngModel)]="utente.PartitaIvaClinica" placeholder="Partita Iva"
                                i18n-placeholder>
                            </mat-form-field>
                        </div>
                        <div class="flex-row start-center fxFlex ">
                            <mat-label style="width: 80px" i18n>Email</mat-label>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <input matInput name="EmailClinica" [(ngModel)]="utente.EmailClinica"
                                    placeholder="Email Clinica" i18n-placeholder>
                            </mat-form-field>
                        </div>
                        <div class="flex-row start-center fxFlex ">
                            <mat-label style="width: 80px" i18n>Note</mat-label>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <textarea matInput name="note" [(ngModel)]="utente.Note" i18n-placeholder placeholder="Note"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

            </ng-template>
        </ng-container>


    </form>
</div>