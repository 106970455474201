<div style="overflow: hidden;height: 100%;" class="flex-col fxFlex start-stretch">
    <mat-toolbar class="internal-toolbar" style="position: sticky;">
        <div class="flex-row space-between-center fxFlex">
            <div class="flex-row start-center ">
                <button mat-icon-button (click)="close()" color="accent"><mat-icon>close</mat-icon></button>
                <span class="miniTitle" i18n>Dettaglio Consulto</span>
                <ng-container *ngIf="!isNew">
                    <button mat-icon-button color="warn" (click)="deleteConsulto()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-container>

            </div>
            <div *ngIf="unsavedChanges">
                <span style="font-size: small; text-transform: uppercase; color: var(--error);" i18n>modifiche non
                    salvate</span>
            </div>
        </div>
    </mat-toolbar>
    <div style="overflow: auto; height: calc(100vh - 128px); background-color: white;" >
        <mat-stepper [linear]="linearStepper" [disableRipple]="true" color="primary" #stepper>
            <!-- FIRST STEP: Scelta Paziente, Scelta Cliente, TIPO CONSULTO-->
            <mat-step [color]="firstStepCompleted? 'primary':'warn'" label="Dati principali" i18n-label
                [completed]="firstStepCompleted">
                <div class="flex-col start-stretch" style="overflow: hidden;">
                    <div class="flex-col start-stretch  gap24 stepWrapper" *ngIf="consulto">
                        <form #form="ngForm" class="flex-col start-stretch gap24" style="margin-top: 8px;">
                            <div class="flex-row space-between-center gap24" >
                                <ng-container *appIsAuth="true">
                                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                                        <mat-label i18n>Cliente</mat-label>
                                        <mat-select name="clienteID" [disabled]="!isNew"
                                            (ngModelChange)="utenteChanged($event)" [ngModel]="utenteSelezionatoEmail">
                                            <mat-option *ngFor="let utente of utenti" [value]="utente.Email">{{utente.Nome+
                                                " "
                                                +utente.Cognome + " - " + utente.Email}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
    
                                <div class="flex-row start-center gap12 fxFlex">
                                    <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                                        <mat-label i18n>Paziente</mat-label>
                                        <mat-select name="pazienteId" [(ngModel)]="consulto.PazienteID" required
                                            [disabled]="!isNew" (ngModelChange)="pazienteChanged($event)">
                                            <mat-option *ngFor="let paziente of pazientiFiltered "
                                                [value]="paziente.Id">{{paziente.Nome
                                                + " - " +
                                                paziente.NChip}}</mat-option>
                                        </mat-select>
                                        <button mat-icon-button color="primary" matSuffix (click)="$event.stopPropagation() ;aggiungiPaziente()" *ngIf="isNew"
                                            [disabled]="consulto.UserEmail == undefined"><mat-icon>add_circle</mat-icon></button>
                                    </mat-form-field>
                                    <ng-container>
                                        
                                    </ng-container>
    
                                </div>
                                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                                    <mat-label i18n>Tipo di consulto</mat-label>
                                    <mat-select [(ngModel)]="consulto.TipoConsultoID" name="tipo" required
                                        [disabled]="!isNew">
                                        <mat-option [value]="tipo.ID"
                                            *ngFor="let tipo of tipiConsulto">{{tipo.Descrizione}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <ng-container [ngTemplateOutlet]="datiReferto">
    
                            </ng-container>
                            <mat-form-field appearance="outline" class="fxFlex">
                                <mat-label i18n>Note</mat-label>
                                <textarea matInput name="note" [(ngModel)]="consulto.Note" rows="4"></textarea>
                            </mat-form-field>
                        </form>
                    </div>
                    <div class="flex-row end-center footer" style="margin-bottom: 8px;">
                        <button mat-raised-button matStepperNext color="primary" [disabled]="!firstStepCompleted"
                            i18n>Prossimo</button>
                    </div>
                </div>
            </mat-step>

            <!-- SECOND STEP: Allegati -->
            <mat-step [label]="'Allegati'">
                <ng-template matStepContent>
                    <div class="flex-col start-stretch" style="overflow: hidden;">
                        <div class="flex-col space-between-stretch stepWrapper gap12">
                            <app-allegati [consulto]="consulto" (allegati)="allegatiChanged($event)"
                                (eliminaAllegato)="eliminaAllegato($event)"></app-allegati>
                            </div>
                            <div class="flex-row space-between-end footer" style="margin-bottom: 8px;">
                                <button mat-raised-button matStepperPrevious color="warn" i18n>Indietro</button>
                                <button mat-raised-button matStepperNext color="primary" i18n>Prossimo</button>
                            </div>
                    </div>
                </ng-template>
            </mat-step>

            <!-- THIRD STEP: 
                - SE CLIENTE in INSERIMENTO: Riepilogo Dati paziente, cliente  
                - SE ADMIN: Produzione Referto (A seconda dell'esame richiesto)
                - SE CLIENTE A REFERTO PRODOTTO: Visualizzazione Referto - Download Referto
            -->
            <mat-step [label]="getLabelThirdStep()">
                <ng-template matStepContent>
                    <div class="flex-col start-stretch" style="overflow: hidden;">
                        <div class="stepWrapper flex-col space-between-stretch gap12">
                            <app-referto [consulto]="consulto" *ngIf="showReferto"></app-referto>
                            <ng-container *ngIf="!showReferto" [ngTemplateOutlet]="dettaglioClientePaziente"></ng-container>
    
                        </div>
                        <div class="flex-row space-between-end footer" style="margin-bottom: 8px;">
                            <button mat-raised-button matStepperPrevious color="warn" i18n>Indietro</button>
                            <button mat-raised-button matStepperNext color="primary"
                                [disabled]="secondStepProssimoDisabled && !secondStepSaved" i18n>Prossimo</button>
                        </div>
                    </div>
                </ng-template>
            </mat-step>




            <!-- FOURTH STEP: Teleconferenza
                - Visualizzazione data conferenza o templeta per la scelta della data
            -->
            <mat-step [label]="'Teleconferenza'">
                <div class="flex-col start-stretch" style="overflow: hidden;">
                    <div class="flex-col space-between-stretch stepWrapper">
                        <!-- SLOT ASSOCIATO -->
                        <ng-container *ngIf="slotAssociato != undefined">
                            <div class="flex-col center-center">
                                <span class="miniTitle" style="padding-top: 24px;" *ngIf="isAfterToday" i18n>Richiesto
                                    appuntamento
                                    in teleconferenza per il</span>
                                <span class="miniTitle" style="padding-top: 24px;" *ngIf="!isAfterToday" i18n>Appuntamento
                                    in
                                    teleconferenza il</span>
                                <span style="font-size: large;padding-top: 24px;">
                                    {{slotAssociato.DataInizio | date: 'EEEE dd/MM/yyyy HH:mm' |titlecase}}</span>
                                <ng-container *ngIf="isAfterToday">
                                    <div *appIsAuth="true" style="margin-top: 24px;" class="flex-row gap24">
                                        <button mat-raised-button
                                            *ngIf="consulto && consulto.Slot && consulto.Slot.Accettato != true"
                                            (click)="accettaAppuntamento()" color="primary" i18n>Accetta
                                            appuntamento</button>
                                        <button mat-raised-button
                                            *ngIf="consulto && consulto.Slot && consulto.Slot.Accettato != false "
                                            (click)="rifiutaAppuntamento()" color="accent" i18n>Rifiuta
                                            appuntamento</button>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <!-- SLOT NON ASSOCIATO -->
                        <ng-container *ngIf="slotAssociato == undefined">
                            <app-lista-slot (slotTeleconferenza)="slotScelto($event)"></app-lista-slot>
                        </ng-container>
                    </div>
                    <div class="flex-row space-between-center footer" style="margin-bottom: 8px;">
                        <button mat-raised-button matStepperPrevious color="warn" i18n>Indietro</button>
                        <button mat-raised-button matStepperNext color="primary" i18n>Prossimo</button>
                    </div>
                </div>
            </mat-step>


            <!-- 
                FIFTH STEP: Pagamento
                - SE CLIENTE: Scelta metodo di pagamento - Pagamento
                - SE ADMIN: Settaggio stato pagamento
             -->

            <mat-step label="Pagamento">
                <ng-template matStepContent>
                    <div class="flex-col start-stretch" style="overflow: hidden;">
                        <div class="flex-col space-between-stretch gap12 stepWrapper">
                            <app-pagamento [consulto]="consulto" [slot]="slotAssociato" [isNew]="isNew"
                                (metodoPagamentoChangedEmitter)="metodoPagamentoChanged($event)"
                                (pagamentoPaypalEffettuato)="pagamentoPaypalEffettuato($event)"></app-pagamento>
                            </div>
                            <div class="flex-row space-between-center footer" style="margin-bottom: 8px;">
                                <button mat-raised-button matStepperPrevious color="warn" i18n>Indietro</button>
                                <button mat-raised-button matStepperNext color="primary" (click)="salva()"
                                    [disabled]="salvaConsultoDisabled" i18n>Salva
                                    Consulto</button>
                            </div>
                    </div>
                </ng-template>
            </mat-step>
        </mat-stepper>
    </div>
</div>


<!-- dettaglioClientePaziente raccoglie i dati dettagliati per il paziente indicato e per l'utente che sta richiedendo il consulto. 
    Questo sarà sempre il secondo step se un cliente sta inserendo un consulto. 
    Se il consulto è già stato refertato. Anche il cliente potrà accedere alla sezione specifica con il referto.
    Altrimenti se l'utente loggato è Admin, il secondo step sarà il referto da riempire o già riempito.
-->
<ng-template #dettaglioClientePaziente>
    <div class="flex-col gap24" *ngIf="consulto && consulto.Paziente && consulto.User">
        <mat-expansion-panel class="no-padding">
            <mat-expansion-panel-header class="expansionHeader">
                <mat-panel-title>
                    <div class="flex-row start-center gap24 fxFlex">
                        <span>Utente: </span>
                        <div class="fxFlex space-between-center ">
                            <span class="miniTitle">{{consulto.User.Nome + " " + consulto.User.Cognome}} </span>
                            <span i18n>Controlla la correttezza dei dati anagrafici</span>
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow: auto!important;height: 320px;">
                <app-user-detail [userEmail]="consulto.UserEmail"></app-user-detail>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="consulto.PazienteID" class="no-padding">
            <mat-expansion-panel-header class="expansionHeader">
                <mat-panel-title>
                    <div class="flex-row start-center gap24 fxFlex">
                        <span>Paziente: </span>
                        <div class="fxFlex space-between-center ">
                            <span class="miniTitle">{{consulto.Paziente.Nome}}</span>
                            <span i18n>Controlla la correttezza dei dati anagrafici</span>
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="overflow: auto!important;height: 320px;">
                <app-pazienti-detail [pazienteID]="consulto.PazienteID"></app-pazienti-detail>
            </div>
        </mat-expansion-panel>
    </div>
</ng-template>

<ng-template #datiReferto>
    <div class="flex-col gap12">
        <mat-divider></mat-divider>
        <span class="miniTitle" i18n>Considerazioni preliminari</span>
        <mat-divider></mat-divider>
        <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
            <mat-label>Motivo dell'esame</mat-label>
            <textarea matInput name="MotivoEsame" placeholder="Motivo esame" [(ngModel)]="consulto.MotivoEsame"
                i18n-placeholder></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
            <mat-label i18n>Anamnesi</mat-label>
            <textarea matInput name="Anamnesi" placeholder="Anamnesi" [(ngModel)]="consulto.Anamnesi"
                i18n-placeholder></textarea>
        </mat-form-field>
    </div>
    <div class="flex-col gap12" *ngIf="isTipoEcocardiografia()">
        <mat-divider></mat-divider>
        <span class="miniTitle" i18n>CAMPI PER ECOCARDIOGRAFIA</span>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch">
            <mat-label i18n>Sensorio</mat-label>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <input matInput name="Sensorio" placeholder="Sensorio" [(ngModel)]="consulto.EcocardiografiaSensorio"
                    i18n-placeholder>
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch">
            <mat-label i18n>Mucose</mat-label>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <input matInput name="Mucose" placeholder="Mucose" [(ngModel)]="consulto.EcocardiografiaMucose" i18n-placeholder>
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch">
            <mat-label i18n>Frequenza respiratoria</mat-label>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <input matInput name="FrequenzaRespiratoria" placeholder="Frequenza Respiratoria" i18n-placeholder
                    [(ngModel)]="consulto.EcocardiografiaFrequenzaRespiratoria">
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch">
            <mat-label i18n>Soffi cardiaci</mat-label>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <input matInput name="SoffiCardiaci" placeholder="Soffi cardiaci"
                    [(ngModel)]="consulto.EcocardiografiaSoffiCardiaci" i18n-placeholder>
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch">
            <mat-label i18n>Frequenza cardiaca</mat-label>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <input matInput name="FrequenzaCardiaca" placeholder="Frequenza cardiaca"
                    [(ngModel)]="consulto.EcocardiografiaFrequenzaCardiaca" i18n-placeholder>
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch">
            <mat-label i18n>TRC</mat-label>
            <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
                <input matInput name="Trc" placeholder="TRC" [(ngModel)]="consulto.EcocardiografiaTrc" i18n-placeholder>
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>
    </div>
</ng-template>