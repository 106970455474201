import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DefaultReferto } from 'src/app/model/referti/default-value.model';
import { Ecg } from 'src/app/model/referti/ecg.model';
import { ruoloCliente } from 'src/app/model/user.model';
import { ConsultoUnsavedDataService } from 'src/app/services/consulto-unsaved-data.service';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
  selector: 'app-ecg',
  templateUrl: './ecg.component.html',
  styleUrls: ['./ecg.component.scss'],
})
export class EcgComponent implements OnDestroy {

  subs: Subscription = new Subscription();

  constructor(
    private currentUser: CurrentUserService,
    private modifiche: ConsultoUnsavedDataService,
  ) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @ViewChild('ecgWrapper', { read: ElementRef }) ecgWrapper:
    | ElementRef
    | undefined;


  defReferto = DefaultReferto;

  @ViewChild('f') form: NgForm | undefined;

  // Ci serve per valutare se il form è stato inizializzato
  formInit: boolean = false;
  get hasForm(): void {
    if(this.form == undefined || this.formInit == true) {
      return ;
    }else{
      this.formInit = true;
      console.log("FORM INIT");
      /**
      Si esegue la subscription in un timeout per evitare che la prima assegnazione del form,
      dopo il recupero dei dati dal server, venga considerata come una modifica
       */
      setTimeout(() => {
        this.formSub();
      }, 500);
    }
  }

  formSub(){
    this.subs.add(
    this.form?.valueChanges?.subscribe((_) => {
      this.modifiche.modificheNonSalvate.next(true);
    }));
  }
  
  _referto: Ecg | undefined;
  @Input() set referto(value: Ecg | undefined) {
    this._referto = value;
  }
  get referto(): Ecg | undefined {
    return this._referto;
  }

  get isCliente(): boolean {
    if (this.currentUser.currentUser == undefined) {
      return false;
    }
    return this.currentUser.currentUser?.Ruolo == ruoloCliente;
  }
}
