<div>
    <mat-toolbar class="internal-toolbar ">
        <div class="flex-row space-between-center fxFlex">
            <span class="miniTitle" i18n>Fatture</span>
        </div>
    </mat-toolbar>

    <mat-table mat-table [dataSource]="listaFatture">
        <ng-container matColumnDef="numero">
            <mat-header-cell *matHeaderCellDef i18n> Numero:  </mat-header-cell>
            <mat-cell *matCellDef="let fattura"> {{fattura.Progressivo?.Numero}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="anno">
            <mat-header-cell *matHeaderCellDef i18n> Anno:  </mat-header-cell>
            <mat-cell *matCellDef="let fattura"> {{fattura.Progressivo?.Anno}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="creata">
            <mat-header-cell *matHeaderCellDef i18n> Creata il:  </mat-header-cell>
            <mat-cell *matCellDef="let fattura"> {{fattura.CreatedAt | date: 'dd/MM/yyyy'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="cessionario">
            <mat-header-cell *matHeaderCellDef i18n> Cessionario/Committente  </mat-header-cell>
            <mat-cell *matCellDef="let fattura"> {{fattura.Consulto.User.Nome }} &nbsp; {{fattura.Consulto.User.Cognome}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="paziente">
            <mat-header-cell *matHeaderCellDef i18n> Paziente  </mat-header-cell>
            <mat-cell *matCellDef="let fattura"> {{fattura.Consulto.Paziente.Nome }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="stato">
            <mat-header-cell *matHeaderCellDef i18n> Stato: </mat-header-cell>
            <mat-cell *matCellDef="let fattura"> 
                <mat-icon color="warn" *ngIf="statoFattura(fattura) == 1" matTooltip="Errore di invio fattura al sistema di interscambio" i18n-matTooltip>error</mat-icon>
                <mat-icon color="accent" *ngIf="statoFattura(fattura) == 0" matTooltip="Fattura inviata al sistema di interscambio." i18n-matTooltip>pending</mat-icon>
                <mat-icon color="primary" *ngIf="statoFattura(fattura) == 2" matTooltip="Fattura Riconciliata" i18n-matTooltip>receipt</mat-icon>
            </mat-cell>
        </ng-container>
        <!-- TODO: Download -->
        <ng-container matColumnDef="download">
            <mat-header-cell *matHeaderCellDef i18n> Download: </mat-header-cell>
            <mat-cell *matCellDef="let fattura"> 
                <button mat-icon-button (click)="scaricaFattura(fattura)" color="primary" matTooltip="Scarica la fattura" i18n-matTooltip *ngIf="statoFattura(fattura) == 2 || statoFattura(fattura) == 0">
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="table-header" ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"  ></mat-row>
    </mat-table>
</div>