<div style="position: absolute; top: 0; z-index: 100; height: 100vh; width: 0.1%; " (mouseenter)="sidenav.open()"
    *ngIf="openMenuOnMouseOver"></div>

<div class="mainContainer">
    <mat-toolbar class="toolbar" style="background-color: var(--primary);">
        <!-- TOGGLE SIDENAV -->

        <div class="titolo-e-logo">
            <button [ngClass]="{ 'd-none': !logged }" id="toggle-right-btn" mat-icon-button aria-label="toggle sidevan"
                (click)="menuButtonClicked()">
                <mat-icon style="color: white !important">{{openMenuOnMouseOver?
                    'menu':'menu_open'}}</mat-icon>
            </button>
            <div class="logo-container">
                <img [src]="localizedLogo" alt="logo" height="60px" />
            </div>
            <span style="color: white; margin-left: 12px;">LENOCI VETERINARY CARDIOLOGY</span>
        </div>

        <div class="flex-row start-center gap12" >
            <button [matMenuTriggerFor]="settings" mat-icon-button color="accent" *appIsAuth="true">
                <mat-icon>settings</mat-icon>
            </button>
            <div class="menu-utente-container ">
                <button mat-mini-fab color="accent" *ngIf="teleconferenzeProntePerUtente.length > 0" [matTooltip]="'Partecipa alla teleconferenza: ' + 
                 (teleconferenzeProntePerUtente[0].Consulto?.User?.Nome ||'') + ' ' + 
                 (teleconferenzeProntePerUtente[0].Consulto?.User?.Cognome || '')" class="box-shadow animatedConferenceBtn"
                    style="color: var(--primary);" (click)="goToConference()">
                    <mat-icon>videocam</mat-icon>
                </button>
                <button mat-fab style="background-color: var(--accent);" [matMenuTriggerFor]="menuUtente"
                    aria-label="User Menu">
                    <span class="iniziali-utente">{{inizialiUtente()}}</span>
                </button>
                <!-- <app-i18n-switcher></app-i18n-switcher> -->
            </div>
        </div>

    </mat-toolbar>



    <mat-sidenav-container autosize class="navigation-container">
        <mat-sidenav (mouseleave)="openMenuOnMouseOver? sidenav.close(): ''"
            [opened]="openMenuOnMouseOver? false : true" #sidenav [mode]="openMenuOnMouseOver? 'over':'side'"
            position="start" class="menu-bar">
            <app-menu></app-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <mat-progress-bar [mode]="isLoading? 'indeterminate':'determinate'" [value]="isLoading? '':0"
                style="position: absolute;z-index: 1000;"></mat-progress-bar>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<mat-menu #menuUtente>
    <button mat-menu-item (click)="dettaglioUtente()" class="flex-row start-center" style="height: 120px;">
        <div class="flex-col start-stretch">
            <mat-icon style="color: var(--primary);">{{iconaUtente}}</mat-icon>
        </div>
        <div *ngIf="utenteAttivo" class="flex-col start-stretch">
            <span style="font-weight: 700;">{{utenteAttivo.Nome}}&nbsp;{{utenteAttivo.Cognome}}</span>
            <span style="font-size: small; color:gray">{{utenteAttivo.Email}}</span>
            <span style="font-size: small; font-weight: 700;color: var(--primary); ">{{utenteAttivo.Ruolo | uppercase}}</span>
        </div>
    </button>
    <mat-divider></mat-divider>
    <!-- <div mat-menu-item (click)="cambiaPassword()">
        <mat-icon>password</mat-icon>
        <span>Cambia password</span>
    </div> -->
    <mat-divider> </mat-divider>
    <div mat-menu-item (click)="logOut()">
        <mat-icon color="warn">logout</mat-icon>
        <span style="font-weight: 700;color: var(--accent);">Esci</span>
    </div>
</mat-menu>

<mat-menu #settings>
    <button mat-menu-item (click)="notifiche()">
        <mat-icon color="primary">payments</mat-icon>
        <span>Notifiche Consulti</span>
    </button>
</mat-menu> 