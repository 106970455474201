<div class="flex-col start-stretch gap24">
  <mat-toolbar class="internal-toolbar">
    <button mat-icon-button mat-dialog-close [tabindex]="-1">
      <mat-icon>close</mat-icon>
    </button>
    <div class="miniTitle" i18n>Inserisci una nuova password</div>
  </mat-toolbar>

  <div class="flex-col start-stretch gap24" style="padding: 24px;">
    <form [formGroup]="form">
      <div class="flex-col start-stretch gap12">
        <div ngClass="fxFlex" *ngIf="data.OldRequested" class="input-container">
          <mat-form-field appearance="outline" class="fxFlex">
            <mat-label i18n>Vecchia password</mat-label>
            <input type="password" matInput formControlName="oldPassword" />
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="fxFlex">
          <mat-label i18n>Nuova Password</mat-label>
          <input type="password" matInput formControlName="newPassword" autofocus/>
        </mat-form-field>
        <mat-form-field appearance="outline" class="fxFlex" >
          <mat-label i18n>Ripeti nuova password</mat-label>
          <input type="password" matInput formControlName="newPassword2" />
        </mat-form-field>
        <mat-error *ngIf="!valid" i18n>Le password non corrispondono</mat-error>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
          <button mat-raised-button [mat-dialog-close]="result" color="accent" class="save-button" [disabled]="!valid">
            <span i18n>Save</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>