import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginRichiestaAccessoCoordinatorService {

  constructor() { }

  public isRichiestaAccesso: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public setRichiestaAccesso(value: boolean): void {
    this.isRichiestaAccesso.next(value);
  }
}
