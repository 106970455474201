export const DefaultReferto: string = `PER ECOCARDIO: L’esame B-mode evidenzia camere cardiache nella norma con rapporto AS/Ao nei limiti. 
Le valvole AV e semilunari appaiono nella norma. 
L’esame M-mode evidenzia VS nella norma per spessori parietali e cinesi. 
L’esame color doppler non evidenzia flussi turbolenti. 
L’esame doppler spettrale evidenzia pattern diastolico normale. 
Flussi transaortici, transpolmonari e transtricuspidali nella norma. Il quadro ecocardiografico risulta nella norma.`

export const DefaultFegato:string = `Fegato non debordante dall’arcata costale, con profilo regolare, a ecostruttura 
omogenea esente da immagini di tipo focale. Non dilatate le vie biliari intra o extraepatiche, colecisti normalmente distesa con pareti sottili, alitiasica`;

export const DefaultMilza:string = `Milza di dimensioni normali con ecostruttura omogenea non si rilevano immagini riferibili a lesioni focali o a raccolte.`;

export const DefaultApparatoGastrointericoEPancreas:string = `Pancreas nei limiti per dimensioni ed ecogenicità, dotto di calibro normale, non si osservano processi espansivi né calcificazioni. 
oppure ( non chiaramente visibile, non si osservano processi espansivi nella sede anatomica). 
Stomaco nella norma, parete regolare con spessore e stratificazione normali, non si osservano alterazioni del contenuto 
Duodeno nella norma per parete regolare con spessore e stratificazione normali, non si osservano i segni diretti o indiretti di possibili fatti di sub-occlusione 
Anse digiunali nella norma per parete regolare con spessore e stratificazione normali, non si osservano i segni diretti o indiretti di possibili fatti di sub-occlusione 
Ileo cieco nella norma per parete regolare con spessore e stratificazione normali, non si osservano i segni diretti o indiretti di possibili fatti di sub-occlusione; tratto colico nella norma.`;

export const DefaultLinfonodi:string = `Linfonodi esplorati normali per dimensioni morfologia profilo ed ecostruttura.`;

export const DefaultReni:string = `Reni in sede regolari per dimensioni morfologia e profilo. Il parenchima è normale per spessore ed ecogenicità. 
normali la capsula e la differenziazione delle zone midollare e corticale. Vie escretrici non dilatate, non immagini riferibili a calcoli.`;

export const DefaultSurreni:string = `Surreni visibili, regolari per dimensioni morfologia e profilo. (se non visibili: non si osservano processi espansivi nella loro sede anatomica) (oppure surreni non chiaramente visibili e non ingrandite)`;
export const DefaultVescica:string = `Vescica urinaria moderatamente capace (distesa), alitiasica ed i profili sono regolari e lo spessore della parete normale.`;
export const DefaultApparatoGenitale:string = `Prostata di dimensioni normali con ecostruttura omogenea, non ectasia duttale, non si osservano lesioni di tipo focale, cisti, o calcificazioni. 
Ovaie visibili regolari per dimensioni morfologia e profilo (se non visibili: non si osservano processi espansivi nella loro sede anatomica) (oppure ovaie non chiaramente visibili e non ingrandite) 
Utero visibile di dimensioni normali (per la fase di ciclo estrale)
con ecostruttura omogenea non si rilevano immagini riferibili a lesioni cistico-endometriali o a raccolte cavitarie`;

