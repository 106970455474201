import {
  ApplicationRef,
  ErrorHandler,
  Injectable,
  OnDestroy,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

// esempio preso dal SwUpdatesService di https://angular.io/ tramite debugger di chrome

/**
 * SwUpdatesService
 *
 * @description
 * While enabled, this service will:
 * 1. Check for available ServiceWorker updates every 5 minutes.
 * 2. Activate an update as soon as one is available.
 *
 *  * Guide:
 * - https://blog.angular-university.io/angular-service-worker/
 *
 * - https://angular.io/guide/service-worker-communications
 *
 */
@Injectable({ providedIn: 'root' })
export class SwUpdatesService implements OnDestroy {
  private checkInterval = 1000 * 60 * 5; // 5 minutes
  private onDisable = new Subject<void>();

  constructor(
    private appRef: ApplicationRef,
    private errorHandler: ErrorHandler,
    private swu: SwUpdate
  ) {}

  disable(): void {
    this.onDisable.next();
  }

  enable(): void {
    if (!this.swu.isEnabled) {
      return;
    }

    // Periodically check for updates (after the app is stabilized).
    const appIsStable = this.appRef.isStable.pipe(first((v) => v));
    concat(appIsStable, interval(this.checkInterval))
      .pipe(
        tap(() => this.log('Checking for update...')),
        takeUntil(this.onDisable)
      )
      .subscribe(() => {
        this.swu.checkForUpdate();
      });

    // Activate available updates.
    this.swu.versionUpdates
    .pipe(
      filter(evt => evt.type === 'VERSION_READY'), // Filter for VersionReadyEvent
      tap((evt) => { 
        this.log(`Update available: ${JSON.stringify(evt)}`)
      }),
      takeUntil(this.onDisable)
    )
    .subscribe((ev) => {
      if(ev.type.includes('NO_NEW_VERSION_DETECTED')){
        return;
      }
      if (
        confirm(
          "E' disponibile una nuova versione. Ricaricare la pagina per aggiornare"
        )
      ) {
        this.swu.activateUpdate().then(() => document.location.reload());
      }
    });

    // // Request a full page navigation once an update has been activated.
    // this.swu.activated
    //     .pipe(
    //         tap(evt => this.log(`Update activated: ${JSON.stringify(evt)}`)),
    //         takeUntil(this.onDisable),
    //     )
    //     .subscribe(() => this.location.fullPageNavigationNeeded());

    // Request an immediate page reload once an unrecoverable state has been detected.
    this.swu.unrecoverable
      .pipe(
        tap((evt) => {
          const errorMsg = `Unrecoverable state: ${evt.reason}`;
          this.errorHandler.handleError(errorMsg);
          this.log(`${errorMsg}\nReloading...`);
        }),
        takeUntil(this.onDisable)
      )
      .subscribe((event) => {
        if (
          confirm(
            'Si è verificato un errore da cui non è possibile recuperare:\n' +
              event.reason +
              '\n\nSi prega di ricaricare la pagina.'
          )
        ) {
          window.location.reload();
        }
      });
  }

  ngOnDestroy(): void {
    this.disable();
  }

  private log(message: string): void {
    const timestamp = new Date().toISOString();
    console.log(`[SwUpdates - ${timestamp}]: ${message}`);
  }
}
