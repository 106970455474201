import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultoRefertoCoordinatorService {

  public isFormTouched: Subject<boolean> = new Subject<boolean>();
  public isFormSaved: Subject<boolean> = new Subject<boolean>();

  public RefertoUnsaved = new Subject<boolean>();

  constructor() { }
}
