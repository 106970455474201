import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { MessageConstants, MessageData, MessageService } from "../services/message.service";
import { AuthenticationService } from "../services/authentication.service";

export type ForeignKeyErrors = {
    KeyName: string;
    Message: string;
}

const ForeignKeyErrors: ForeignKeyErrors[] = [
    {KeyName: 'pazienti_user_email_fkey', Message: "L'utente non può essere cancellato perchè almeno un paziente è associato ad esso"},
    {KeyName: 'allegati_created_by_fkey', Message: "L'utente non può essere cancellato perchè almeno un allegato è associato ad esso"},
    {KeyName: 'ecg_consulto_id_fkey', Message: "Il consulto non può essere cancellato perchè un Referto è associato ad esso"},
    {KeyName: 'ecocardiografie_consulto_id_fkey', Message: "Il consulto non può essere cancellato perchè un Referto è associato ad esso"},
    {KeyName: 'ecografie_consulto_id_fkey', Message: "Il consulto non può essere cancellato perchè un Referto è associato ad esso"},
    {KeyName: 'holters_consulto_id_fkey', Message: "Il consulto non può essere cancellato perchè un Referto è associato ad esso"},
    {KeyName: 'radiografie_consulto_id_fkey', Message: "Il consulto non può essere cancellato perchè un Referto è associato ad esso"},
    {KeyName: 'small_parts_consulto_id_fkey', Message: "Il consulto non può essere cancellato perchè un Referto è associato ad esso"},
    {KeyName: 'fatture_consulto_id_fkey', Message: "Il consulto non può essere cancellato perchè una Fattura è associata ad esso"},
    {KeyName: 'pagamenti_consulto_id_fkey', Message: "Il consulto non può essere cancellato perchè dati di pagamento sono associato ad esso"},
]

@Injectable()
export class ErrorInterceptor implements HttpInterceptor{
    constructor(
        private messageService: MessageService,
        private auth: AuthenticationService,
    ){}

    intercept(req: HttpRequest<any>, next: HttpHandler) :Observable<HttpEvent<any>>{
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => { 
                console.log(err);
                // TODO: Controllare anche il codice di errore, se include token scaduto, fare logout
                if(err.status === 401 ){
                    this.auth.logout();
                }
                const error = err.error || err.statusText;
                this.handleError(err.message);
                return throwError(() => error);
            })
        );
    }

    //TODO: gestire gli errori con una  tabella di translation
    private handleError(error: string){
        const m: MessageData = MessageConstants.Errore;
        m.Message = error;
        for (let i = 0; i < ForeignKeyErrors.length; i++) {
            const element = ForeignKeyErrors[i];
            if(error && error != '' && error.includes(element.KeyName)){
                m.Message = element.Message;
                break;
            }
        }
        this.messageService.sendMessage(m);
    }


}