import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { Consulto } from 'src/app/model/consulto.model';
import { Ecg } from 'src/app/model/referti/ecg.model';
import { Ecocardiografia } from 'src/app/model/referti/ecocardiografia.model';
import { Ecografia } from 'src/app/model/referti/ecografia.model';
import { Holter } from 'src/app/model/referti/holter.model';
import { Radiografia } from 'src/app/model/referti/radiografia.model';
import { SmallPart } from 'src/app/model/referti/small-part.model';
import { TipiConsulti } from 'src/app/model/tipo-consulto.model';
import { ruoloAdmin, User } from 'src/app/model/user.model';
import { ConsultoRefertoCoordinatorService } from 'src/app/services/consulto-referto-coordinator.service';
import { ConsultoUnsavedDataService } from 'src/app/services/consulto-unsaved-data.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import {
  MessageConstants,
  MessageData,
  MessageService,
} from 'src/app/services/message.service';
import {
  datiGenerazioneReferto,
  PdfRefertoService,
} from 'src/app/services/pdf-referto.service';
import { ApiUrl } from 'src/app/shared/api-url';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';

const URL: string = ApiUrl.REFERTI;
const TABLE_ECG: string = 'ecg';
const TABLE_ECOCARDIOGRAFIA: string = 'ecocardiografie';
const TABLE_ECOGRAFIA: string = 'ecografie';
const TABLE_HOLTER: string = 'holters';
const TABLE_RADIOGRAFIA: string = 'radiografie';
const TABLE_SMALL_PART: string = 'small_parts';

@Component({
  selector: 'app-referto',
  templateUrl: './referto.component.html',
  styleUrls: ['./referto.component.scss'],
})
export class RefertoComponent implements OnInit {
  _consulto: Consulto | undefined;
  @Input() set consulto(value: Consulto | undefined) {
    this._consulto = value;
  }
  get consulto(): Consulto | undefined {
    return this._consulto;
  }
  refertoType: number = -1;
  isNew: boolean = true;
  refertatori: User[] = []; 
  
  disponibileChanged(){
    this.modifiche.modificheNonSalvate.next(true);
  }

  referto:
    | Ecg
    | Ecocardiografia
    | Ecografia
    | Holter
    | Radiografia
    | SmallPart
    | undefined;

  tipiCosulti = TipiConsulti;

  constructor(
    private http: HttpClient,
    private currentUser: CurrentUserService,
    private consultoRefertoCoordinatorService: ConsultoRefertoCoordinatorService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private pdfRefertoService: PdfRefertoService,
    private modifiche: ConsultoUnsavedDataService
  ) {}

  get isCliente(): boolean {
    return this.currentUser.currentUser?.Ruolo == 'cliente';
  }

  ngOnInit(): void {
    this.setValuesForTipoReferto();
    this.loadReferto();
    this.loadListaRefertatori();
  }

  private loadListaRefertatori(){
    const url = ApiUrl.USERS;
    let params = new HttpParams().set('soloAdmin', true);
    this.http.get<User[]>(url, {params: params}).subscribe((users) => {
      this.refertatori = users;
    });
  }

  private setValuesForTipoReferto() {
    if (this.consulto == undefined) {
      // setTimeout(() => {
      //   this.setValuesForTipoReferto();
      // }, 500);
      return console.log('ConsultoID undefined');
    }
    switch (this.consulto?.TipoConsultoID) {
      case TipiConsulti.Ecg:
        this.generaSpecificReferto = this.generaEcg;
        this.refertoType = TipiConsulti.Ecg;
        break;
      case TipiConsulti.Ecocardiografia:
        this.refertoType = TipiConsulti.Ecocardiografia;
        this.generaSpecificReferto = this.generaEcocardiografia;
        break;
      case TipiConsulti.Ecografia:
        this.refertoType = TipiConsulti.Ecografia;
        this.generaSpecificReferto = this.generaEcografia;
        break;
      case TipiConsulti.Holter:
        this.refertoType = TipiConsulti.Holter;
        this.generaSpecificReferto = this.generaHolter;
        break;
      case TipiConsulti.Radiografia:
        this.refertoType = TipiConsulti.Radiografia;
        this.generaSpecificReferto = this.generaRadiografia;
        break;
      case TipiConsulti.SmallPart:
        this.refertoType = TipiConsulti.SmallPart;
        this.generaSpecificReferto = this.generaSmallPart;
        break;
    }
  }

  private loadReferto() {
    if (this.consulto == undefined) {
      return console.log('ConsultoID undefined');
    }
    if (this.consulto.ID == undefined) {
      return console.log('Nessun referto associato al consulto');
    }
    let params: HttpParams = new HttpParams().set(
      'consultoID',
      this.consulto.ID
    );
    params = params.set('tipo', this.refertoType.toString());
    this.http
      .get<
        Ecg | Ecocardiografia | Ecografia | Holter | Radiografia | SmallPart
      >(URL, { params: params })
      .subscribe((referto) => {
        this.referto = referto;
        if (this.referto != undefined) {
          this.isNew = false;
        }
        console.log(this.referto);
      });
  }

  protected generaReferto() {
    this.isNew = true;
    this.generaSpecificReferto();
  }

  generaSpecificReferto!: () => void;

  private generaEcg() {
    console.log('Genera ECG');
    this.referto = {
      ConsultoID: this.consulto!.ID,
      EseguitoDa: this.currentUser.currentUser!.Email,
    } as Ecg;
  }
  private generaEcocardiografia() {
    console.log('Genera Ecocardiografia');
    this.referto = {
      ConsultoID: this.consulto!.ID,
      EseguitoDa: this.currentUser.currentUser!.Email,
    } as Ecocardiografia;
  }
  private generaEcografia() {
    console.log('Genera Ecografia');
    this.referto = {
      ConsultoID: this.consulto!.ID,
      EseguitoDa: this.currentUser.currentUser!.Email,
    } as Ecografia;
  }
  private generaHolter() {
    console.log('Genera Holter');
    this.referto = {
      ConsultoID: this.consulto!.ID,
      EseguitoDa: this.currentUser.currentUser!.Email,
    } as Holter;
  }
  private generaRadiografia() {
    console.log('Genera Radiografia');
    this.referto = {
      ConsultoID: this.consulto!.ID,
      EseguitoDa: this.currentUser.currentUser!.Email,
    } as Radiografia;
  }
  private generaSmallPart() {
    console.log('Genera SmallPart');
    this.referto = {
      ConsultoID: this.consulto!.ID,
      EseguitoDa: this.currentUser.currentUser!.Email,
    } as SmallPart;
  }

  get refertoEcg(): Ecg | undefined {
    return this.referto as Ecg;
  }
  get refertoEcocardiografia(): Ecocardiografia | undefined {
    return this.referto as Ecocardiografia;
  }
  get refertoEcografia(): Ecografia | undefined {
    return this.referto as Ecografia;
  }
  get refertoHolter(): Holter | undefined {
    return this.referto as Holter;
  }
  get refertoRadiografia(): Radiografia | undefined {
    return this.referto as Radiografia;
  }
  get refertoSmallParts(): SmallPart | undefined {
    return this.referto as SmallPart;
  }

  get mostraScaricaPDF(): boolean {
    return (
      this.referto != undefined &&
      this.referto.ID != undefined &&
      this.referto.ID > 0
    );
  }

  scaricaPDF() {
    let dati: datiGenerazioneReferto = {
      tipo: this.refertoType as TipiConsulti,
      refertoID: this.referto?.ID || -1,
    };

    this.pdfRefertoService.consultoRichiedeGenerazionePDF.next(dati);
  }

  protected async salvaReferto() {
    if (
      this.referto?.Disponibile &&
      (await lastValueFrom(this.avvisaRefertoDisponibileCliente())) == false
    ) {
      return;
    }
    if (this.referto == undefined) {
      return console.log('Referto undefined');
    }
    let params = new HttpParams().set('tipo', this.refertoType.toString());
    if (this.isNew) {
      this.http.post(URL, this.referto, { params: params }).subscribe((res) => {
        console.log(res);
        const resReferto = res as
          | Ecg
          | Ecocardiografia
          | Ecografia
          | Holter
          | Radiografia
          | SmallPart;
        this.referto!.ID = resReferto.ID;
        this.refertoInserito();
      });
    } else {
      this.http.put(URL, this.referto, { params: params }).subscribe((res) => {
        console.log(res);
        this.refertoModificato();
        const resReferto = res as
          | Ecg
          | Ecocardiografia
          | Ecografia
          | Holter
          | Radiografia
          | SmallPart;
        this.referto!.ID = resReferto.ID;
      });
    }
  }

  private refertoInserito: () => void = () => {
    let messageData: MessageData = MessageConstants.Inserimento;
    messageData.Message = 'Referto inserito con successo.';
    this.messageService.sendMessage(messageData);
    this.consultoRefertoCoordinatorService.isFormSaved.next(true);
    this.modifiche.modificheNonSalvate.next(false);
  };

  private refertoModificato: () => void = () => {
    let messageData: MessageData = MessageConstants.Modifica;
    messageData.Message = 'Referto modificato con successo.';
    this.messageService.sendMessage(messageData);
    this.consultoRefertoCoordinatorService.isFormSaved.next(true);
    this.modifiche.modificheNonSalvate.next(false);
  };

  avvisaRefertoDisponibileCliente(): Observable<boolean> {
    let message =
      "Hai selezionato 'Rendi disponibile al cliente.' Il cliente che ha richiesto il consulto potrà visualizzare il referto. Vuoi procedere?";
    let d: ConfirmDialogData = {
      message: [message],
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: d });
    return dialogRef.afterClosed();
  }

  refertatoreChanged(ev: string){
    if(this.referto == undefined){
      return;
    }
    let u = this.refertatori.find((u) => u.Email == ev);
    this.referto.EseguitoDaUser = u;
  }

}
