import { Component } from '@angular/core';

@Component({
  selector: 'app-recupera-password',
  templateUrl: './recupera-password.component.html',
  styleUrls: ['./recupera-password.component.scss']
})
export class RecuperaPasswordComponent {
  email?: string = '';
}
