
<div class="flex-col start-stretch fxFlex" >
    <mat-toolbar>
        <span class="miniTitle">CREAZIONE FATTURA</span>
    </mat-toolbar>

    <div style="padding: 0px 24px 24px 24px ;" class="flex-col gap12 start-stretch">
        <span style="font-weight: 700; color: var(--accent);">Attenzione:</span>
        <span i18n> Cliccando su crea fattura, il sistema si occuperà di creare la fattura e inviarla al Sistema di Interscambio. 
            L'esito dell'invio della fattura verrà riportato nel dettaglio del consulto, 
            dal quale sarà anche possibile scaricare il PDF della fattura proforma.
            Al sistema di interscambio è affidato anche l'onere della conservazione digitale. 
            Se, l'invio al sistema di interscambio non avrà successo sarà visualizzato un avviso nel dettaglio del pagamento. 
            che riporterà (laddove presente) la ragione che ha portato all'errore. 
            Sarà possibile, una volta risolti gli errori, ri-creare la fattura e riprovare l'invio.
        </span>

        <!-- <span class="miniTitle">Per procedere alla creazione della fattura è necessario specificare i seguenti dati:</span>
        <div class="fxFlex flex-row gap12 start-center" >
            <mat-label i18n>Aliquota Iva</mat-label>
            <mat-form-field appearance="outline">
                <input type="number" matInput name="aliquotaIva" placeholder="Aliquota IVA" [(ngModel)]="data.AliquotaIva">
            </mat-form-field>
        </div> -->
        
        <div class="flex-row space-between-end fxFlex " >
            <button mat-raised-button color="accent" (click)="close()" i18n>Annulla</button>
            <button mat-raised-button color="primary" (click)="inviaConsultoPerCreazioneFattura()" i18n>Crea Fattura</button>
        </div>
    </div>
</div>
