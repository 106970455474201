<div class="menu_container fxFlex" style="height: calc(100vh - 88px);">
    <div class="flex-col start-stretch gap12">
        <div   *ngFor="let voice of menuVoices" class="menu_voice_wrapper">
            <button 
                style="color: var(--primary);"
                mat-button 
                [routerLink]="voice.Path" 
                routerLinkActive="active" 
                [routerLinkActiveOptions]="{exact: true}" 
                class="menu_voice" *appIsAuth="voice.AuthRequired">
                <mat-icon>{{voice.Icon}}</mat-icon>
                <span>{{voice.Label}}</span>
            </button>
        </div>
    </div>
    <div style="justify-content: end; font-size: small; color: var(--primary);" >
        versione : {{version}}
    </div>
</div>