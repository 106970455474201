<script
    src="https://www.paypal.com/sdk/js?client-id=AV_V0GblzWTC6TvxqRQN-qB84oRRby3Q4T0jmgX5-hpU3Dq3ZlNY9yI4XBfRjGJDanUzFiIXuWgaeqkO&components=buttons&debug=true"></script>
<div class="flex-col start-stretch gap12">
    <div class="fakeToolbar flex-row space-between-center">
        <div class="flex-row space-between-center fxFlex" style="padding-right: 24px;padding-left: 24px;"
            *ngIf="tipoConsulto">
            <span class="miniTitle" i18n>TEST DIAGNOSTICO: </span>
            <span class="accented">{{tipoConsulto.Descrizione | uppercase}}</span>
        </div>
    </div>

    <div class="flex-row start-center gap24" style="padding-left: 24px;padding-right: 24px;">
        <div class="flex-col flex50 riepilogo">
            <span class="intestazioni" i18n>DATI PAZIENTE</span>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Nome</span>
                <span class="valori">{{consulto.Paziente?.Nome}}</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Proprietario</span>
                <span class="valori">{{consulto.Paziente?.CognomeProprietario}}</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Specie</span>
                <span class="valori">{{consulto.Paziente?.Specie}}</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Razza</span>
                <span class="valori">{{consulto.Paziente?.Razza}}</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Sesso</span>
                <span class="valori">{{consulto.Paziente?.Sesso}}</span>
            </div>
        </div>
        <div class="flex-col flex50 riepilogo">
            <!-- TODO: Riepilogare i dati che saranno usati nella fattura a seconda ... -->
            <span class="intestazioni" style="text-align: right;" i18n>DATI CESSIONARIO/COMMITTENTE</span>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Nome e Cognome</span>
                <span class="valori">{{consulto.User?.Nome}}&nbsp;{{consulto.User?.Cognome}}</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Indirizzo</span>
                <span class="valori">{{consulto.FatturaAClinica? (consulto.User?.IndirizzoClinica || "") :
                    (consulto.User?.Indirizzo || "")}}</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Cap</span>
                <span class="valori">{{consulto.FatturaAClinica? consulto.User?.CapClinica : consulto.User?.Cap}}</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Comune (Provincia)</span>
                <span class="valori">{{consulto.FatturaAClinica ? consulto.User?.ComuneClinica :
                    consulto.User?.Comune}}&nbsp;({{consulto.FatturaAClinica ? consulto.User?.ProvinciaClinica :
                    consulto.User?.Provincia}})</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Nazione</span>
                <span class="valori">{{consulto.FatturaAClinica ? consulto.User?.NazioneClinica :
                    consulto.User?.Nazione}}</span>
            </div>
            <div class="flex-row gap24">
                <span class="intestazioni" i18n>Codice Fiscale</span>
                <span class="valori">{{consulto.FatturaAClinica? consulto.User?.CodiceFicaleClinica:
                    consulto.User?.CodiceFiscale}}</span>
            </div>

        </div>
    </div>
    <mat-divider></mat-divider>

    <ng-container>
        <div class="flex-row gap24 space-between-center" style="padding-left: 24px; padding-right: 24px;">
            <div class="flex-row gap24 start-center" *ngIf="tipoConsulto">
                <span class="miniTitle" i18n>TOTALE A PAGARE: </span>
                <span style="font-size: large;" class="accented">{{((slot == undefined? tipoConsulto.Costo :
                    tipoConsulto.CostoTc) || "") | currency }}</span>
            </div>

            <div class="flex-row start-center gap24">
                <span class="miniTitle" i18n>Scegli il metodo di pagamento</span>
                <mat-radio-group [(ngModel)]="metodoPagamento" (change)="metodoPagamentoChanged($event)"
                    [disabled]="!isNew">
                    <mat-radio-button [value]="pagamentoBonifico" i18n>BONIFICO</mat-radio-button>
                    <mat-radio-button [value]="pagamentoPaypal">PAYPAL</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="metodoPagamento == pagamentoPaypal">
                    <div class="flex-col start-center">
                        <mat-spinner *ngIf="isLoadingPaypal" style="height: 42px;width: 42px;"></mat-spinner>
                        <ngx-paypal [config]="paypalConfig" (scriptLoaded)="paypalLoaded()"></ngx-paypal>
                    </div>
                </div>
            </div>
            <!-- <div class="flex-row start-center gap12">
                <span class="miniTitle" i18n>Fattura a: </span>
                <mat-radio-group [disabled]="!isNew" [(ngModel)]="consulto.FatturaAClinica">
                    <mat-radio-button [value]="false" i18n> UTENTE</mat-radio-button>
                    <mat-radio-button [value]="true" i18n> CLINICA</mat-radio-button>
                </mat-radio-group>
            </div> -->
        </div>
        <mat-divider></mat-divider>
        <div *appIsAuth="true" class="flex-row start-center gap12">
            <div class="flex-row gap12 flex20">
                <ng-container *ngIf="metodoPagamento == pagamentoBonifico">
                    <button mat-raised-button color="accent" (click)="pagamentoEffettuatoClicked()"
                        *ngIf="consulto.Pagamento.Stato != pagamentoEffettuato" i18n> Segna come pagato</button>
                    <button mat-raised-button color="accent" (click)="segnaComeDaPagare()"
                        *ngIf="consulto.Pagamento.Stato != pagamentoSospeso && (consulto.Pagamento.FatturaID == null || consulto.Pagamento.FatturaID == undefined)"
                        i18n>Segna
                        come da pagare</button>
                </ng-container>
                <ng-container *ngIf="visualizzaCreaFattura()">
                    <button mat-raised-button color="primary" (click)="creaFattura()">{{fatturaHasError(consulto)?
                        ricreaFatturaCaption : creaFatturaCaption}}</button>
                </ng-container>
                <ng-container
                    *ngIf="consulto.Pagamento && consulto.Pagamento.Stato == pagamentoEffettuato && hasFatturaWrapper(consulto.Pagamento) && !fatturaHasError(consulto)">
                    <button mat-raised-button color="primary" (click)="scaricaFattura()" i18n>Scarica fattura</button>
                </ng-container>
            </div>
            <ng-container *ngIf="fatturaHasError(consulto)">
                <div class="flex-col gap6 start-stretch flex80">
                    <span style="font-weight: 700; color: var(--error);" i18n>ATTENZIONE: Non è stato possibile inviare
                        la
                        fattura al SDI: </span>
                    <span style="color: var(--error);">{{consulto.Pagamento.Fattura?.ErroriUnimatica || ''}}</span>
                    <span style="color: var(--error);">{{consulto.Pagamento.Fattura?.ErroriSDI || ''}}</span>
                </div>
            </ng-container>

        </div>
    </ng-container>

</div>