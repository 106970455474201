import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Allegato } from 'src/app/model/allegato.model';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit{

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  _imgURL!: SafeResourceUrl;  
  get imgURL(): SafeResourceUrl{
    return this._imgURL;
  }
  set imgURL(val: SafeResourceUrl){
    this._imgURL = val;
  }
  fileType!: string;
  _file!: Blob;
  allegato!: Allegato;
  @Input() set file(allegato: Allegato) {
    this.allegato = allegato;
    if(allegato.File == null || allegato.File == undefined){
      this.imgURL = '';
      return;
    }
    if(allegato.ToDelete){
      this.imgURL = '';
      return;
    }
    let val = allegato.File!;
    console.log('file', val);
    this._file = val;
    this.fileType = this._file.type;
    this.imgURL = FilePreviewComponent.getSanitizedUrl(this.sanitizer, URL.createObjectURL(this.file));
    console.log('imgURL', this.imgURL);
  }
  get file(): Blob {
    return this._file;
  }

  ngOnInit(): void {
    //this.imgURL = FilePreviewComponent.getSanitizedUrl(this.sanitizer, URL.createObjectURL(this.file));
  }

  public static getSanitizedUrl(
    sanitazer: DomSanitizer,
    url: string
  ): SafeResourceUrl {
    return sanitazer.bypassSecurityTrustResourceUrl(url);
  }

}
