import { Component, OnInit } from '@angular/core';
import { SwUpdatesService } from './services/sw-updates.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import {
  ModificaPasswordData,
  ModificaPasswordComponent,
} from './components/modifica-password/modifica-password.component';
import { CurrentUserService } from './services/current-user.service';
import { LoginCandidate } from './model/login-candidate.model';
import { LoginInfo } from './model/login-info.model';
import { SignalingService } from './services/ws/signaling.service';

const LOGIN_INFO = 'LOGIN_INFO';
const LandingNavigation = '';
const LoginNavigation = 'login';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'vet';

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private swUpdate: SwUpdatesService,
    private dialog: MatDialog,
    private currentUser: CurrentUserService,
    private signalingService: SignalingService
  ) {}

  gestisciPaswwordTemporanea(loginInfo: LoginInfo){
    let data = <ModificaPasswordData>{
      OldRequested: !loginInfo.Temporary,
    };
    const dialogRef = this.dialog.open(ModificaPasswordComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe((d: ModificaPasswordData) => {
      if (d) {
        this.auth.renewPassword(d).subscribe((res) => {
          if(res){
            loginInfo.Temporary = false;
            loginInfo.Expired = false;
            localStorage.removeItem(LOGIN_INFO);
            localStorage.setItem(LOGIN_INFO, JSON.stringify(loginInfo));
            console.log(loginInfo.User);
            
            this.currentUser.changeCurrentUser(loginInfo.User);
            this.router.navigate([LandingNavigation]);
            this.loginSucces();
          }
        });
      }
    });
  }

  loginSucces(){
    //this.signalingService.initSignaling();
  }

  ngOnInit(): void {
    this.auth.loginInfo.subscribe((loginInfo) => {
      if (window.location.href.includes('login')) {
        // se siamo nella pagina di login e sono già presenti
        // le loginInfo, si va alla root home
        if (loginInfo != null && loginInfo.Temporary == false) {
          this.router.navigate([LandingNavigation]);
          this.loginSucces();
        }
        if (loginInfo?.Temporary) {
          this.gestisciPaswwordTemporanea(loginInfo);
        }
      } else if (loginInfo == null || loginInfo.Expired) {
        // se non siamo nella pagina di login e
        // se non ci sono le loginInfo, si va alla pagina di login
        this.router.navigate([LoginNavigation]);
      }else{
        console.log("no temporary, loginInfo != null");
        this.currentUser.changeCurrentUser(loginInfo.User)
        this.loginSucces();
      }
    });
    this.swUpdate.enable();
  }
}
