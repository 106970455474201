import { ICONA_CALENDARIO, ICONA_CONSULTO, ICONA_LISTINO, ICONA_PAZIENTE, ICONA_UTENTE } from "./icons-constant";

export type MenuVoice = {
    Label: string;
    Path: string;
    Icon: string;
    AuthRequired: boolean;
}

export const MenuConstant: MenuVoice[] = [
    {
        Label: $localize`:@@utenti:Utenti`,
        Path: '/users',
        Icon: ICONA_UTENTE,
        AuthRequired: true
    },
    {
        Label: $localize`:@@pazienti:Pazienti`,
        Path: '/pazienti',
        Icon: ICONA_PAZIENTE,
        AuthRequired: false,
    },
    {
        Label: $localize`:@@consulto:Consulto`,
        Path: '/consulti',
        Icon: ICONA_CONSULTO,
        AuthRequired: false,
    },
    {
        Label: $localize`:@@calendario:Calendario`,
        Path: '/calendario',
        Icon: ICONA_CALENDARIO,
        AuthRequired: false,
    },
    {
        Label: $localize`:@@listini:Listini`,
        Path: '/listini',
        Icon: ICONA_LISTINO,
        AuthRequired: true,
    },
    {
        Label: $localize`:@@fatture:Fatture`,
        Path: '/fatture',
        Icon: 'receipt',
        AuthRequired: true,
    }
];