import { environment } from './../../environments/environment';
import { LoginInfo } from '../model/login-info.model';
import { LoginCandidate } from '../model/login-candidate.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { ModificaPasswordData } from '../components/modifica-password/modifica-password.component';
import { CurrentUserService } from './current-user.service';

const LOGIN_CANDIDATE = 'LOGIN_CANDIDATE';
const LOGIN_INFO = 'LOGIN_INFO';

/**
 * AuthenticationService è il servizio che gestisce il login
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private currentUser: CurrentUserService
  ) {
    const v = localStorage.getItem(LOGIN_INFO);
    if (v) {
      const info = JSON.parse(v);
      this.loginSubject.next(info);
    }
  }
  private loginSubject = new BehaviorSubject<LoginInfo | undefined>(undefined);
  private errorSubject = new BehaviorSubject<any>(null);
  get loginInfo(): Observable<LoginInfo | undefined> {
    return this.loginSubject.asObservable();
  }
  get errorInfo(): Observable<any> {
    return this.errorSubject.asObservable();
  }
  get currentLoginInfo(): LoginInfo | undefined {
    return this.loginSubject.value;
  }
  /**
   * effettua il login sul server
   * @param candidate
   */
  login(candidate: LoginCandidate): Observable<LoginInfo> {
    return this.http
      .post<LoginInfo>(environment.apiUrl + 'login', candidate)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          this.loginFail(response.error);
          return throwError(response);
        }),
        tap((li) => {
          return this.loginSuccess(candidate, li);
        })
      );
  }
  tryToAutoLogin(): Observable<LoginInfo> {
    const v = localStorage.getItem(LOGIN_CANDIDATE);
    if (v) {
      const candidate = JSON.parse(v);
      return this.login(candidate);
    } else {
      return of();
    }
  }
  logout(): void {
    localStorage.removeItem(LOGIN_CANDIDATE);
    localStorage.removeItem(LOGIN_INFO);
    this.loginSubject.next(undefined);
    this.currentUser.changeCurrentUser(undefined);
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  }
  renewPassword(d: ModificaPasswordData): Observable<any> {
    return this.http.post(environment.apiUrl + 'login/renewpassword', d);
  }
  recoverPassword(email: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + 'login/recoverpassword', {
      Email: email,
    });
  }

  private loginSuccess(candidate: LoginCandidate, info: LoginInfo): void {
    this.loginSubject.next(info);
    if (candidate.RememberMe) {
      localStorage.setItem(LOGIN_CANDIDATE, JSON.stringify(candidate));
      localStorage.setItem(LOGIN_INFO, JSON.stringify(info));
    }
    this.currentUser.changeCurrentUser(info.User);
    this.refreshTimeout = setTimeout(() => {
      this.login(candidate).subscribe();
    }, 3600000);
  }

  private refreshTimeout?: any;

  private loginFail(error: any): void {
    this.errorSubject.next(error);
    localStorage.removeItem(LOGIN_CANDIDATE);
  }
}
