import { Consulto } from "./consulto.model";
import { Pagamento } from "./pagamento.model";

export type FatturaDB = {
    ID: number;
    PagamentoID: number;
    Consulto?: Consulto
    FatturaJson: string;
    NomeFileUnimatica: string;
    Riconciliata: boolean;
    ErroriUnimatica: string;
    ErroriSDI: string;
    CreatedAt: string;
    RicevutaSDI: string;
    Progressivo?: ProgressivoFattura;
}

export type ProgressivoFattura = {
    FatturaID: number;
    Numero: number;
    Anno: number;
    Annullata: boolean;
}

const separatorCodFisc = "_";
const separatorProg = "|";

export function progressivo(f: FatturaDB): string {
    let a = f.NomeFileUnimatica.split(separatorCodFisc)[1];
    return a.split(separatorProg)[0];
}