<ng-container *appIsAuth="true">
    <div class="flex-col center-center fxFlex gap24" *ngIf="referto == undefined" style="height:calc(100vh - 324px); ">
        <button mat-raised-button color="primary" (click)="generaReferto()" i18n
            [disabled]="!consulto || consulto.ID == undefined || consulto.ID == 0">Genera referto</button>
        <span *ngIf="!consulto || consulto.ID == undefined || consulto.ID == 0">Prima di produrre il referto, è
            necessario salvare il consulto.</span>
    </div>
</ng-container>


<div class="flex-row space-between-center gap24 toolbarReferto fxFlex"  *ngIf="referto != undefined" [ngClass]="{'toolbarRefertoDisponibile': referto.Disponibile, 'toolbarRefertoNonDisponibile': !referto.Disponibile}">
    <div class="flex-row gap24 start-center fxFlex" >
        <span class="miniTitle" i18n>produzione referto 
        </span>
        <mat-checkbox color="primary" [(ngModel)]="referto.Disponibile" (change)="disponibileChanged()"
            *appIsAuth="true">
            <span i18n>Rendi disponibile al cliente</span>
        </mat-checkbox>
    </div>
    <div class="fxFlex" >
        <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic">
            <mat-label i18n>Refertatore</mat-label>
            <mat-select [(ngModel)]="referto!.EseguitoDa" 
                (ngModelChange)="refertatoreChanged($event)" [disabled]="referto.Disponibile">
                <mat-select-trigger *ngIf="referto && referto.EseguitoDaUser">
                    <div class="flex-row start-center gap12 fxFlex">
                        <span>{{referto.EseguitoDaUser.Cognome}}&nbsp;{{referto.EseguitoDaUser.Nome}}</span>
                        <div class="flex-row fxFlex"></div>
                        <span style="text-align: end;"> &nbsp;&nbsp; {{ referto.EseguitoDaUser.Email}}</span>
                    </div>
                </mat-select-trigger>
                <mat-option *ngFor="let refertatore of refertatori" [value]="refertatore.Email">  
                    <div class="flex-row start-center gap12 fxFlex">
                        <span>{{refertatore.Cognome}}&nbsp;{{refertatore.Nome}}</span>
                        
                        <span style="text-align: end;">&nbsp;&nbsp;{{refertatore.Email}}</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="flex-row gap24 end-center" style="min-width: 200px;">
        <button mat-raised-button color="primary" (click)="scaricaPDF()" *ngIf="mostraScaricaPDF" i18n>Scarica PDF</button>
        <button mat-raised-button color="primary" (click)="salvaReferto()" *appIsAuth="true" i18n>Salva</button>
    </div>
</div>

<div [ngSwitch]="refertoType">
    <app-ecg *ngSwitchCase="tipiCosulti.Ecg" [referto]="refertoEcg"></app-ecg>
    <app-ecocardiografia *ngSwitchCase="tipiCosulti.Ecocardiografia" [referto]="refertoEcocardiografia"></app-ecocardiografia>
    <app-ecografia *ngSwitchCase="tipiCosulti.Ecografia" [referto]="refertoEcografia"></app-ecografia>
    <app-holter *ngSwitchCase="tipiCosulti.Holter" [referto]="refertoHolter"></app-holter>
    <app-radiografia *ngSwitchCase="tipiCosulti.Radiografia" [referto]="refertoRadiografia"></app-radiografia>
    <app-smallparts *ngSwitchCase="tipiCosulti.SmallPart" [referto]="refertoSmallParts"></app-smallparts>
</div>