<fieldset [disabled]="isCliente" *ngIf="referto != undefined && (!isCliente || (isCliente && referto.Disponibile))" 
style="border: none!important;padding: 0px!important;">
    <!-- GETTER PER VALUTARE STATO FORM -->
    <div style="visibility: hidden;">{{hasForm}}</div>
    <!-- GETTER PER VALUTARE STATO FORM -->

    <form #f="ngForm" class="flex-col gap12 start-stretch" style="height: calc(100vh - 386px);overflow: auto" >
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Organi Esaminati" i18n-label>
                <ng-container [ngTemplateOutlet]="organi"></ng-container>
            </mat-tab>
            <mat-tab label="SMALL PARTS" i18n-label>
                <ng-container [ngTemplateOutlet]="smallParts"></ng-container>
                
            </mat-tab>
        </mat-tab-group>
        <mat-divider></mat-divider>
        <div class="flex-col start-stretch gap12">
            <mat-label i18n>Referto</mat-label>
            <app-valori-default [valoreDefault]="defReferto"></app-valori-default>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                <textarea matInput name="Referto" placeholder="Referto" rows="10" i18n-placeholder
                    [(ngModel)]="referto.Referto"></textarea>
            </mat-form-field>
        </div>


    <ng-template #organi>
        <div class="flex-col gap12" style="margin-top: 12px;">
            <span class="miniTitle" i18n>Considerazioni generali</span>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Esame ecografico addominale</span>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="EsameEcograficoAddominale" placeholder="Esame ecografico addominale" i18n-placeholder
                        [(ngModel)]="referto.EsameEcograficoAddominale"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Fegato</span>
                <app-valori-default [valoreDefault]="defFegato"></app-valori-default>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="Fegato" placeholder="Fegato" i18n-placeholder
                        [(ngModel)]="referto.Fegato"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Milza</span>
                <app-valori-default [valoreDefault]="defMilza"></app-valori-default>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="Milza" placeholder="Milza" i18n-placeholder
                        [(ngModel)]="referto.Milza"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Apparato gastroenterico e pancreas</span>
                <app-valori-default [valoreDefault]="defApparatoGastrointericoEPancreas"></app-valori-default>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="ApparatoGastrointericoEPancreas" placeholder="Apparato gastroenterico e pancreas" i18n-placeholder
                        [(ngModel)]="referto.ApparatoGastrointericoEPancreas"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Linfonodi</span>
                <app-valori-default [valoreDefault]="defLinfnodi"></app-valori-default>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="Linfonodi" placeholder="Linfonodi" i18n-placeholder
                        [(ngModel)]="referto.Linfonodi"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Reni</span>
                <app-valori-default [valoreDefault]="defReni"></app-valori-default>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="Reni" placeholder="Reni" i18n-placeholder
                        [(ngModel)]="referto.Reni"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Surreni</span>
                <app-valori-default [valoreDefault]="defSurreni"></app-valori-default>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="Surreni" placeholder="Surreni" i18n-placeholder
                        [(ngModel)]="referto.Surreni"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Vescica</span>
                <app-valori-default [valoreDefault]="defVescica"></app-valori-default>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="Vescica" placeholder="Vescica" i18n-placeholder
                        [(ngModel)]="referto.Vescica"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Apparato genitale</span>
                <app-valori-default [valoreDefault]="defApparatoGenitale"></app-valori-default>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="ApparatoGenitale" placeholder="Apparato genitale" i18n-placeholder
                        [(ngModel)]="referto.ApparatoGenitale"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col gap12">
                <span i18n>Altro</span>
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                    <textarea matInput name="Altro" placeholder="Altro" i18n-placeholder
                        [(ngModel)]="referto.Altro"></textarea>
                </mat-form-field>
            </div>
        </div>
    </ng-template>
    <ng-template #smallParts>
        <div class="flex-col gap12" style="margin-top: 12px;">
            <span i18n>Esame ecografico "small parts"</span>
            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="fxFlex">
                <textarea matInput name="EsameEcograficoSmallParts" placeholder="Esame Ecografico small parts" i18n-placeholder
                    [(ngModel)]="referto.EsameEcograficoSmallParts" ></textarea>
            </mat-form-field>
        </div>
    </ng-template>
   
    </form>
</fieldset>