import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshPazientiService {

  public mustRefresh: Subject<boolean> = new Subject<boolean>();

  public refresh() {
    this.mustRefresh.next(true);
  }

  constructor() { }
}
