import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DefaultReferto } from 'src/app/model/referti/default-value.model';
import { Ecocardiografia, EcocardiografiaUnit, ecocardiografiaUnits } from 'src/app/model/referti/ecocardiografia.model';
import { ruoloCliente } from 'src/app/model/user.model';
import { ConsultoUnsavedDataService } from 'src/app/services/consulto-unsaved-data.service';
import { CurrentUserService } from 'src/app/services/current-user.service';

@Component({
  selector: 'app-ecocardiografia',
  templateUrl: './ecocardiografia.component.html',
  styleUrls: ['./ecocardiografia.component.scss']
})
export class EcocardiografiaComponent implements OnDestroy {
 
  constructor(
    private currentUser: CurrentUserService,
    private modifiche: ConsultoUnsavedDataService,
  ) {
    
  }
  

  defReferto = DefaultReferto;
  subs: Subscription = new Subscription();

  @ViewChild('f') form: NgForm | undefined;

  // Ci serve per valutare se il form è stato inizializzato
  formInit: boolean = false;
  get hasForm(): void {
    if(this.form == undefined || this.formInit == true) {
      return ;
    }else{
      this.formInit = true;
      console.log("FORM INIT");
      /**
      Si esegue la subscription in un timeout per evitare che la prima assegnazione del form,
      dopo il recupero dei dati dal server, venga considerata come una modifica
       */
      setTimeout(() => {
        this.formSub();
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  formSub(){
    this.subs.add(
    this.form?.valueChanges?.subscribe((_) => {
      this.modifiche.modificheNonSalvate.next(true);
    }));
  }

  suffixes: EcocardiografiaUnit = ecocardiografiaUnits;
  suff = "norm. <= 1.7"
  
  _referto: Ecocardiografia | undefined;
  @Input() set referto(value: Ecocardiografia | undefined) {
    this._referto = value;
  }
  get referto(): Ecocardiografia | undefined {
    return this._referto;
  }

  get isCliente(): boolean {
    if(this.currentUser.currentUser == undefined){
      return false;
    }
    return this.currentUser.currentUser?.Ruolo == ruoloCliente;
  }
}
