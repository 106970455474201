<div>
    <mat-toolbar class="internal-toolbar">
        <div class="flex-row space-between-center fxFlex">
            <span class="miniTitle" i18n>LISTINI</span>
            <button mat-raised-button color="accent" class="saveBtn" [tabIndex]="8" (click)="salva()">SAVE</button>
        </div>
    </mat-toolbar>

    <div style="padding: 24px;" class="flex-col start-stretch">
        <div class="flex-row fxFlex" style="margin-bottom: 12px;">
            <span class="miniTitle fxFlex" style="min-width: 52%;" i18n>Tipo</span>
            <span class="miniTitle fxFlex" i18n>Costo in TeleConferenza (Iva Esclusa)</span>
            <span class="miniTitle fxFlex" style="margin-left: 12px;" i18n>Costo solo consulto (Iva Esclusa)</span>
        </div>
        <div class="flex-col" *ngFor="let tipoCons of tipiConsulto; let i = index">
            <div class="flex-row start-center gap12">
                <mat-label class="fxFlex" style="min-width: 50%; max-width: 50%;">{{tipoCons.Descrizione}}</mat-label>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic" >
                    <input matInput type="number" [name]="'costoTc' + i" [(ngModel)]="tipoCons.CostoTc" [placeholder]="'Costo ' + tipoCons.Descrizione">
                </mat-form-field>
                <mat-form-field appearance="outline" class="fxFlex" subscriptSizing="dynamic" >
                    <input matInput type="number" [name]="'costo' + i" [(ngModel)]="tipoCons.Costo" [placeholder]="'Costo ' + tipoCons.Descrizione">
                </mat-form-field>
            </div>
            <mat-divider style="margin-bottom: 12px;margin-top: 12px;"> </mat-divider>
        </div>
    </div>
</div>