import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import {
  ICreateOrderRequest,
  IOnApproveCallbackData,
  IPayPalConfig,
} from 'ngx-paypal';
import { lastValueFrom, Observable } from 'rxjs';
import { Consulto } from 'src/app/model/consulto.model';
import {
  Pagamento,
  hasFattura,
  pagamentoEffettuato,
  pagamentoSospeso,
} from 'src/app/model/pagamento.model';
import { Slot } from 'src/app/model/slot.model';
import { TipoConsulto } from 'src/app/model/tipo-consulto.model';
import {
  MessageConstants,
  MessageData,
  MessageService,
} from 'src/app/services/message.service';
import { ApiUrl } from 'src/app/shared/api-url';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import {
  pagamentoBonifico,
  pagamentoPaypal,
} from 'src/app/shared/generic-constant';
import { FatturaDetailComponent } from '../fatture/fattura-detail/fattura-detail.component';

export type datiPagamentoPaypal = {
  data: IOnApproveCallbackData | undefined;
  details: string;
};

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss'],
})
export class PagamentoComponent implements OnInit {
  creaFatturaCaption = $localize`:@@creaFattura:Crea Fattura`;
  ricreaFatturaCaption = $localize`:@@ricreaFattura:Ricrea Fattura`;

  @Input('consulto') consulto!: Consulto;
  @Input('slot') slot!: Slot | undefined;
  @Input('isNew') isNew: boolean = false;

  @Output() metodoPagamentoChangedEmitter: EventEmitter<number> =
    new EventEmitter<number>();
  //TODO: in pagamentoPaypalEffettuato deve essere passato il pagamento con i dati restituiti da paypal
  @Output() pagamentoPaypalEffettuato: EventEmitter<
    datiPagamentoPaypal | undefined
  > = new EventEmitter<datiPagamentoPaypal | undefined>();

  protected hasFatturaWrapper(p: Pagamento) {
    return hasFattura(p);
  }

  protected fatturaHasError(c: Consulto) {
    if (c.Pagamento == undefined) {
      return false;
    }
    let fatturaPresente: boolean = this.hasFatturaWrapper(c.Pagamento);
    if (!fatturaPresente) {
      return false;
    }
    let fatturaConErroriUni: boolean =
      c.Pagamento.Fattura?.ErroriUnimatica != undefined &&
      c.Pagamento.Fattura?.ErroriUnimatica != '' &&
      c.Pagamento.Fattura?.ErroriUnimatica != null;
    let fatturaConErroriSDI: boolean =
      c.Pagamento.Fattura?.ErroriSDI != undefined &&
      c.Pagamento.Fattura?.ErroriSDI != '' &&
      c.Pagamento.Fattura?.ErroriSDI != null;
    return fatturaConErroriUni || fatturaConErroriSDI;
  }

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {}

  // TODO: PAYPAL config deve essere gestito nell'onInit con i dati del consulto
  pagamentoPaypal = pagamentoPaypal;
  pagamentoBonifico = pagamentoBonifico;
  metodoPagamento: number = pagamentoBonifico | pagamentoPaypal;

  pagamentoEffettuato = pagamentoEffettuato;
  pagamentoSospeso = pagamentoSospeso;

  metodoPagamentoChanged(event: MatRadioChange) {
    this.metodoPagamento = event.value;
    this.metodoPagamentoChangedEmitter.emit(this.metodoPagamento);
    if (this.metodoPagamento == pagamentoPaypal) {
      this.initPayPalConfig();
    }
  }
  async pagamentoEffettuatoClicked() {
    if (this.consulto == undefined || this.consulto.Pagamento == undefined) {
      return;
    }
    if (await lastValueFrom(this.askForConfirm(true))) {
      this.consulto.Pagamento.Stato = pagamentoEffettuato;
      this.aggiornaPagamento();
    }
  }

  async segnaComeDaPagare() {
    if (this.consulto == undefined || this.consulto.Pagamento == undefined) {
      return;
    }
    if (await lastValueFrom(this.askForConfirm(false))) {
      this.consulto.Pagamento.Stato = pagamentoSospeso;
      this.aggiornaPagamento();
    }
  }

  aggiornaPagamento() {
    const url = ApiUrl.PAGAMENTI;
    const obs = this.http.put<Pagamento>(url, this.consulto.Pagamento);
    obs.subscribe((response) => {
      console.log(response);
      let m: MessageData = MessageConstants.Modifica;
      m.Message = 'Stato del pagamento aggiornato con successo';
      this.messageService.sendMessage(m);
    });
  }

  confermaPagatoStr = $localize`:@@confermaPagato:Sei sicuro di voler segnare il consulto come pagato?`;
  confermaNonPagatoStr = $localize`:@@confermaNonPagato:Sei sicuro di voler segnare il consulto come da pagare?`;
  modificaSceltaStr = $localize`:@@modificaScelta:Sarà comunque possibile modificare questa scelta in seguito.`;
  askForConfirm(segnaComePagato: boolean) {
    let data: ConfirmDialogData = {
      message: [
        segnaComePagato ? this.confermaPagatoStr : this.confermaNonPagatoStr,
      ],
    };
    data.message.push(this.modificaSceltaStr);
    return this.dialog
      .open(ConfirmDialogComponent, { data: data })
      .afterClosed();
  }

  controllaValiditaDatiPerProduzioneFattura(): string[] {
    let errors: string[] = [];
    if (this.consulto == undefined) {
      errors.push('Consulto non definito');
    }
    if (this.consulto.Pagamento == undefined) {
      errors.push('Pagamento non definito');
    }
    // checkDatiPaziente
    if (this.consulto.Paziente == undefined) {
      errors.push('Paziente non definito');
    }
    let p = this.consulto.Paziente;
    if (p?.Nome == undefined || p?.Nome == '') {
      errors.push('Nome del paziente non definito');
    }
    if (this.consulto.User == undefined) {
      errors.push('Cessionario/Committente non definito');
    }
    let u = this.consulto.User;
    if (u == undefined) {
      errors.push('Cessionario/Committente non definito');
    }
    if (u?.Nome == undefined || u?.Nome == '') {
      errors.push('Nome del cessionario/committente non definito');
    }
    if (u?.Cognome == undefined || u?.Cognome == '') {
      errors.push('Cognome del cessionario/committente non definito');
    }
    if (
      u?.CodiceFiscale == undefined ||
      u?.CodiceFiscale == '' ||
      u.CodiceFiscale.trim().length != 16 ||
      u.CodiceFiscale.trim() == ''
    ) {
      errors.push('Codice fiscale del cessionario/committente non definito');
    }
    if (!u?.CodiceFiscale.match(/^[A-Z0-9]+$/)) {
      errors.push('Il codice fiscale deve essere alfanumerico');
    }
    if (u?.Indirizzo == undefined || u?.Indirizzo == '') {
      errors.push('Indirizzo del cessionario/committente non definito');
    }

    return errors;
  }

  visualizzaCreaFattura() {
    let hasPagamento = this.consulto.Pagamento != undefined;
    let statoOk = this.consulto.Pagamento?.Stato == pagamentoEffettuato;
    let fatturaHasErrors =
      !this.hasFatturaWrapper(this.consulto.Pagamento) ||
      this.fatturaHasError(this.consulto);
    return hasPagamento && statoOk && fatturaHasErrors;
  }

  protected async creaFattura() {
    let errors = this.controllaValiditaDatiPerProduzioneFattura();
    let m: string[] = ['Impossibile creare la fattura: '];
    if (errors.length > 0) {
      let mes: string[] = m.concat(errors);
      mes = mes.concat(['Controllare i dati del mancanti']);
      let cdd: ConfirmDialogData = {
        title: 'Dati mancanti',
        showCancel: false,
        message: mes,
      };
      const dr = this.dialog
        .open(ConfirmDialogComponent, { data: cdd })
        .afterClosed();
      await lastValueFrom(dr);
      return;
    }
    let obs = this.dialog
      .open(FatturaDetailComponent, {
        data: this.consulto,
      })
      .afterClosed();
    obs.subscribe((res) => {
      if (res) {
        this.consulto.Pagamento = res;
      }
    });
  }

  scaricaFattura() {
    if (
      this.consulto.Pagamento.FatturaID == undefined ||
      this.consulto.Pagamento.FatturaID < 0 ||
      this.consulto.Pagamento.FatturaID == null
    ) {
      return console.log('Errore fattura non presente');
    }
    const url = ApiUrl.FATTURE + '/scarica';
    let params = new HttpParams().set(
      'fattura_id',
      this.consulto.Pagamento.FatturaID.toString()
    );
    const obs = this.http.get(url, { params: params, responseType: 'blob' });
    obs.subscribe((response) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  getPaypalClientID(): Observable<string> {
    return this.http.get<string>(ApiUrl.PAYPAL_CLIENT_ID);
  }

  private async initPayPalConfig() {
    const clientID = await lastValueFrom(this.getPaypalClientID());
    this.isLoadingPaypal = true;
    this.paypalConfig = {
      currency: 'EUR',
      clientId: clientID,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'EUR',
                value: (this.slot != undefined
                  ? this.tipoConsulto.Costo
                  : this.tipoConsulto.CostoTc
                ).toString(),
                breakdown: {
                  item_total: {
                    currency_code: 'EUR',
                    value: (this.slot != undefined
                      ? this.tipoConsulto.Costo
                      : this.tipoConsulto.CostoTc
                    ).toString(),
                  },
                },
              },
              items: [
                {
                  name:
                    'Consulto ' + this.slot != undefined
                      ? 'in teleconferenza'
                      : '',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'EUR',
                    value: (this.slot != undefined
                      ? this.tipoConsulto.Costo
                      : this.tipoConsulto.CostoTc
                    ).toString(),
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'horizontal',
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details: string) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
          this.registraDatiPagamentoPaypal(data, details);
          this.showSuccess = true;
        });
      },
      onClientAuthorization: (data) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.showCancel = true;
      },
      onError: (err) => {
        console.log('OnError', err);
        this.showError = true;
        this.pagamentoEffettuato = pagamentoSospeso;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        this.resetStatus();
      },
    };
  }

  /**
   * registraDatiPagamentoPaypal - Emette i dati del pagamento effettuato
   * @param data
   * @param details
   */
  registraDatiPagamentoPaypal(data: IOnApproveCallbackData, details: string) {
    this.pagamentoPaypalEffettuato.emit({ data: data, details: details });
  }

  tipoConsulto!: TipoConsulto;
  paypalConfig: IPayPalConfig = {} as IPayPalConfig;
  showError: boolean = false;
  showSuccess: boolean = false;
  showCancel: boolean = false;
  resetStatus() {}

  ngOnInit(): void {
    // Recupero il tipo di consulto per ricavare il prezzo
    this.loadTipoConsulto();
    if (this.consulto.Pagamento && this.consulto.Pagamento.TipoPagamento) {
      this.metodoPagamento = this.consulto.Pagamento.TipoPagamento;
    }
  }

  loadTipoConsulto() {
    const url = ApiUrl.TIPI_CONSULTO + '/' + this.consulto.TipoConsultoID;
    const obs = this.http.get<TipoConsulto>(url);
    obs.subscribe((response) => {
      this.tipoConsulto = response;
    });
  }

  isLoadingPaypal: boolean = false;
  paypalLoaded() {
    this.isLoadingPaypal = false;
  }
}
