import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Subject, Subscription, tap } from 'rxjs';
import {
  IceCandidateContent,
  RTCDescrInitContent,
  StatoContent,
  User,
} from 'src/app/model/user.model';
import {
  Messaggio,
  SignalingService,
  TipoMessaggio,
} from './signaling.service';
import { CurrentUserService } from '../current-user.service';

const RTC_CONFIGURATION: RTCConfiguration = {
  iceServers: [
    {
      urls: 'stun:stun.l.google.com:19302',
    },
    {
      urls: 'turn:www.squidsystem.xyz:3478?transport=udp',
      username: 'soun',
      credential: 'asega',
    },
  ],
};

@Injectable({
  providedIn: 'root',
})
export class RtcService {
  subs: Subscription = new Subscription();

  constructor(
    private signalingService: SignalingService,
    private currentUserService: CurrentUserService
  ) {
    this.signalingService.close.subscribe(() => {
      this.streamingStopped(this.currentUserService.currentUser!.Email);
    });
    this.ready.next(true);
  }
  private streamingSubscription: Subscription | undefined;

  private _audioOutput = new BehaviorSubject<MediaDeviceInfo | undefined>(undefined);
  get audioOutput() {
    return this._audioOutput.asObservable();
  }
  private streams: Map<string, MediaStream> = new Map<string, MediaStream>();
  private peerConnections: Map<string, RTCPeerConnection> = new Map<string, RTCPeerConnection>();

  private streamingSubscriptions: Subscription | undefined;
  ready = new BehaviorSubject<boolean>(false);
  availableDevices = new BehaviorSubject<MediaDeviceInfo[]>([]);

  private _streamAvailable = new Subject<User>();
  streamAvailable = (() => this._streamAvailable.asObservable())();

  private _streamUnavailable = new Subject<User>();
  streamUnavailable = (() => this._streamUnavailable.asObservable())();

  private _streaming = new BehaviorSubject<boolean>(false);
  streaming = (() => this._streaming.asObservable())();

  myStream: User | undefined;
  get hidden(): boolean {
    if (this.myStream == undefined) {
      return false;
    }
    return !this.myStream?.MediaStream?.getVideoTracks()[0].enabled;
  }
  set hidden(value: boolean) {
    if (this.myStream == undefined) {
      return;
    }
    try {
      this.myStream!.MediaStream!.getVideoTracks()[0].enabled = !value;
    } finally {
    }
  }

  get mute(): boolean {
    if (this.myStream == undefined) {
      return false;
    }
    return !this.myStream?.MediaStream?.getAudioTracks()[0].enabled;
  }

  set mute(value: boolean) {
    if (this.myStream == undefined) {
      return;
    }
    try {
      this.myStream!.MediaStream!.getAudioTracks()[0].enabled = !value;
    } finally {
    }
  }
  stopStreaming(): void {
    let id = this.currentUserService.currentUser!.Email;
    this.myStream?.MediaStream?.getTracks().forEach((t) => t.stop());
    this.myStream = undefined;
    this.signalingService.send(<Messaggio>{
      UtenteID: id,
      Tipo: TipoMessaggio.Stato,
      Content: <StatoContent>{
        Online: true,
        Streaming: false,
      },
    });
    this.peerConnections.forEach((pc, id) => this.streamingStopped(id));
    this.streamingSubscription?.unsubscribe();
    this._streaming.next(false);
  }

  inspectDevices() {
    from(navigator.mediaDevices.enumerateDevices()).subscribe((devices) => {
      this.availableDevices.next(devices);
      const output = devices.filter((d) => d.kind === 'audiooutput')[0];
      this._audioOutput.next(output);
    });
  }

  utenteConStream = new BehaviorSubject<User | undefined>(undefined);

  startStreaming() {
    if (this.currentUserService.currentUser == undefined) {
      return;
    }
    let email = this.currentUserService.currentUser?.Email;
    const obs = from(
      navigator.mediaDevices.getUserMedia({ video: true, audio: true })
    ).pipe(tap(() => this.inspectDevices()));

    obs.subscribe((stream) => {
      this.myStream = { Email: email!, MediaStream: stream } as User;
      this._streamAvailable.next(this.myStream);
      this.utenteConStream.next(this.myStream);

      this.subs.add(
        this.signalingService.access.subscribe((access) => {
          if (access.UtenteEmail == email) {
            return;
          }
          let c = access.Content as StatoContent;
          if (c.Online && c.Streaming) {
            this.call(access.UtenteEmail!);
          } else {
            this.streamingStopped(access.UtenteEmail!);
          }
        })
      );
      this.subs.add(
        this.signalingService.message.subscribe((messaggio) => {
          let email = messaggio.UtenteEmail!;
          if (email == this.currentUserService.currentUser?.Email) {
            return;
          }
          switch (messaggio.Tipo) {
            case TipoMessaggio.Offer:
              this.arriveOffer(email, messaggio);
              break;
            case TipoMessaggio.IceCandidate:
              this.arriveCandidate(email, messaggio);
              break;
            case TipoMessaggio.Answer:
              this.arriveAnswer(email, messaggio);
              break;
            default:
              break;
          }
        })
      );
      this.streamingSubscription = this.subs;
      this.signalingService.send(<Messaggio>{
        UtenteEmail: email,
        Tipo: TipoMessaggio.Stato,
        Content: <StatoContent>{
          Online: true,
          Streaming: true,
        },
      });
      this._streaming.next(true);
    });
  }
  private async arriveOffer(
    email: string,
    messaggio: Messaggio
  ): Promise<void> {
    let pc = this.peerConnection(email);
    let offer = messaggio.Content as RTCSessionDescriptionInit;
    console.log(offer);

    try {
      await pc.setRemoteDescription(offer);
      let answer = await pc.createAnswer();
      this.signalingService.send(<Messaggio>{
        UtenteEmail: this.currentUserService.currentUser?.Email,
        Dest: email,
        Tipo: TipoMessaggio.Answer,
        Content: <RTCDescrInitContent>{
          DescriInit: answer,
        },
      });
      pc.setLocalDescription(answer);
    } catch (e) {
      console.log(e);
    }
  }
  private async arriveAnswer(
    email: string,
    messaggio: Messaggio
  ): Promise<void> {
    let pc = this.peerConnection(email);
    let answer = (messaggio.Content as RTCDescrInitContent).DescriInit;
    try {
      await pc.setRemoteDescription(answer);
    } catch (e) {
      console.log(e);
    }
  }

  private async arriveCandidate(
    email: string,
    messaggio: Messaggio
  ): Promise<void> {
    let pc = this.peerConnection(email);
    let candidate = (messaggio.Content as IceCandidateContent).IceCandidate;
    try {
      await pc.addIceCandidate(candidate);
    } catch (e) {
      console.log(e);
    }
  }

  streamingStopped(email: string): void {
    let pc = this.peerConnection(email);
    if (pc) {
      pc.close();
      this.peerConnections.delete(email);
      this.streams.delete(email);
      this._streamUnavailable.next({ Email: email } as User);
    }
  }

  async call(email: string): Promise<void> {
    let pc = this.peerConnection(email);
    try {
      let offer = await pc.createOffer();
      this.signalingService.send(<Messaggio>{
        Content: offer,
        UtenteEmail: this.currentUserService.currentUser!.Email,
        Dest: email,
        Tipo: TipoMessaggio.Offer,
      });
      await pc.setLocalDescription(offer);
    } catch (e) {
      console.log(e);
    }
  }
  peerConnection(email: string): RTCPeerConnection {
    let pc = this.peerConnections.get(email);
    if (pc) {
      return pc;
    } else {
      return this.createPerrConnection(email);
    }
  }

  private createPerrConnection(email: string): RTCPeerConnection {
    let pc = new RTCPeerConnection(RTC_CONFIGURATION);
    this.peerConnections.set(email, pc);
    let stream = new MediaStream();
    this.streams.set(email, stream);
    this._streamAvailable.next({ Email: email, MediaStream: stream } as User);
    pc.ontrack = (event) => this.connectionDidTrackEvent(email, event);
    pc.onicecandidate = (event) => this.didDiscoverIceCandidate(event, email);
    pc.onconnectionstatechange = () => this.connectionStateDidChange(email);
    pc.oniceconnectionstatechange = () =>
      this.iceConnectionStateDidChange(email);
    this.myStream?.MediaStream?.getTracks().forEach(
      (track: MediaStreamTrack) => {
        pc.addTrack(track);
      }
    );
    pc.onicecandidateerror = (ev) =>
      console.log('onicecandidateerror', 'error type: ' + ev.type);
    return pc;
  }
  /**
   * aggiunge mediaStreamTrack a un remoteStream ed aggiorna _remoteStreamArray che contiene i mediaStream totali da mostrare
   * @param id id della peerConnection che ha registrato evento ontrack
   * @param track MediaStreamTrack da attaccare al remoteStream legata alla peerConnection con tale id
   */
  connectionDidTrackEvent(email: string, event: RTCTrackEvent): any {
    let track = event.track;
    let stream = this.streams.get(email);
    track.onended = function (e) {
      stream?.removeTrack(this);
    };
    console.log('RTC: track event type: ' + event.type);
    stream?.addTrack(track);
  }

  private didDiscoverIceCandidate(
    event: RTCPeerConnectionIceEvent,
    email: string
  ) {
    if (event.candidate) {
      this.signalingService.send(<Messaggio>{
        UtenteEmail: this.currentUserService.currentUser!.Email,
        Dest: email,
        Tipo: TipoMessaggio.IceCandidate,
        Content: <IceCandidateContent>{
          IceCandidate: event.candidate,
        },
      });
    }
  }

  private connectionStateDidChange(email: string) {
    console.log(
      'RTC ' + email + ' ' + this.peerConnections.get(email)?.connectionState
    );
  }

  private iceConnectionStateDidChange(email: string) {
    console.log(
      'RTC ' + email + ' ' + this.peerConnections.get(email)?.connectionState
    );
  }


  changeDevice(mediaConstraint: MediaStreamConstraints) {
    from(navigator.mediaDevices.getUserMedia(mediaConstraint)).subscribe(
      (stream) => this.changeStream(stream)
    );
  }

  changeStream(stream: MediaStream) {
    stream
      .getTracks()
      .filter((t) =>
        this.myStream?.MediaStream?.getTracks().some((mt) => mt.kind == t.kind)
      )
      .forEach((t) => {
        this.myStream?.MediaStream?.getTracks()
          .filter((mt) => mt.kind == t.kind)
          .forEach((mt) => {
            this.myStream?.MediaStream?.removeTrack(mt);
          });
        this.myStream?.MediaStream?.addTrack(t);
      });
    this.peerConnections.forEach((pc) =>
      pc
        .getSenders()
        .filter((s) => stream.getTracks().some((t) => t.kind == s.track?.kind))
        .forEach((s) =>
          s.replaceTrack(
            stream.getTracks().find((t) => s.track?.kind == t.kind) || null
          )
        )
    );
  }

  changeAudioDevice(device: MediaDeviceInfo) {
    let mc = {
      audio: {
        deviceId: device.deviceId ? { exact: device.deviceId } : undefined,
      },
    };
    this.changeDevice(mc);
  }

  changeVideoDevice(device: MediaDeviceInfo) {
    let mc = {
      video: {
        deviceId: device.deviceId ? { exact: device.deviceId } : undefined,
      },
    };
    this.changeDevice(mc);
  }


}
