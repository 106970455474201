import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChiudiMenuService {

  public setMenuAsOpenOnMouseEnter : Subject<boolean> = new Subject<boolean>();


  constructor() { }
}
