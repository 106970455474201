import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlliTeleconferenzaCoordinatorService {

  toggleStream: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(false);
  toggleCamera: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(false);
  toggleAudio: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(false);



  toggleStreamAction(){
    if(this.toggleStream.value == false){
      this.toggleStream.next(true);
      this.toggleCamera.next(true);
      this.toggleAudio.next(true);
    }else{
      this.toggleStream.next(false);
      this.toggleCamera.next(false);
      this.toggleAudio.next(false);
    }
  }

  toggleCameraAction(){
    if(this.toggleCamera.value == false){
      this.toggleCamera.next(true);
    }else{
      this.toggleCamera.next(false);
    }
  }

  toggleAudioAction(){
    if(this.toggleAudio.value == false){
      this.toggleAudio.next(true);
    }else{
      this.toggleAudio.next(false);
    }
  }


  constructor() { }
}
